import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import iState from '../../types/misc/iState';
import StateService from '../../services/misc/StateService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
// import SearchTextField from '../frameWork/SearchTextField';
// import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';

type iStateList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};

const StateList = ({ allowDelete, headerProps }: iStateList) => {
  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iState>({
      sort: `name:ASC`,
      getFn: (params) =>
        StateService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = (State?: iState) => {
    return renderEntityEditPopBtn<iState>({
      editingEntity: State,
      entityName: 'State',
      createFn: (data) => StateService.create(data),
      updateFn: (id, data) => StateService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'state-name',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iState>[] => [
    {
      key: 'name',
      header: 'Name',
      cell: ({ data }: iCellParams<iState>) => {
        return getEditBtn(data);
      },
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iState>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iState>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => StateService.deactivate(data.id),
                    getDisplayName: (State) => State.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: 'state-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default StateList;
