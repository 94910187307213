export const DEFAULT_LOCALE = 'en-au';

const getDefaultLocale = () => {
  return process.env.REACT_APP_DEFAULT_LOCALE || DEFAULT_LOCALE;
};

const AppHelper = {
  getDefaultLocale,
};

export default AppHelper;
