import HouseStoreyList from '../../components/houseStorey/HouseStoreyList';
import ProtectedPage from '../../layouts/ProtectedPage';

const HouseStoreyPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `House Storey`,
      }}
    >
      {(headerProps) => (
        <HouseStoreyList allowDelete headerProps={headerProps} />
      )}
    </ProtectedPage>
  );
};

export default HouseStoreyPage;
