import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsConstructionSchedulePanel = {
  build: iBuild;
};
const BuildDetailsConstructionSchedulePanel = ({
  build,
}: iBuildDetailsConstructionSchedulePanel) => {
  return <Wrapper>Construction Schedule: {build.id}</Wrapper>;
};

export default BuildDetailsConstructionSchedulePanel;
