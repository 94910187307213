import iAttribute, {
  AttributeTypes,
} from '../../../types/attribute/iAttribute';
import styled from 'styled-components';
import TextArea from '../../frameWork/TextArea';
import { getErrorProps } from '../../form/FormError';
import { iUseAsForm } from '../../form/EntityEditPanel';
import Toggle from '../../frameWork/Toggle';

const Wrapper = styled.div``;
type iAttributeSettingsEditPanel = {
  attribute?: iAttribute;
  isDisabled?: boolean;
  testId?: string;
  className?: string;
  useAsFrom: iUseAsForm;
};
const AttributeSettingsEditPanel = ({
  testId,
  className,
  attribute,
  isDisabled,
  useAsFrom,
}: iAttributeSettingsEditPanel) => {
  const componentName = 'attribute-settings-edit';
  const testIdStr = `${testId || ''}-${componentName}`;

  const getSelectedAttributeType = () => `${attribute?.type || ''}`.trim();

  const getSettingObject = () => {
    return attribute?.settings || {};
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFieldChanged = (fieldName: string, newValue: any) => {
    useAsFrom.onFieldChange('settings', {
      ...getSettingObject(),
      [fieldName]: newValue,
    });
  };

  const getOptionTextArea = () => {
    const type = getSelectedAttributeType();
    if (type !== AttributeTypes.DROPDOWN) {
      return null;
    }
    const settings = getSettingObject();
    const options = 'options' in settings ? settings.options : [];
    return (
      <TextArea
        label={`Options`}
        testId={'options'}
        isRequired
        isDisabled={isDisabled}
        value={options.join('\n')}
        helperMsg={`Each Option Per Line`}
        {...getErrorProps({
          fieldName: 'options',
          error: useAsFrom.errorMap,
        })}
        onChange={(event) => {
          handleFieldChanged('options', event.target.value.split('\n'));
        }}
      />
    );
  };

  const getProductDropdownSettings = () => {
    if (getSelectedAttributeType() !== AttributeTypes.PRODUCT_DROPDOWN) {
      return;
    }
    const settings = getSettingObject();
    return (
      <>
        <Toggle
          label={'Show Product'}
          helperMsg={'whether product will be shown, when selected.'}
          isDisabled={isDisabled}
          isChecked={settings.showSelected === true}
          onChange={(event) => {
            handleFieldChanged('showSelected', event.target.checked);
            if (event.target.checked === false) {
              handleFieldChanged('showSelectedPic', false);
            }
          }}
        />
        {settings.showSelected === true && (
          <Toggle
            label={'Show Product Image'}
            helperMsg={'whether product image will be shown, when selected.'}
            isDisabled={isDisabled}
            isChecked={settings.showSelectedPic === true}
            onChange={(event) =>
              handleFieldChanged('showSelectedPic', event.target.checked)
            }
          />
        )}
      </>
    );
  };

  const getContent = () => {
    switch (getSelectedAttributeType()) {
      case AttributeTypes.DROPDOWN: {
        return getOptionTextArea();
      }
      case AttributeTypes.PRODUCT_DROPDOWN: {
        return getProductDropdownSettings();
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Wrapper
      data-testid={testIdStr}
      className={`${componentName} ${className || ''}`}
    >
      {getContent()}
    </Wrapper>
  );
};

export default AttributeSettingsEditPanel;
