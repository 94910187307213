import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from '../services/auth/AuthService';
import PageInit from '../pages/PageInit';
import { RootState } from '../redux/makeReduxStore';
import {
  userAuthenticated,
  removedAuthentication,
} from '../redux/reduxers/auth.slice';
import { useNavigate } from 'react-router-dom';
import { URL_LOGIN } from '../helpers/UrlMap';

type iProtectedRoute = {
  children: React.ReactNode;
};
const ProtectedRoute = ({ children }: iProtectedRoute) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((s: RootState) => s.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user !== undefined) return;
    let isCancelled = false;
    setIsLoading(true);
    AuthService.getMe()
      .then((res) => {
        if (isCancelled === true) return;
        setIsLoading(false);
        dispatch(userAuthenticated(res));
      })
      .catch(() => {
        if (isCancelled === true) return;
        setIsLoading(false);
        AuthService.removeLocalAuthToken();
        dispatch(removedAuthentication());
        navigate(URL_LOGIN);
      });
    // eslint-disable-next-line
    return () => {
      isCancelled = true;
    };
  }, [dispatch, user]);

  //  loading
  if (isLoading === true) {
    return <PageInit />;
  }

  // not authenticated
  if (`${user?.id || ''}`.trim() === '') {
    return null;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
