import { default as Origin } from '@atlaskit/toggle';
import type {
  UIAnalyticsEvent,
  WithAnalyticsEventsProps,
} from '@atlaskit/analytics-next';
import styled from 'styled-components';
import React from 'react';
import tokens from './Tokens';
import FormField, { iFormField } from './FormField';

export interface ToggleProps extends WithAnalyticsEventsProps {
  /**
   * Sets if the toggle is disabled or not. This prevents any interaction.
   * Disabled toggles will not appear in the tab order for assistive technology.
   */
  isDisabled?: boolean;
  /**
   * Use a pairing label with your toggle using `id` and `htmlFor` props to set the relationship.
   * For more information see [labels on MDN web docs](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label).
   */
  id?: string;
  /**
   * Descriptive name for the value property, to be submitted in a form.
   */
  name?: string;
  /**
   * Value to be submitted in a form.
   */
  value?: string;
  /**
   * Handler to be called when toggle is unfocused.
   */
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement>,
    analyticsEvent: UIAnalyticsEvent,
  ) => void;
  /**
   * Handler to be called when native 'change' event happens internally.
   */
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    analyticsEvent: UIAnalyticsEvent,
  ) => void;
  /**
   * Handler to be called when toggle is focused.
   */
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement>,
    analyticsEvent: UIAnalyticsEvent,
  ) => void;
  /**
   * Toggle size.
   */
  size?: 'regular' | 'large';
  /**
   * A `testId` prop is provided for specified elements, which is a unique string that appears as a data attribute `data-testid` in the rendered code, serving as a hook for automated tests.
   * We have added 2 `testIds`:
   * - `testId` that targets the Label component to interact with the component.
   * - `{testId}--input` to check if the toggle has been checked/unchecked.
   */
  testId?: string;
  /**
   * Additional information to be included in the `context` of analytics events that come from button.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analyticsContext?: Record<string, any>;
  /**
   * Sets whether the toggle is initially checked or not.
   * After the initial interaction, whether the component is checked or not is
   * controlled by the component.
   */
  defaultChecked?: boolean;
  /**
   * If defined, it takes precedence over defaultChecked, and the toggle acts
   * as a controlled component.
   *
   * You can provide a onChange function to be notified of checked value changes
   */
  isChecked?: boolean;
  /**
   * Text to be used as an `aria-label` for the toggle component.
   *
   * Use this when there is no visible label for the toggle.
   */
  label?: string;
  /**
   * Use this when you need to provide an extended description about how the toggle works using aria-describedby.
   *
   * It's important to use this prop if the meaning of the toggle with the only a label would be unclear to people who use assistive technology.
   */
  descriptionId?: string;
}

const Wrapper = styled.div`
  .form-field-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  margin-block-start: ${tokens('space.100', '8px')};
  &.separatedLines {
    label[data-testid$='-label'] {
      width: 100%;
      margin-block-end: ${tokens('space.050', '4px')};
      margin-block-start: ${tokens('space.0', '0px')};
    }
  }
`;

export type iToggle = Omit<iFormField<ToggleProps>, 'render'> & {
  separatedLines?: boolean;
};
const Toggle = ({
  separatedLines = false,
  testId,
  className,
  ...props
}: iToggle) => {
  const testIdStr = `toggle-${testId || ''}`;

  const newProps = {
    ...props,
    testId: testIdStr,
  };
  return (
    <Wrapper
      className={`toggle-wrapper ${className || ''} ${separatedLines === true ? 'separatedLines' : ''}`}
      data-testid={`${testIdStr}-wrapper`}
    >
      <FormField<ToggleProps>
        {...newProps}
        render={(fProps) => <Origin {...fProps} />}
      />
    </Wrapper>
  );
};

export default Toggle;
