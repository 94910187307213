import ProtectedPage from '../../layouts/ProtectedPage';
import CityCouncilList from '../../components/cityCouncil/CityCouncilList';

const CityCouncilsPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `City Councils / Shires`,
      }}
    >
      {(headerProps) => (
        <CityCouncilList allowDelete headerProps={headerProps} />
      )}
    </ProtectedPage>
  );
};

export default CityCouncilsPage;
