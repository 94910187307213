import SettingsPage from '../../layouts/SettingsPage';
import BankList from '../../components/bank/BankList';

const BanksPage = () => {
  return (
    <SettingsPage title={'Banks'}>
      {(headerProps) => <BankList allowDelete headerProps={headerProps} />}
    </SettingsPage>
  );
};

export default BanksPage;
