import ServiceHelper from '../helper/ServiceHelper';
import iAddress from '../../types/system/iAddress';

const endPoint = '/address';

const AddressService = {
  getAll: ServiceHelper.getPaginatedAll<iAddress>(endPoint),
  get: ServiceHelper.getById<iAddress>(endPoint),
  create: ServiceHelper.create<iAddress>(endPoint),
  update: ServiceHelper.updateById<iAddress>(endPoint),
  deactivate: ServiceHelper.deactivateById<iAddress>(endPoint),
};

export default AddressService;
