import ServiceHelper from '../helper/ServiceHelper';
import iBuildStylePrice from '../../types/build/iBuildStylePrice';

const endPoint = '/buildStylePrice';
const BuildStylePriceService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildStylePrice>(endPoint),
  get: ServiceHelper.getById<iBuildStylePrice>(endPoint),
  create: ServiceHelper.create<iBuildStylePrice>(endPoint),
  update: ServiceHelper.updateById<iBuildStylePrice>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildStylePrice>(endPoint),
};

export default BuildStylePriceService;
