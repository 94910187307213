import ServiceHelper from '../helper/ServiceHelper';
import iAttributeItem from '../../types/attribute/iAttributeItem';

const endPoint = '/attributeItem';
const AttributeItemService = {
  getAll: ServiceHelper.getPaginatedAll<iAttributeItem>(endPoint),
  get: ServiceHelper.getById<iAttributeItem>(endPoint),
  create: ServiceHelper.create<iAttributeItem>(endPoint),
  update: ServiceHelper.updateById<iAttributeItem>(endPoint),
  deactivate: ServiceHelper.deactivateById<iAttributeItem>(endPoint),
};

export default AttributeItemService;
