import styled from 'styled-components';
import iBuild from '../../../types/build/iBuild';
import { formatDate } from '../../frameWork/DateTimePicker';
import Flex from '../../frameWork/Flex';
import FormField from '../../frameWork/FormField';
import moment from 'moment';
import { RIGHT_PANEL_WIDTH } from '../../layout/LeftAndRightPanel';
import MathHelper from '../../../helpers/MathHelper';
import Lozenge from '../../frameWork/Lozenge';
import { Label } from '../../frameWork/Form';
import Tokens from '../../frameWork/Tokens';
import UserHelper from '../../../helpers/UserHelper';
import { ViewWrapper } from '../../frameWork/InlineEdit';

const Wrapper = styled.div`
  .style-name {
    padding: ${Tokens('space.100')};
  }
  form,
  form > div {
    margin-top: 0;
  }

  .row {
    flex-wrap: wrap;
    > div {
      width: ${MathHelper.div(MathHelper.sub(RIGHT_PANEL_WIDTH, 50), 3)}px;
      margin-block-start: ${Tokens('space.200', '1rem')};
    }
  }
  .person-col {
    width: 120px;
  }
`;
export type iBuildRightPanel = {
  build: iBuild;
};
const BuildRightPanel = ({ build }: iBuildRightPanel) => {
  const formatedContractDate = formatDate(build.contractDate);
  const formatedSettlementDate = formatDate(build.settlementDate);
  const formatedActualStartDate = formatDate(build.actualStartDate);
  const formatedEstFinishDate = formatDate(
    build.estFinishDateFromActualStartDate,
  );
  return (
    <Wrapper>
      <div>
        <Label htmlFor={''}>Style</Label>
        <div className={'style-name'}>
          {build?.Style?.name || ''}
          {build?.BaseOffStyle && (
            <>
              {' '}
              <Lozenge appearance={'success'}>
                {build?.BaseOffStyle?.name || ''}
              </Lozenge>
            </>
          )}
        </div>
      </div>
      <Flex className={'gap-1 row'}>
        <FormField
          label={'Contract Date'}
          render={() => (
            <ViewWrapper className={'none-pointer'}>
              {formatedContractDate &&
                moment(formatedContractDate).format('ll')}
            </ViewWrapper>
          )}
        />
        <FormField
          label={'Contract Days'}
          render={() => (
            <ViewWrapper className={'none-pointer'}>
              {build.contractDays || ''}
            </ViewWrapper>
          )}
        />
      </Flex>
      <Flex className={'gap-1 row'}>
        <FormField
          label={'Actual Start Date'}
          render={() => (
            <ViewWrapper className={'none-pointer'}>
              {formatedActualStartDate &&
                moment(formatedActualStartDate).format('ll')}
            </ViewWrapper>
          )}
        />
        <FormField
          label={'Est. Finish Date'}
          render={() => (
            <ViewWrapper className={'none-pointer'}>
              {formatedEstFinishDate &&
                moment(formatedEstFinishDate).format('ll')}
            </ViewWrapper>
          )}
        />
        <FormField
          label={'Days Left'}
          render={() => (
            <ViewWrapper className={'none-pointer'}>
              {formatedEstFinishDate &&
                moment(formatedEstFinishDate).diff(moment(), 'days')}
            </ViewWrapper>
          )}
        />
      </Flex>
      <Flex className={'gap-1 flex-wrap'}>
        <FormField
          label={'Settlement Date'}
          render={() => (
            <ViewWrapper className={'none-pointer'}>
              <Flex className={'gap-1 align-items-center'}>
                <div>
                  {formatedSettlementDate &&
                    moment(formatedSettlementDate).format('ll')}
                </div>
                {build.isSettlementDateConfirmed && (
                  <Lozenge appearance={'success'}>is confirmed</Lozenge>
                )}
              </Flex>
            </ViewWrapper>
          )}
        />
      </Flex>
      <Flex className={'gap-1 flex-wrap'}>
        {[
          {
            label: 'Sales Person',
            value: () => UserHelper.getFullName(build.SalesPerson),
          },
          {
            label: 'Sales Manager',
            value: () => UserHelper.getFullName(build.SalesManager),
          },
          {
            label: 'Start Supervisor',
            value: () => UserHelper.getFullName(build.Supervisor1),
          },
          {
            label: 'End Supervisor',
            value: () => UserHelper.getFullName(build.Supervisor2),
          },
        ].map((item) => {
          return (
            <FormField
              className={'person-col'}
              label={item.label}
              key={item.label}
              render={() => (
                <ViewWrapper className={'none-pointer'}>
                  {item.value()}
                </ViewWrapper>
              )}
            />
          );
        })}
      </Flex>
    </Wrapper>
  );
};

export default BuildRightPanel;
