import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import iProduct from '../../types/product/iProduct';
import ProductService from '../../services/product/ProductService';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';
import { iOptionWithData } from '../frameWork/Select';

export type iProductSelector = iPreloadAsyncSelectProps & {
  attributeSetCodes?: string[];
  isForBuildOnly?: boolean;
};
const ProductSelector = ({
  attributeSetCodes = [],
  isForBuildOnly,
  isMulti = false,
  value,
  values,
  ...props
}: iProductSelector) => {
  const providedValues = values || value || null;
  return (
    <PreloadedAsyncSelector<iProduct>
      placeholder={'Select a Product ...'}
      {...props}
      isMulti={isMulti}
      values={isMulti === true ? providedValues : null}
      value={isMulti === true ? null : providedValues}
      optionsUniqBy={(data: iProduct) => data.id}
      formatOptionLabel={(data: iOptionWithData<iProduct>) => {
        const value = data.data || {};
        return (
          <div>{'productCode' in value ? value.productCode : data.label}</div>
        );
      }}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return ProductService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(isForBuildOnly === true ? { isForBuild: true } : {}),
            ...(attributeSetCodes.length <= 0
              ? {}
              : { attributeSetCode: attributeSetCodes }),
            ...(searchTxtStr === ''
              ? {}
              : {
                  [OP_OR]: [
                    { name: { [OP_LIKE]: `%${searchTxtStr}%` } },
                    { productCode: { [OP_LIKE]: `%${searchTxtStr}%` } },
                  ],
                }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        ProductService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default ProductSelector;
