import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import iPlanRevision from '../../types/plan/iPlanRevision';
import PlanRevisionService from '../../services/plan/PlanRevisionService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';

type iPlanRevisionList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};
const PlanRevisionList = ({ allowDelete, headerProps }: iPlanRevisionList) => {
  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iPlanRevision>({
      sort: `sort:ASC`,
      getFn: (params) =>
        PlanRevisionService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = (PlanRevision?: iPlanRevision) => {
    return renderEntityEditPopBtn<iPlanRevision>({
      editingEntity: PlanRevision,
      entityName: 'Plan Revision',
      createFn: (data) => PlanRevisionService.create(data),
      updateFn: (id, data) => PlanRevisionService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'PlanRevision-name',
        },
        {
          fieldName: 'description',
          label: 'Description',
          isDisabled,
          value: entity?.description || '',
          testId: 'PlanRevision-description',
        },
        {
          fieldName: 'sort',
          label: 'Sort',
          isDisabled,
          isRequired: true,
          value: entity?.sort || '1',
          testId: 'PlanRevision-sort',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iPlanRevision>[] => [
    {
      key: 'name',
      header: 'Name',
      cell: ({ data }: iCellParams<iPlanRevision>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'description',
      header: 'Description',
      cell: ({ data }: iCellParams<iPlanRevision>) =>
        `${data?.description || ''}`,
    },
    {
      key: 'sort',
      header: 'Sort',
      cell: ({ data }: iCellParams<iPlanRevision>) => `${data?.sort || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iPlanRevision>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iPlanRevision>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      PlanRevisionService.deactivate(data.id),
                    getDisplayName: (PlanRevision) => PlanRevision.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: 'PlanRevision-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default PlanRevisionList;
