import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import BuildSuspensionService from '../../services/buildSuspension/BuildSuspensionService';
import iBuildSuspension from '../../types/buildSuspension/iBuildSuspension';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import React from 'react';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import BuildSuspensionEditPopBtn from './BuildSuspensionEditPopBtn';

type iBuildSuspensionList = iComponentWithPageHeader & {
  buildId: string;
  allowDelete?: boolean;
  onSaved?: (suspension: iBuildSuspension, isCreated: boolean) => void;
  onDeleted?: () => void;
};
const BuildSuspensionList = ({
  buildId,
  headerProps,
  allowDelete,
  onSaved,
  onDeleted,
}: iBuildSuspensionList) => {
  const buildIdStr = `${buildId || ''}`.trim();
  const testIdStr = `BuildSuspension-list`;

  const { renderDataTable, renderDeleteBtn, onRefreshWhenCreated, onRefresh } =
    useListCrudHook<iBuildSuspension>({
      sort: `createdAt:DESC`,
      getFn: (params) =>
        BuildSuspensionService.getAll({
          where: JSON.stringify({
            isActive: true,
            buildId: buildIdStr,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy,Type,Reason',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getTypeName = (suspension: iBuildSuspension) => {
    return `${suspension?.Type?.name || 'NO TYPE'}`.trim();
  };

  const getReasonName = (suspension: iBuildSuspension) => {
    return `${suspension?.Reason?.name || 'NO REASON'}`.trim();
  };

  const getEditBtn = (suspension?: iBuildSuspension) => {
    return (
      <BuildSuspensionEditPopBtn
        buildId={buildId}
        buildSuspension={suspension}
        onSaved={(saved, isCreated) => {
          if (isCreated) {
            onRefreshWhenCreated();
          } else {
            onRefresh();
          }
          onSaved && onSaved(saved, isCreated);
        }}
        renderBtn={(onClick) => {
          if (!suspension || `${suspension.id || ''}`.trim() === '') {
            return getCreateIconBtn({
              onClick: onClick,
              testId: `create-btn-suspension`,
              label: `Create a new Suspension / On-Hold`,
              isTooltipDisabled: false,
            });
          }
          return (
            <a
              onClick={onClick}
              className={'cursor-pointer'}
              data-testid={`BuildSuspension-${suspension?.id || ''}`}
            >
              {getTypeName(suspension)}
            </a>
          );
        }}
      />
    );
  };

  const getColumns = (): iTableColumn<iBuildSuspension>[] => [
    {
      key: 'type',
      header: 'Type',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildSuspension>) => getEditBtn(data),
    },
    {
      key: 'reason',
      header: 'Reason',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildSuspension>) => getReasonName(data),
    },
    {
      key: 'startDate',
      header: 'Start Date',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildSuspension>) =>
        DynamicTableHelper.displayDateCell(data.startDate),
    },
    {
      key: 'endDate',
      header: 'End Date',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildSuspension>) =>
        DynamicTableHelper.displayDateCell(data.endDate),
    },
    {
      key: 'details',
      header: 'Details',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildSuspension>) => data.details || '',
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iBuildSuspension>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iBuildSuspension>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    onDeleted: onDeleted,
                    deleteFnc: async () =>
                      BuildSuspensionService.deactivate(data.id),
                    getDisplayName: (suspension) =>
                      `${getTypeName(suspension)} - ${getReasonName(suspension)}`,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.BUILD_SUSPENSION_LIST,
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default BuildSuspensionList;
