import ServiceHelper from '../helper/ServiceHelper';
import iGasType from '../../types/system/iGasType';

const endPoint = '/gasType';

const GasTypeService = {
  getAll: ServiceHelper.getPaginatedAll<iGasType>(endPoint),
  get: ServiceHelper.getById<iGasType>(endPoint),
  create: ServiceHelper.create<iGasType>(endPoint),
  update: ServiceHelper.updateById<iGasType>(endPoint),
  deactivate: ServiceHelper.deactivateById<iGasType>(endPoint),
};

export default GasTypeService;
