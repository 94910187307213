import iAsset from '../../types/asset/iAsset';
import AssetService from '../../services/asset/AssetService';
import { IconButton, IconButtonProps, LinkButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import PopupBtn, {
  iModelPropsFn,
  iPopupBtn,
  iSetShowingModalFn,
} from '../common/PopupBtn';
import { useEffect, useState } from 'react';
import { getFooterWithBtns } from '../common/PopupModal';
import TextField from '../frameWork/TextField';
import CopyToClipboardBtn from '../common/CopyToClipboardBtn';
import Toaster from '../common/Toaster';

type iGetAssetPublicUrlPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  asset: iAsset;
  btnProps?: Omit<IconButtonProps, 'onClick'>;
  modalProps?: iModelPropsFn;
};
const GetAssetPublicUrlPopupBtn = ({
  asset,
  renderBtn,
  btnProps,
  modalProps,
  ...props
}: iGetAssetPublicUrlPopupBtn) => {
  const testIdStr = `GetAssetPublicUrlPopupBtn-btn-${asset.id}`;
  const [publicUrl, setPublicUrl] = useState<string | null>(null);
  const [isGetting, setIsGetting] = useState(false);

  useEffect(() => {
    if (publicUrl !== null) {
      return;
    }
    if (asset.isPublic === true) {
      setPublicUrl(asset.url || '');
    }
  }, [publicUrl, asset.url, asset.isPublic]);

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    setPublicUrl(null);
    setIsGetting(false);
    setModelShowing(false);
  };

  const getBody = () => {
    const publicUrlStr = `${publicUrl || ''}`.trim();
    if (publicUrlStr !== '') {
      return (
        <>
          <p>This asset is now PUBLIC, here is the url:</p>
          <TextField
            value={publicUrlStr}
            onChange={() => null}
            elemAfterInput={<CopyToClipboardBtn valueToCopy={publicUrlStr} />}
          />
        </>
      );
    }
    return (
      <div>
        This asset is currently inaccessible by public. You can temporarily
        access it by generating a temporary URL through the button below:
      </div>
    );
  };

  const getTempUrl = () => {
    setIsGetting(true);
    AssetService.getPublicUrl(asset.id)
      .then((resp) => {
        setPublicUrl(resp.url);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsGetting(false);
      });
  };

  return (
    <PopupBtn
      titleId={testIdStr}
      {...props}
      modalProps={(setModelShowing, isDisabled) => {
        const url = `${publicUrl || ''}`.trim();
        return {
          title: 'Getting public url for this asset',
          body: getBody(),
          footer: getFooterWithBtns({
            cancelBtnProps: {
              onClick: () => handleClose(setModelShowing),
              isDisabled,
              isLoading: isGetting,
              btnText: url === '' ? 'Cancel' : 'Close',
            },
            ...(url === ''
              ? {
                  actionBtnProps: {
                    isDisabled,
                    isLoading: isGetting,
                    btnText: 'Get Url',
                    iconBefore: Icons.SendIcon,
                    onClick: () => getTempUrl(),
                  },
                }
              : {
                  actionBtn: (
                    <LinkButton
                      href={url}
                      target={'_blank'}
                      iconBefore={Icons.LinkIcon}
                      appearance={'primary'}
                    >
                      Open
                    </LinkButton>
                  ),
                }),
          }),
          ...(modalProps && modalProps(setModelShowing, isDisabled)),
        };
      }}
      renderBtn={(onClick) => {
        if (renderBtn) {
          return renderBtn(onClick);
        }
        return (
          <IconButton
            label="Get public url"
            testId={`${testIdStr}-btn`}
            icon={Icons.LinkIcon}
            appearance={'subtle'}
            {...btnProps}
            onClick={onClick}
          />
        );
      }}
    />
  );
};

export default GetAssetPublicUrlPopupBtn;
