import SettingsPage from '../../layouts/SettingsPage';
import CompanyEditPanel from '../../components/settings/CompanyEditPanel';

const CompanyProfilePage = () => {
  return (
    <SettingsPage title={'Company Profile'}>
      <CompanyEditPanel />
    </SettingsPage>
  );
};

export default CompanyProfilePage;
