import ServiceHelper from '../helper/ServiceHelper';
import iBuildStyle from '../../types/build/iBuildStyle';

const endPoint = '/buildStyle';
const BuildStyleService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildStyle>(endPoint),
  get: ServiceHelper.getById<iBuildStyle>(endPoint),
  create: ServiceHelper.create<iBuildStyle>(endPoint),
  update: ServiceHelper.updateById<iBuildStyle>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildStyle>(endPoint),
};

export default BuildStyleService;
