import {
  default as Origin,
  SectionMessageProps,
} from '@atlaskit/section-message';
import styled from 'styled-components';
import Tokens from './Tokens';
import { useState } from 'react';
import { IconButton } from './Button';
import Icons from './Icons';

export type iSectionMessage = SectionMessageProps & {
  className?: string;
  allowClose?: boolean;
};
const Wrapper = styled.div`
  margin-block-end: ${Tokens('space.150', '0.75rem')};
  position: relative;
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;
const SectionMessage = ({
  className,
  allowClose = true,
  ...props
}: iSectionMessage) => {
  const [isShowing, setIsShowing] = useState(true);

  const getClassBtn = () => {
    if (allowClose !== true) {
      return null;
    }
    return (
      <span className={'close-btn'}>
        <IconButton
          icon={Icons.CrossIcon}
          label={'Close'}
          appearance={'subtle'}
          onClick={() => setIsShowing(false)}
        />
      </span>
    );
  };

  if (isShowing !== true) {
    return null;
  }

  return (
    <Wrapper className={`section-msg-wrapper ${className || ''}`}>
      <Origin {...props} />
      {getClassBtn()}
    </Wrapper>
  );
};

export default SectionMessage;
