import FinanceTypeList from '../../components/financeType/FinanceTypeList';
import SettingsPage from '../../layouts/SettingsPage';

const FinanceTypePage = () => {
  return (
    <SettingsPage title={'FinanceType'}>
      {(headerProps) => (
        <FinanceTypeList allowDelete headerProps={headerProps} />
      )}
    </SettingsPage>
  );
};

export default FinanceTypePage;
