import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import iUser from '../../types/system/iUser';
import UserService from '../../services/system/UserService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import React from 'react';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import { AvatarItem } from '../frameWork/Avatar';
import UserAvatar from './UserAvatar';
import UserHelper from '../../helpers/UserHelper';
import UserEditPopupBtn from './UserEditPopupBtn';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/makeReduxStore';
type iUserList = iComponentWithPageHeader & {
  allowDelete: boolean;
};

const UserList = ({ headerProps, allowDelete = false }: iUserList) => {
  const { user: currentUser } = useSelector((s: RootState) => s.auth);
  const {
    renderDataTable,
    onRefreshOnCurrentPage,
    onRefreshWhenCreated,
    renderDeleteBtn,
  } = useListCrudHook<iUser>({
    sort: `firstName:ASC,lastName:ASC`,
    getFn: (params) =>
      UserService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const columns: iTableColumn<iUser>[] = [
    {
      key: 'firstName',
      header: 'Name',
      isSortable: true,
      cell: ({ data }) => (
        <UserEditPopupBtn
          user={data}
          renderBtn={(onclick) => (
            <AvatarItem
              testId={`UserEditPopupBtn-${data.id}`}
              avatar={<UserAvatar user={data} />}
              primaryText={
                <div className={'text-uppercase'}>
                  {UserHelper.getFullName(data)}
                </div>
              }
              secondaryText={data.username}
              onClick={onclick}
            />
          )}
          onSaved={() => {
            onRefreshOnCurrentPage();
          }}
        />
      ),
    },
    {
      key: 'email',
      header: 'Email',
      isSortable: true,
      cell: ({ data }) => data.email,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iUser>(),
    ...(allowDelete === true
      ? [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iUser>) => {
              if (
                currentUser?.id === data.id &&
                `${data.id || ''}`.trim() !== ''
              ) {
                return;
              }

              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    getDisplayName: (user) => UserHelper.getFullName(user),
                    deleteFnc: () => UserService.deactivate(data.id),
                  })}
                </div>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={
              <UserEditPopupBtn
                onSaved={() => onRefreshWhenCreated()}
                renderBtn={(onClick) =>
                  getCreateIconBtn({
                    onClick: onClick,
                    label: 'Create a new user',
                    isTooltipDisabled: false,
                  })
                }
              />
            }
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns,
        tblProps: {
          testId: 'user-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default UserList;
