import PageHeader, { iPageHeader } from '../frameWork/PageHeader';
import React from 'react';

export type iComponentWithPageHeader = {
  headerProps?: iPageHeader;
  children?: React.ReactNode;
};
const ComponentWithPageHeader = ({
  children,
  headerProps,
}: iComponentWithPageHeader) => {
  return (
    <>
      {headerProps && <PageHeader {...headerProps} />}
      {children}
    </>
  );
};

export default ComponentWithPageHeader;
