import styled from 'styled-components';
import { ReactNode, useEffect, useState } from 'react';
import iProduct from '../../types/product/iProduct';
import { Link } from 'react-router-dom';
import { URL_PRODUCT_DETAILS } from '../../helpers/UrlMap';
import ProductService from '../../services/product/ProductService';
import Toaster from '../common/Toaster';
import AttachmentService from '../../services/attachment/AttachmentService';
import EntityNames from '../../helpers/EntityNames';
import iAsset, { AssetTypes } from '../../types/asset/iAsset';
import AssetThumbnail from '../asset/AssetThumbnail';
import Icons from '../frameWork/Icons';
import Flex from '../frameWork/Flex';
import StringHelper from '../../helpers/StringHelper';

const Wrapper = styled.div`
  &.picture {
    display: flex;
    gap: 0.4rem;
    .pic-thumb {
      width: 54px !important;
      height: 54px !important;
      object-fit: contain;
      object-position: center center;
      -o-object-fit: contain;
      -o-object-position: center center;
    }
    .name-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
`;
export type iProductDiv = {
  productId: string;
  render?: (product: iProduct, productPic?: iAsset | null) => ReactNode;
  showPicture?: boolean;
  testId?: string;
  className?: string;
};
const ProductDiv = ({
  productId,
  testId,
  render,
  className,
  showPicture = false,
}: iProductDiv) => {
  const componentName = 'product-div';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [product, setProduct] = useState<iProduct | null>(null);
  const [productPic, setProductPic] = useState<iAsset | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (`${productId || ''}`.trim() === '' || !StringHelper.isUUID(productId)) {
      setProductPic(null);
      setProduct(null);
      return;
    }
    let isCanceled = false;
    setIsLoading(true);
    Promise.all([
      ProductService.get(productId),
      ...(showPicture === true
        ? [
            AttachmentService.getAll({
              where: JSON.stringify({
                isActive: true,
                entityId: productId,
                entityName: EntityNames.Product,
                type: AssetTypes.PRODUCT_PHOTOS,
              }),
              include: 'Asset',
              sort: 'updatedAt:DESC',
              perPage: 1,
            }),
          ]
        : []),
    ])
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        setProduct(resp[0]);
        if (showPicture === true) {
          const attachments = resp[1].data || [];
          setProductPic(
            attachments.length > 0 ? attachments[0].Asset || null : null,
          );
        }
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [productId, showPicture]);

  const getProductNameWithLink = (prod: iProduct) => {
    return (
      <Flex className={'gap-025 align-items-center'}>
        <Link
          data-testid={`${testIdStr}-link`}
          to={URL_PRODUCT_DETAILS.replace(':id', productId)}
          className={'name'}
          title={'View product details'}
        >
          <Icons.LinkIcon size={'small'} label={''} />
        </Link>
        <div>{prod.name}</div>
      </Flex>
    );
  };

  const getContent = () => {
    if (!StringHelper.isUUID(productId)) {
      return <>{productId}</>;
    }
    if (!product || isLoading) {
      return null;
    }

    if (render) {
      return render(product, showPicture !== true ? undefined : productPic);
    }

    if (showPicture === true) {
      return (
        <>
          <AssetThumbnail asset={productPic} className={'pic-thumb'} />
          <div className={'name-wrapper'}>
            {getProductNameWithLink(product)}
            <small>{product.productCode}</small>
          </div>
        </>
      );
    }

    return getProductNameWithLink(product);
  };

  return (
    <Wrapper
      className={`${componentName} ${className || ''} ${showPicture ? 'picture' : ''}`}
      data-testid={testIdStr}
    >
      {getContent()}
    </Wrapper>
  );
};
export default ProductDiv;
