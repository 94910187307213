import { iConfigParams } from '../../../services/AppService';
import iPaginatedResult from '../../../types/iPaginatedResult';

export interface iDataState<T> {
  data: iPaginatedResult<T>;
  isLoading: boolean;
}

export const getInitDataState = (currentPage: number, perPage: number) => {
  return {
    data: { data: [], perPage, currentPage },
    isLoading: false,
  };
};

export interface iViewingState {
  perPage?: number;
  sort?: string;
  filter?: iConfigParams;
  currentPage?: number;
  version: number;
}

export enum ActionKind {
  Loading = 'LOADING',
  Loaded = 'LOADED',
  Delete = 'DELETE',
}

export type Action<T> = {
  type: ActionKind;
  payload: {
    data?: iPaginatedResult<T>;
    currentPage?: number;
    sort?: string;
    filter?: iConfigParams;

    targetId?: string;
    keyword?: string;
    item?: T;
  };
};

export const reducer = <T extends { id: string }>(
  state: iDataState<T>,
  action: Action<T>,
): iDataState<T> => {
  switch (action.type) {
    case ActionKind.Loading:
      return { ...state, isLoading: true };
    case ActionKind.Loaded:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data ? action.payload.data : state.data,
      };
    default:
      return state;
  }
};
