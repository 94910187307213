import Select, { convertSameValueToOption, iSelect } from '../frameWork/Select';
import * as _ from 'lodash';
import React, { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
type iPageSizeSelector = iSelect & {
  extraOptions?: number[];
};
const PageSizeSelector = ({
  className,
  extraOptions,
  value,
  ...props
}: iPageSizeSelector) => {
  const [range, setRange] = React.useState([10, 30, 50, 100]);

  useEffect(() => {
    setRange(
      _.sortBy(
        _.uniq(
          [10, 30, 50, 100, value, ...(extraOptions || [])].filter(
            (val) => `${val || ''}`.trim() !== '',
          ),
        ),
      ),
    );
  }, [extraOptions, value]);

  const getValue = () => {
    if (value !== undefined && value !== null) {
      return convertSameValueToOption(value);
    }
    return convertSameValueToOption(range[0]);
  };

  return (
    <Select
      {...props}
      className={`page-size-selector ${className || ''}`}
      isMulti={false}
      isSearchable={false}
      value={getValue()}
      options={range.map((item) => convertSameValueToOption(item))}
    />
  );
};

export default PageSizeSelector;
