import {
  ButtonItem as OriginButtonItem,
  LinkItem as OriginLinkItem,
  NavigationFooter as OriginNavigationFooter,
  NavigationHeader as OriginNavigationHeader,
  NestableNavigationContent as OriginNestableNavigationContent,
  NestingItem as OriginNestingItem,
  Section as OriginSection,
  SideNavigation as OriginSideNavigation,
  NavigationContent as OriginNavigationContent,
  HeadingItem as OriginHeadingItem,
} from '@atlaskit/side-navigation';

export const ButtonItem = OriginButtonItem;
export const LinkItem = OriginLinkItem;
export const NavigationFooter = OriginNavigationFooter;
export const NavigationHeader = OriginNavigationHeader;
export const NestableNavigationContent = OriginNestableNavigationContent;
export const NestingItem = OriginNestingItem;
export const HeadingItem = OriginHeadingItem;
export const Section = OriginSection;
export const NavigationContent = OriginNavigationContent;

const SideNavigation = OriginSideNavigation;

export default SideNavigation;
