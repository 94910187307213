import ProtectedPage from '../layouts/ProtectedPage';
import PageHeader from '../components/frameWork/PageHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/makeReduxStore';

const HomePage = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  return (
    <ProtectedPage>
      <PageHeader>Welcome, {user?.firstName}</PageHeader>
      <div>Everything is being made at the moment, watch this space...</div>
    </ProtectedPage>
  );
};

export default HomePage;
