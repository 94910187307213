import Icons from '../../../frameWork/Icons';
import iBuild from '../../../../types/build/iBuild';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';

export enum BuildDetailsOrdersKeys {
  SLAB = 'Slab',
  FRAME = 'Frame, Doors and Windows',
  BRICKS = 'Bricks & Roof Plumbing',
  ELECTRICAL = 'Electrical',
  PLUMBING = 'Plumbing',
  CABINETS = 'Cabinets',
  FIXINGS = 'Fixings',
  PAINTING = 'Painting',
  FLOOR_WALL_COVERT = 'Floor / Wall Cover',
  COMPLETION_WORKS = 'Completion Works',
  PLANS = 'Plans',
  ORDER_NOTES = 'Notes',
  START_TO_LOCKUP = 'Start to Lockup',
  LOCKUP_TO_COMPLETION = 'Lockup To Completion',
}

type iGetBuildDetailsOrdersMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const getBuildDetailsOrdersMenuItemsMap = ({
  build,
}: iGetBuildDetailsOrdersMenuItemsMap): iMenuItemsMap => {
  return Object.keys(BuildDetailsOrdersKeys).reduce((map, key) => {
    const value =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      key in BuildDetailsOrdersKeys ? BuildDetailsOrdersKeys[key] : '';
    return {
      ...map,
      [key]: {
        name: value,
        icon: <Icons.OverviewIcon label={value} />,
        component: (
          <div>
            {value}: {build.id}
          </div>
        ),
      },
    };
  }, {});
};

const BuildDetailsOrdersHelper = {
  getBuildDetailsOrdersMenuItemsMap,
};
export default BuildDetailsOrdersHelper;
