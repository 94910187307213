import TextField from '../frameWork/TextField';
import { getErrorProps } from '../form/FormError';
import Select from '../frameWork/Select';
import iAttribute, { AttributeTypes } from '../../types/attribute/iAttribute';
import styled from 'styled-components';
import { iUseAsForm } from '../form/EntityEditPanel';
import Tabs from '../frameWork/Tabs';
import { useState } from 'react';
import AttributeSettingsEditPanel from './components/AttributeSettingsEditPanel';
import AttributeForSectionEditPanel from './components/AttributeForSectionEditPanel';
import Flex from '../frameWork/Flex';
import Tokens from '../frameWork/Tokens';
import AttributeDisplayLogic from './components/AttributeDisplayLogic';
import AttributeSettingsHelper from './components/AttributeSettingsHelper';

const Wrapper = styled.div`
  .general-row > * {
    width: 40%;
    margin-bottom: ${Tokens('space.200', '1rem')};
  }
`;

enum ViewingTabs {
  General = 'General',
  UsedFor = 'Used For',
  EditLogic = 'Edit Logic',
}
type iAttributeEditPanel = {
  attribute?: iAttribute;
  isDisabled?: boolean;
  useAsFrom: iUseAsForm;
};
const AttributeEditPanel = ({
  attribute,
  isDisabled = false,
  useAsFrom,
}: iAttributeEditPanel) => {
  const [selectedTab, setSelectedTab] = useState<string>(ViewingTabs.General);
  const getSelectedAttributeType = () => `${attribute?.type || ''}`.trim();

  const getSelectedTypeHelperMsg = () => {
    if (getSelectedAttributeType() === AttributeTypes.PRODUCT_DROPDOWN) {
      return 'IsForBuild products with the same AttributeSet can be selected.';
    }
    if (getSelectedAttributeType() === AttributeTypes.SUPPLIER_DROPDOWN) {
      return 'A supplier can be selected.';
    }
    return;
  };

  const getSelectedAttributeTypeOption = () => {
    const type = getSelectedAttributeType();
    if (type === '') {
      return undefined;
    }
    return { label: type, value: type };
  };

  return (
    <Wrapper>
      <Flex
        className={
          'general-row justify-content-start align-items-flex-start gap-1'
        }
      >
        <TextField
          isRequired
          label={'Name'}
          testId={'name'}
          className={'name-input'}
          placeholder={'The name of the attribute'}
          isDisabled={isDisabled}
          value={attribute?.name || ''}
          {...getErrorProps({
            fieldName: 'name',
            error: useAsFrom.errorMap,
          })}
          onChange={(event) =>
            useAsFrom.onFieldChange(
              'name',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              event.target.value || '',
            )
          }
        />
        <Select
          isRequired
          label={'Type'}
          testId={'type'}
          className={'type-selector'}
          isDisabled={isDisabled}
          value={getSelectedAttributeTypeOption()}
          {...getErrorProps({
            fieldName: 'type',
            error: useAsFrom.errorMap,
          })}
          onChange={(option) =>
            useAsFrom.onFieldChange('type', option?.value || '')
          }
          options={Object.values(AttributeTypes).map((type) => ({
            label: type,
            value: type,
          }))}
          helperMsg={getSelectedTypeHelperMsg()}
        />
      </Flex>
      <Tabs
        activeKey={selectedTab}
        onSelect={(key) => setSelectedTab(key)}
        appearance={'tabs'}
        tabs={[
          {
            eventKey: ViewingTabs.General,
            title: 'Options',
            tabContent: (
              <>
                <AttributeSettingsEditPanel
                  attribute={attribute}
                  useAsFrom={useAsFrom}
                />
              </>
            ),
          },
          {
            eventKey: ViewingTabs.UsedFor,
            title: ViewingTabs.UsedFor,
            tabContent: (
              <AttributeForSectionEditPanel
                attribute={attribute}
                isDisabled={isDisabled}
                onUpdate={(newSettings) => {
                  useAsFrom.onFieldChange('settings', {
                    ...(attribute?.settings || {}),
                    ...newSettings,
                  });
                }}
              />
            ),
          },
          ...(AttributeSettingsHelper.isAttributeEditable(attribute)
            ? [
                {
                  eventKey: ViewingTabs.EditLogic,
                  title: ViewingTabs.EditLogic,
                  tabContent: (
                    <AttributeDisplayLogic
                      attribute={attribute}
                      isDisabled={isDisabled}
                      onUpdate={(newSettings) => {
                        useAsFrom.onFieldChange('settings', {
                          ...(attribute?.settings || {}),
                          ...newSettings,
                        });
                      }}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    </Wrapper>
  );
};

export default AttributeEditPanel;
