import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import iAttribute from '../../types/attribute/iAttribute';
import AttributeService from '../../services/attribute/AttributeService';
import { iOptionWithData } from '../frameWork/Select';
import { OP_LIKE, OP_NOT, OP_OR } from '../../services/ServiceHelper';

export type iAttributeSelector = iPreloadAsyncSelectProps & {
  excludeIds?: string[];
};
const AttributeSelector = ({
  excludeIds = [],
  ...props
}: iAttributeSelector) => {
  return (
    <PreloadedAsyncSelector<iAttribute>
      placeholder={'Select an Attribute ...'}
      {...props}
      optionsUniqBy={(data: iAttribute) => data.id}
      formatOptionLabel={(data: iOptionWithData<iAttribute>) => {
        return `${data.data.AttributeSet?.name} / ${data.data.name}`;
      }}
      getFn={async (data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return AttributeService.getAll({
          ...params,
          sort: 'attributeSetCode:ASC,name:ASC',
          include: 'AttributeSet',
          where: JSON.stringify({
            isActive: true,
            ...(excludeIds.length > 0 ? { id: { [OP_NOT]: excludeIds } } : {}),
            ...(searchTxtStr === ''
              ? {}
              : {
                  [OP_OR]: [
                    { attributeSetCode: { [OP_LIKE]: `%${searchTxtStr}%` } },
                    { name: { [OP_LIKE]: `%${searchTxtStr}%` } },
                  ],
                }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        AttributeService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
          include: 'AttributeSet',
        })
      }
    />
  );
};

export default AttributeSelector;
