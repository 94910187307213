import ServiceHelper from '../helper/ServiceHelper';
import iGarageSize from '../../types/build/iGarageSize';

const endPoint = '/garageSize';
const GarageSizeService = {
  getAll: ServiceHelper.getPaginatedAll<iGarageSize>(endPoint),
  get: ServiceHelper.getById<iGarageSize>(endPoint),
  create: ServiceHelper.create<iGarageSize>(endPoint),
  update: ServiceHelper.updateById<iGarageSize>(endPoint),
  deactivate: ServiceHelper.deactivateById<iGarageSize>(endPoint),
};

export default GarageSizeService;
