import { useNavigate } from 'react-router-dom';
import EmptyState, { iEmptyState } from '../components/frameWork/EmptyState';
import styled from 'styled-components';
import Button from '../components/frameWork/Button';
import Icons from '../components/frameWork/Icons';

type iPageNotFound = Omit<iEmptyState, 'header'> &
  Partial<Pick<iEmptyState, 'header'>>;
const Wrapper = styled.div`
  img {
    height: auto !important;
    width: 600px !important;
    --max-width: 100% !important;
    --max-height: 100% !important;
  }
`;
const PageNotFound = (props: iPageNotFound) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <EmptyState
        imageUrl={'/images/404.svg'}
        headingLevel={2}
        header={'Ops'}
        description={'Requested page not found.'}
        primaryAction={
          <Button
            testId={'home-btn'}
            appearance={'primary'}
            onClick={() => navigate('/')}
            iconBefore={Icons.HomeIcon}
          >
            Home
          </Button>
        }
        secondaryAction={
          <Button
            testId={'nav-back-btn'}
            onClick={() => navigate(-1)}
            iconBefore={Icons.ArrowLeftIcon}
          >
            Go Back
          </Button>
        }
        {...props}
      />
    </Wrapper>
  );
};

export default PageNotFound;
