import SettingsPage from '../../layouts/SettingsPage';
import TaxRateList from '../../components/tax/TaxRateList';

const TaxRatesPage = () => {
  return (
    <SettingsPage title={'Tax Rates'}>
      {(headerProps) => <TaxRateList allowDelete headerProps={headerProps} />}
    </SettingsPage>
  );
};

export default TaxRatesPage;
