import InlineEdit, {
  iInlineEdit,
  ViewWrapper as InlineEditWrapper,
} from './InlineEdit';
import TextArea, { iTextArea } from './TextArea';
import { CSSProperties, useState } from 'react';
import styled from 'styled-components';

type iInlineTextArea = iInlineEdit & {
  editViewProps?: iTextArea;
  readViewStyle?: CSSProperties;
};

const ViewWrapper = styled(InlineEditWrapper)`
  min-height: 60px;
  white-space: pre;
  display: block;
  overflow-y: auto;
`;
const InlineTextArea = ({
  onConfirm,
  value,
  defaultValue,
  editViewProps,
  readViewStyle,
  placeHolder,
  ...props
}: iInlineTextArea) => {
  const [localValue, setLocalValue] = useState(value || defaultValue || '');
  const [isEditing, setIsEditing] = useState(false);

  const handleConfirm = () => {
    setIsEditing(false);
    onConfirm(localValue);
  };

  return (
    <InlineEdit
      {...props}
      value={localValue}
      isEditing={isEditing}
      onEdit={() => setIsEditing(true)}
      onConfirm={() => handleConfirm()}
      onCancel={() => {
        setLocalValue(value || defaultValue || '');
        setIsEditing(false);
      }}
      readViewFitContainerWidth
      readView={() => (
        <ViewWrapper
          style={readViewStyle}
          data-testid={`read-view-${props.testId || ''}`}
          className={`view-wrapper ${
            `${value || ''}`.trim() === '' ? 'default-view' : ''
          } ${props.isDisabled === true ? 'disabled-view ' : ''}`}
        >
          {value || placeHolder || defaultValue || 'Please input some value...'}
        </ViewWrapper>
      )}
      editView={
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ errorMessage, ...fieldProps }) => {
          return (
            <TextArea
              {...fieldProps}
              {...editViewProps}
              testId={`edit-view-${props.testId || ''}`}
              value={localValue}
              onChange={(event) => setLocalValue(event.target.value)}
            />
          );
        }
      }
    />
  );
};

export default InlineTextArea;
