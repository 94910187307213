import Navigation, {
  PrimaryButton,
  PrimaryDropdownButton,
} from '../frameWork/Navigation';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/makeReduxStore';
import Logo from '../common/Logo';
import {
  URL_BACKEND,
  URL_BUILD_STYLES,
  URL_BUILDS,
  URL_CITY_COUNCILS,
  URL_CONTACT_COMPANY_LIST,
  URL_HOUSE_AREAS,
  URL_SETTINGS,
  URL_SUBURBS,
  URL_HOUSE_STOREY,
  URL_STATE,
  URL_GAS_TYPES,
  URL_ESTATE,
  URL_PRODUCTS,
} from '../../helpers/UrlMap';
import UserAvatarPopup from '../user/UserAvatarPopup';
import styled from 'styled-components';
import Tokens from '../frameWork/Tokens';
import { LinkIconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import Popup from '../frameWork/Popup';
import { ReactNode, useState } from 'react';
import { Section, LinkItem } from '../frameWork/Menu';
import { ContactCompanyTypes } from '../../types/contact/iContactCompany';

const LogoWrapper = styled.a`
  cursor: pointer;
  display: inline-block;
  margin-right: ${Tokens('space.200')};
`;

export enum TopNavOpeningMenuKey {
  CONTACTS = 'Contacts',
  MISC = 'Misc.',
  BUILD = 'Build',
  STOCK = 'Stock',
}
const TopNav = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [openingMenu, setOpeningMenu] = useState<string | null>(null);
  const testId = 'topNav';

  const getMenuPopup = (key: string, name: string, content: ReactNode) => {
    return (
      <Popup
        key={key}
        testId={`${testId}-${key}`}
        placement={'bottom-start'}
        isOpen={openingMenu === key}
        onClose={() => setOpeningMenu(null)}
        trigger={(btnProps) => (
          <PrimaryDropdownButton
            {...btnProps}
            testId={`${testId}-${key}-trigger`}
            onClick={() => setOpeningMenu(key)}
          >
            {name}
          </PrimaryDropdownButton>
        )}
        content={() => content}
      />
    );
  };

  type iLinkItem = {
    name: string;
    url: string;
  };
  const getLinkItems = (items: iLinkItem[]) => {
    return (
      <>
        {items.map((item) => {
          return (
            <LinkItem
              key={item.name}
              href={item.url}
              testId={`${testId}-${item.name.toLowerCase()}`}
            >
              {item.name}
            </LinkItem>
          );
        })}
      </>
    );
  };

  const getContactsMenu = () => {
    return getMenuPopup(
      TopNavOpeningMenuKey.CONTACTS,
      TopNavOpeningMenuKey.CONTACTS,
      <Section>
        {getLinkItems(
          [
            {
              type: ContactCompanyTypes.CLIENT,
              name: 'Clients',
            },
            {
              type: ContactCompanyTypes.SUPPLIER,
              name: 'Suppliers',
            },
            {
              type: ContactCompanyTypes.GAS_AUTHORITY,
              name: 'Gas Authority',
            },
            {
              type: ContactCompanyTypes.WATER_AUTHORITY,
              name: 'Water Authority',
            },
          ].map((item) => ({
            ...item,
            url: URL_CONTACT_COMPANY_LIST.replace(':type', item.type),
          })),
        )}
      </Section>,
    );
  };

  const getInventoryMenu = () => {
    return getMenuPopup(
      TopNavOpeningMenuKey.STOCK,
      TopNavOpeningMenuKey.STOCK,
      <Section>
        {getLinkItems([
          {
            name: 'Products',
            url: URL_PRODUCTS,
          },
        ])}
      </Section>,
    );
  };

  const getMiscMenu = () => {
    return getMenuPopup(
      TopNavOpeningMenuKey.MISC,
      TopNavOpeningMenuKey.MISC,
      <Section>
        {getLinkItems([
          {
            name: 'City Councils / Shires',
            url: URL_CITY_COUNCILS,
          },
          {
            name: 'Suburbs',
            url: URL_SUBURBS,
          },
          {
            name: 'States',
            url: URL_STATE,
          },
          {
            name: 'Gas Types',
            url: URL_GAS_TYPES,
          },
          {
            name: 'Estate',
            url: URL_ESTATE,
          },
        ])}
      </Section>,
    );
  };
  const getBuildMenu = () => {
    return getMenuPopup(
      TopNavOpeningMenuKey.BUILD,
      TopNavOpeningMenuKey.BUILD,
      <Section>
        {getLinkItems([
          {
            name: 'Build Jobs',
            url: URL_BUILDS,
          },
          {
            name: 'Styles',
            url: URL_BUILD_STYLES,
          },
          {
            name: 'House Areas',
            url: URL_HOUSE_AREAS,
          },
          {
            name: 'House Storey',
            url: URL_HOUSE_STOREY,
          },
        ])}
      </Section>,
    );
  };

  return (
    <Navigation
      testId={testId}
      label={'TopNav'}
      renderAppSwitcher={() => (
        <LogoWrapper href={URL_BACKEND} data-testid={`${testId}-logoWrapper`}>
          <Logo width={85} height={60} />
        </LogoWrapper>
      )}
      primaryItems={[
        <PrimaryButton href={URL_BACKEND} key={'home'}>
          Home
        </PrimaryButton>,
        getBuildMenu(),
        getInventoryMenu(),
        getContactsMenu(),
        getMiscMenu(),
      ]}
      renderProductHome={() => null}
      renderHelp={() => null}
      renderSettings={() => (
        <LinkIconButton
          testId={`${testId}-settings`}
          icon={Icons.SettingsIcon}
          label={'settings'}
          href={URL_SETTINGS}
          shape={'circle'}
          appearance={'subtle'}
        />
      )}
      renderSignIn={() => null}
      renderSearch={() => null}
      renderNotifications={() => null}
      renderProfile={() => <UserAvatarPopup user={user} />}
      renderCreate={() => null}
    />
  );
};

export default TopNav;
