import Icons from '../../../frameWork/Icons';
import iBuild from '../../../../types/build/iBuild';
import BuildDetailsPlanOverview from './Components/BuildDetailsPlanOverview';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';
import BuildDetailsPlanChanges from './Components/BuildDetailsPlanChanges';
import BuildDetailsPlanSoilTest from './Components/BuildDetailsPlanSoilTest';

export enum BuildDetailsPlanKeys {
  OVERVIEW = 'Overview',
  PLAN_CHANGES = 'Plan Changes',
  SOIL_TEST = 'Soil Test',
}

type iGetBuildDetailsPlanMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const getMenuItemsMap = ({
  build,
  onBuildUpdated,
  isDisabled = false,
}: iGetBuildDetailsPlanMenuItemsMap): iMenuItemsMap => {
  return {
    [BuildDetailsPlanKeys.OVERVIEW]: {
      name: BuildDetailsPlanKeys.OVERVIEW,
      icon: <Icons.OverviewIcon label={BuildDetailsPlanKeys.OVERVIEW} />,
      component: (
        <BuildDetailsPlanOverview
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsPlanKeys.PLAN_CHANGES]: {
      name: BuildDetailsPlanKeys.PLAN_CHANGES,
      icon: (
        <Icons.EditorNumberListIcon label={BuildDetailsPlanKeys.PLAN_CHANGES} />
      ),
      component: (
        <BuildDetailsPlanChanges
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsPlanKeys.SOIL_TEST]: {
      name: BuildDetailsPlanKeys.SOIL_TEST,
      icon: <Icons.JiraLabsIcon label={BuildDetailsPlanKeys.SOIL_TEST} />,
      component: (
        <BuildDetailsPlanSoilTest
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
  };
};

const BuildDetailsPlanHelper = {
  getMenuItemsMap,
};
export default BuildDetailsPlanHelper;
