import {
  LinkItem as OriginLinkItem,
  ButtonItem as OriginButtonItem,
  HeadingItem as OriginHeadingItem,
  MenuGroup as OriginMenuGroup,
  Section as OriginSection,
} from '@atlaskit/menu';

export const LinkItem = OriginLinkItem;
export const ButtonItem = OriginButtonItem;
export const HeadingItem = OriginHeadingItem;
export const MenuGroup = OriginMenuGroup;
export const Section = OriginSection;

export default {};
