import styled from 'styled-components';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import ProductService from '../../services/product/ProductService';
import iProduct from '../../types/product/iProduct';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import DynamicTableHelper, {
  iCellParams,
} from '../../helpers/DynamicTableHelper';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { URL_PRODUCT_DETAILS } from '../../helpers/UrlMap';
import UtilsService from '../../services/UtilsService';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';
import SearchTextField from '../frameWork/SearchTextField';
import EntityNames from '../../helpers/EntityNames';

const Wrapper = styled.div``;

export type iProductListPanel = iComponentWithPageHeader & {
  className?: string;
  testId?: string;
  allowDelete?: boolean;
  allowCreate?: boolean;
};

const ProductList = ({
  className,
  testId,
  allowCreate,
  allowDelete,
  headerProps,
}: iProductListPanel) => {
  const testIdStr = `${testId || ''}-product-list`;
  const navigate = useNavigate();
  const {
    renderDataTable,
    renderDeleteBtn,
    onSetFilter,
    renderEntityEditPopBtn,
  } = useListCrudHook<iProduct>({
    sort: `productCode:ASC`,
    getFn: (params) =>
      ProductService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy,AttributeSet',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getEditBtn = (data?: iProduct) => {
    const id = `${data?.id || ''}`.trim();
    return renderEntityEditPopBtn<iProduct>({
      testId: `${testIdStr}-create-btn`,
      editingEntity: data,
      entityName: EntityNames.Product,
      createFn: (data) => ProductService.create(data),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      updateFn: async () => null,
      onSaved: (saved) => {
        navigate(URL_PRODUCT_DETAILS.replace(':id', saved.id));
      },
      renderEditBtn: () => (
        <Link to={URL_PRODUCT_DETAILS.replace(':id', id)}>
          {data?.productCode || 'EMPTY PC'}
        </Link>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'productCode',
          label: 'Product Code',
          isRequired: true,
          isDisabled,
          value: entity?.productCode || '',
          testId: 'productCode',
        },
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'name',
        },
      ],
    });
  };

  const getColumns = () => [
    {
      key: 'productCode',
      header: 'Product Code / SKU',
      isDefault: true,
      isSortable: true,
      cell: ({ data }: iCellParams<iProduct>) => (
        <div>
          <div>{getEditBtn(data)}</div>
          <small>{data.name || ''}</small>
        </div>
      ),
    },
    {
      key: 'attributeSetCode',
      header: 'Attribute Set',
      isDefault: true,
      isSelectable: true,
      isSortable: true,
      cell: ({ data }: iCellParams<iProduct>) =>
        `${data.AttributeSet?.name || data.attributeSetCode || ''}`,
    },
    {
      key: 'unitPrice',
      header: 'Unit Price',
      isSelectable: true,
      cell: ({ data }: iCellParams<iProduct>) =>
        UtilsService.formatIntoCurrency(data.unitPrice),
    },
    {
      key: 'isForSell',
      header: 'For Sell?',
      isSelectable: true,
      cell: ({ data }: iCellParams<iProduct>) =>
        DynamicTableHelper.getCheckedIcon(data.isForSell),
    },
    {
      key: 'isForPurchase',
      header: 'For Purchase?',
      isSelectable: true,
      cell: ({ data }: iCellParams<iProduct>) =>
        DynamicTableHelper.getCheckedIcon(data.isForPurchase),
    },
    {
      key: 'isForBuild',
      header: 'For Build?',
      isSelectable: true,
      cell: ({ data }: iCellParams<iProduct>) =>
        DynamicTableHelper.getCheckedIcon(data.isForBuild),
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iProduct>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iProduct>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => ProductService.deactivate(data.id),
                    getDisplayName: (data) => data.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        ...(allowCreate === true
          ? {
              children: (
                <PageTitleWithCreateBtn
                  createBtn={getEditBtn()}
                  title={headerProps?.children}
                />
              ),
            }
          : {}),
        actions: (
          <SearchTextField
            testId={`${testIdStr}-search`}
            placeholder={'Searching name, product code ...'}
            onSearch={(searchText: string) => {
              onSetFilter({
                [OP_OR]: [
                  { name: { [OP_LIKE]: `%${searchText}%` } },
                  { productCode: { [OP_LIKE]: `%${searchText}%` } },
                ],
              });
            }}
          />
        ),
      }}
    >
      <Wrapper
        data-testid={testIdStr}
        className={`product-list-panel-wrapper ${className || ''}`}
      >
        {renderDataTable({
          selectiveColumnKey: SelectiveColKeys.PRODUCT_LIST,
          columns: getColumns(),
          showPageSizer: true,
          tblProps: {
            testId: testIdStr,
          },
        })}
      </Wrapper>
    </ComponentWithPageHeader>
  );
};

export default ProductList;
