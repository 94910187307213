import styled from 'styled-components';
import TextField from '../frameWork/TextField';
import Tokens from '../frameWork/Tokens';
import Button from '../frameWork/Button';
import { useState } from 'react';
import PasswordRules, { validatePassword } from './PasswordRules';

type iPasswordReset = {
  className?: string;
  onSubmit?: (newPassword: string) => void;
  useAsForm?: {
    onNewPasswordChanged: (newPassword: string) => void;
  };
};

const Wrapper = styled.div`
  display: flex;
  gap: ${Tokens('space.100')};
  align-items: start;

  .btn-wrapper {
    padding-top: ${Tokens('space.300')};
  }
`;

export const testId = 'password-reset';

const PasswordReset = ({ className, onSubmit, useAsForm }: iPasswordReset) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const isPasswordValid = (newPassString: string) => {
    const newPasswordStr = `${newPassString || ''}`.trim();
    if (newPasswordStr === '') {
      return true;
    }

    return validatePassword(newPassString);
  };

  const isConfirmPasswordValid = (confirmPassStr: string) => {
    if (`${newPassword || ''}`.trim() !== `${confirmPassStr || ''}`.trim()) {
      return false;
    }
    return true;
  };

  const isFormButtonEnabled = (
    newPassString: string,
    confirmPassString: string,
  ) => {
    if (`${newPassString || ''}`.trim() === '') {
      return false;
    }

    if (
      !isPasswordValid(newPassString) ||
      !isConfirmPasswordValid(confirmPassString)
    ) {
      return false;
    }

    return true;
  };

  const onPasswordChanged = (passString: string, confirmPassString: string) => {
    if (
      !useAsForm ||
      !useAsForm.onNewPasswordChanged ||
      !isFormButtonEnabled(passString, confirmPassString) ||
      passString !== confirmPassString
    ) {
      return;
    }
    useAsForm.onNewPasswordChanged(passString);
  };

  const doSubmit = () => {
    if (useAsForm) {
      return;
    }

    const submittingPassword = `${newPassword || ''}`.trim();
    setNewPassword('');
    setConfirmPassword('');
    onSubmit && onSubmit(submittingPassword);
  };

  const getConfirmingPasswordInput = () => {
    if (`${newPassword || ''}`.trim() === '') {
      return null;
    }

    if (!isPasswordValid(newPassword)) {
      return null;
    }

    return (
      <>
        <TextField
          testId={`${testId}-confirm-password`}
          isInvalid={!isConfirmPasswordValid(confirmPassword)}
          type="password"
          label="Confirm new password"
          value={confirmPassword}
          className={'input-field'}
          isRequired
          appearance={'subtle'}
          placeholder={`Repeat new password`}
          onChange={(event) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            const passString = event.target.value || '';
            setConfirmPassword(passString);
            onPasswordChanged(newPassword, passString);
          }}
          errorMsg={
            isConfirmPasswordValid(confirmPassword)
              ? undefined
              : 'Password is not the same'
          }
        />
        {!useAsForm && (
          <div className={`${testId}-btn-wrapper`}>
            <Button
              testId={`${testId}-confirm-btn`}
              isDisabled={!isFormButtonEnabled(newPassword, confirmPassword)}
              onClick={doSubmit}
            >
              Reset
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <Wrapper className={className} data-testid={testId}>
      <TextField
        testId={`${testId}-new-password`}
        isInvalid={!isPasswordValid(newPassword)}
        type="password"
        label="New password"
        value={newPassword}
        className={'input-field'}
        appearance={'subtle'}
        placeholder={`Leave it blank if you don't want to change`}
        onChange={(event) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const passString = event.target.value || '';
          setNewPassword(passString);
          onPasswordChanged(passString, confirmPassword);
        }}
        errorMsg={isPasswordValid(newPassword) ? undefined : <PasswordRules />}
      />
      {getConfirmingPasswordInput()}
    </Wrapper>
  );
};

export default PasswordReset;
