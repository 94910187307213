import ServiceHelper from '../helper/ServiceHelper';
import iLogEntityChange from '../../types/system/iLogEntityChange';

const endPoint = '/logEntityChange';

const LogEntityChangeService = {
  getAll: ServiceHelper.getPaginatedAll<iLogEntityChange>(endPoint),
  get: ServiceHelper.getById<iLogEntityChange>(endPoint),
};

export default LogEntityChangeService;
