import ServiceHelper from '../helper/ServiceHelper';
import iAttributeValue from '../../types/attribute/iAttributeValue';
import AppService, { iConfigParams } from '../AppService';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import EntityNames from '../../helpers/EntityNames';
import iPaginatedResult from '../../types/iPaginatedResult';

const endPoint = '/attributeValue';
const AttributeValueService = {
  getAll: ServiceHelper.getPaginatedAll<iAttributeValue>(endPoint),
  get: ServiceHelper.getById<iAttributeValue>(endPoint),
  create: ServiceHelper.create<iAttributeValue>(endPoint),
  update: ServiceHelper.updateById<iAttributeValue>(endPoint),
  deactivate: ServiceHelper.deactivateById<iAttributeValue>(endPoint),
  getAllFromEntity: async (
    entityName: EntityNames,
    entityId: string,
    params?: iConfigParams,
    config: AxiosRequestConfig = {},
  ): Promise<iPaginatedResult<iAttributeValue>> => {
    return AppService.get(
      `${endPoint}/${entityName}/${entityId}`,
      params,
      config,
    ).then(({ data }: AxiosResponse) => data);
  },
};

export default AttributeValueService;
