import ProtectedPage from '../../layouts/ProtectedPage';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import BuildStyleService from '../../services/build/BuildStyleService';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import iBuildStyle from '../../types/build/iBuildStyle';
import BuildStyleDetailsPanel from '../../components/buildStyle/BuildStyleDetailsPanel';
import { URL_BUILD_STYLES } from '../../helpers/UrlMap';
import { InlineFlex } from '../../components/frameWork/Flex';
import Badge from '../../components/frameWork/Badge';
import MathHelper from '../../helpers/MathHelper';

const BuildStylesPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const [forceReload, setForceReload] = useState(0);
  return (
    <ProtectedPage>
      <ComponentWithPreloadData<iBuildStyle>
        forceReload={forceReload}
        emptyStateProps={{
          description: `Couldn't find requested Style with id: ${idStr}.`,
        }}
        getDataFn={() =>
          BuildStyleService.get(idStr, {
            include: 'CreatedBy,UpdatedBy,LatestPrice',
          })
        }
      >
        {({ data: buildStyle, stateBeforeReload }) => (
          <BuildStyleDetailsPanel
            onSaved={() => setForceReload(MathHelper.add(forceReload, 1))}
            stateBeforeReload={stateBeforeReload}
            headerProps={{
              children: (
                <InlineFlex className={'gap-1 align-items-base'}>
                  <div>{buildStyle.name}</div>
                  {buildStyle.isCustomised === true ? (
                    <Badge appearance={'added'}>Custom</Badge>
                  ) : null}
                </InlineFlex>
              ),
              breadcrumbItems: [
                {
                  text: `Styles`,
                  href: URL_BUILD_STYLES,
                },
                {
                  text: `${buildStyle.name}`,
                  component: () => (
                    <InlineFlex className={'align-items-center'}>
                      {buildStyle.name}
                    </InlineFlex>
                  ),
                },
              ],
            }}
            buildStyle={buildStyle}
          />
        )}
      </ComponentWithPreloadData>
    </ProtectedPage>
  );
};

export default BuildStylesPage;
