import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsPreConstructionSchedulePanel = {
  build: iBuild;
};
const BuildDetailsPreConstructionSchedulePanel = ({
  build,
}: iBuildDetailsPreConstructionSchedulePanel) => {
  return <Wrapper>Pre Construction Schedule: {build.id}</Wrapper>;
};

export default BuildDetailsPreConstructionSchedulePanel;
