import { default as Origin, DynamicTableProps } from '@atlaskit/dynamic-table';

import {
  RowCellType,
  RowType,
  HeadCellType,
  HeadType,
} from '@atlaskit/dynamic-table/types';
import styled from 'styled-components';
import Pagination from './Pagination';
import * as _ from 'lodash';
import MathHelper from '../../helpers/MathHelper';
import PageSizeSelector from '../pagination/PageSizeSelector';
import { iOption } from './Select';
import React from 'react';
import Tokens from './Tokens';

export type iRowCellType = RowCellType;
export type iRowType = RowType;
export type iHeadType = HeadType;
export type iHeadCellType = HeadCellType;

export type iDynamicTable = DynamicTableProps & {
  testId?: string;
  className?: string;
  totalPages?: number;
  onPageSizeChange?: (pageSize: number) => void;
  bottomLeft?: React.ReactNode;
  topRow?: React.ReactNode;
};
const Wrapper = styled.div`
  max-width: 100%;
  
  [data-testid^="dynamic-tbl-"][data-testid$="--loading--container--advanced"] {
    max-width: 100%;
    overflow-x: auto;
  }
    
  .bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page-sizer {
    > [class$='control'] {
      min-height: auto;
      //background-color: blue;
      [class$='indicatorContainer'] {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }

  .data-pagination {
    font-size: ${Tokens('font.size.100')};
    color: ${Tokens('color.background.accent.gray.bolder.pressed')};
  }
    
  table > caption,
  [class$='Caption'] {
    margin-top: 0px;
  }
}
`;

const DynamicTable = ({
  testId = 'DynamicTable',
  className,
  totalPages,
  page,
  onSetPage,
  onPageSizeChange,
  rowsPerPage,
  bottomLeft,
  topRow,
  ...props
}: iDynamicTable) => {
  const tPages = totalPages || 0;
  const currentPage = page || 1;

  const getPagination = () => {
    if (
      tPages <= 0 ||
      tPages < currentPage ||
      (tPages === currentPage && tPages === 1)
    ) {
      return null;
    }

    return (
      <Pagination
        pages={_.range(1, MathHelper.add(tPages, 1))}
        selectedIndex={MathHelper.sub(currentPage, 1)}
        nextLabel="Next"
        label="Page"
        pageLabel="Page"
        previousLabel="Previous"
        onChange={(event, page) => {
          onSetPage && onSetPage(page);
        }}
      />
    );
  };

  const getPageSizeSelector = () => {
    if (!onPageSizeChange) {
      return null;
    }

    return (
      <PageSizeSelector
        className={'page-sizer'}
        value={rowsPerPage}
        onChange={(newPageSize: iOption) => {
          onPageSizeChange && onPageSizeChange(Number(newPageSize.value));
        }}
      />
    );
  };

  const getBottomRow = () => {
    const pagination = getPagination();
    const pageSizeSelector = getPageSizeSelector();
    const bottomLeftDiv = bottomLeft || null;

    if (!pagination && !pageSizeSelector && !bottomLeftDiv) {
      return null;
    }

    return (
      <div className={'bottom-row'} data-testid={`${testId}-bottom-row`}>
        {bottomLeftDiv || <div />} {pagination || <div />}{' '}
        {pageSizeSelector || <div />}
      </div>
    );
  };

  return (
    <Wrapper
      className={`dynamic-tbl-wrapper ${className || ''}`}
      data-testid={testId}
    >
      {topRow && <div data-testid={`${testId}-top-row`}>{topRow}</div>}
      <Origin
        {...props}
        onSetPage={onSetPage}
        testId={`dynamic-tbl-${testId}`}
      />
      {getBottomRow()}
    </Wrapper>
  );
};
export default DynamicTable;
