import React from 'react';
import styled from 'styled-components';
import Tokens from '../frameWork/Tokens';
import { IconButton, IconButtonProps } from '../frameWork/Button';
import Icons from '../frameWork/Icons';

export type iGetCreateIconBtn = Omit<IconButtonProps, 'icon' | 'label'> &
  Partial<Pick<IconButtonProps, 'icon' | 'label'>> & {
    className?: string;
  };
export type iPageTitleWithCreateBtn = {
  testId?: string;
  className?: string;
  title: React.ReactNode;
  createBtn?: React.ReactNode;
  createBtnProps?: iGetCreateIconBtn;
};

const Wrapper = styled.div`
  display: flex;
  gap: ${Tokens('space.200')};
  align-items: center !important;
`;

const CreateBtnWrapper = styled.div`
  font-size: ${Tokens('font.size.200')};
  button {
    height: 1.7rem;
    width: 1.7rem;
  }
  &.size-sm {
    button {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
`;

export const getCreateIconBtn = (props?: iGetCreateIconBtn) => {
  return (
    <CreateBtnWrapper
      className={`create-btn-wrapper ${props?.className || ''}`}
    >
      <IconButton
        icon={(iconProps) => <Icons.AddIcon {...iconProps} size="small" />}
        label={'Create'}
        spacing={'compact'}
        isSelected
        {...props}
      />
    </CreateBtnWrapper>
  );
};

const PageTitleWithCreateBtn = ({
  className,
  testId,
  title,
  createBtn,
  createBtnProps,
}: iPageTitleWithCreateBtn) => {
  return (
    <Wrapper
      className={`page-title-with-create ${className || ''}`}
      data-testid={`PageTitleWithCreateBtn-${testId || ''}`}
    >
      <div data-testid={`PageTitleWithCreateBtn-${testId || ''}-title`}>
        {title}
      </div>
      <div
        className={'create-btn-wrapper'}
        data-testid={`PageTitleWithCreateBtn-${testId || ''}-btnWrapper`}
      >
        {createBtn || getCreateIconBtn(createBtnProps)}
      </div>
    </Wrapper>
  );
};

export default PageTitleWithCreateBtn;
