import iAddress from '../../types/system/iAddress';
import InlineEdit, { iInlineEdit } from '../frameWork/InlineEdit';
import AddressEditPanel from './AddressEditPanel';
import AddressHelper from '../../helpers/AddressHelper';
import { useState } from 'react';
import { iErrorMap } from '../form/FormError';
import { iConfigParams } from '../../services/AppService';
import StringHelper from '../../helpers/StringHelper';
import AddressService from '../../services/system/AddressService';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';

type iAddressInlineEdit = Omit<
  iInlineEdit,
  'editView' | 'readView' | 'value' | 'defaultValue' | 'isEditing' | 'onConfirm'
> & {
  address?: iAddress | null;
  onSaved: (saved: iAddress, isCreated: boolean) => void;
};

const AddressInlineEdit = ({
  address,
  onSaved,
  placeHolder,
  ...props
}: iAddressInlineEdit) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingAddress, setEditingAddress] = useState(
    address ? { ...address } : null,
  );
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const preSubmit = () => {
    const checkingAddr: iConfigParams = editingAddress || {};
    const errors: iErrorMap = ['street', 'suburb', 'state', 'postcode'].reduce(
      (map: iErrorMap, key: string) => {
        if (`${checkingAddr[key] || ''}`.trim() !== '') {
          return map;
        }

        const msg = `${key} is required.`;
        return {
          ...map,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          [key]: key in map ? [...map[key], msg] : [msg],
        };
      },
      {},
    );

    const postCode = `${checkingAddr.postcode || ''}`.trim();
    if (postCode !== '' && !StringHelper.isNumeric(postCode)) {
      const msg = `Postcode is needs to be a number.`;
      errors.postcode = [msg];
    }

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const handleConfirm = () => {
    if (!preSubmit() || !editingAddress) {
      return;
    }
    const addressId = `${address?.id || ''}`.trim();
    setIsSaving(true);
    const saveFn = () =>
      addressId === ''
        ? AddressService.create(editingAddress)
        : AddressService.update(addressId, editingAddress);

    saveFn()
      .then((resp) => {
        setIsSaving(false);
        Toaster.showToast(
          `Address ${addressId === '' ? 'Created' : 'Updated'}`,
          TOAST_TYPE_SUCCESS,
        );
        setIsEditing(false);
        setEditingAddress(resp);
        onSaved(resp, addressId === '');
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  return (
    <InlineEdit
      {...props}
      onEdit={() => setIsEditing(true)}
      isEditing={isEditing}
      onCancel={() => {
        setEditingAddress(address ? { ...address } : null);
        setErrorMap({});
        setIsEditing(false);
      }}
      readViewFitContainerWidth
      editView={(fieldProps) => (
        <AddressEditPanel
          {...fieldProps}
          testId={props.testId}
          address={editingAddress}
          isDisabled={props.isDisabled === true || isSaving === true}
          useAsForm={{
            errorMap: errorMap,
            onFieldChange: (fieldName, newValue) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              setEditingAddress({
                ...(editingAddress || {}),
                [fieldName]: newValue,
              });
            },
          }}
        />
      )}
      placeHolder={placeHolder || `Click to input an address ...`}
      value={AddressHelper.getFullAddress(editingAddress)}
      onConfirm={() => handleConfirm()}
    />
  );
};

export default AddressInlineEdit;
