import SettingsPage from '../../layouts/SettingsPage';
import BuildSuspensionTypeList from '../../components/buildSuspension/BuildSuspensionTypeList';

const BuildSuspensionTypesPage = () => {
  return (
    <SettingsPage title={'Build Suspension Types'}>
      {(headerProps) => (
        <BuildSuspensionTypeList allowDelete headerProps={headerProps} />
      )}
    </SettingsPage>
  );
};

export default BuildSuspensionTypesPage;
