import iUser from '../../types/system/iUser';
import Avatar, { iAvatar } from '../frameWork/Avatar';

type iUserAvatar = iAvatar & {
  user?: iUser | null;
};
const UserAvatar = ({ user, ...props }: iUserAvatar) => {
  if (`${user?.AvatarAsset?.url || ''}`.trim() === '') {
    return <Avatar testId={'user-avatar'} {...props} />;
  }
  return (
    <Avatar
      testId={'user-avatar-with-url'}
      {...props}
      src={user?.AvatarAsset?.url || ''}
    />
  );
};

export default UserAvatar;
