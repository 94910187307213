import {
  default as Origin,
  BreadcrumbsItem as OriginBreadcrumbsItem,
} from '@atlaskit/breadcrumbs';
import { default as OriginNoop } from '@atlaskit/ds-lib/noop';
import type React from 'react';

export type BreadcrumbsItemProps = {
  href?: string;
  /**
   * An icon to display before the breadcrumb.
   */
  iconBefore?: React.ReactChild;
  /**
   * An icon to display after the breadcrumb.
   */
  iconAfter?: React.ReactChild;
  /**
   * Handler to be called on click. *
   */
  onClick?: (event: React.MouseEvent) => void;
  /**
   * The text to appear within the breadcrumb as a link.
   */
  text: string;
  /**
   * The maximum width in pixels that an item can have before it is truncated.
   * If this is not set, truncation will only occur when it cannot fit alone on a
   * line. If there is no truncationWidth, tooltips are not provided on truncation.
   */
  truncationWidth?: number;
  target?: '_blank' | '_parent' | '_self' | '_top' | '';
  /**
   * Provide a custom component to use instead of the default button.
   *  The custom component should accept a className prop so it can be styled
   *  and possibly all action handlers.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: React.ClassType<any, any, any>;
  /**
   * Additional information to be included in the `context` of analytics events.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  analyticsContext?: Record<string, any>;
  /**
   * A `testId` prop is provided for specified elements, which is a unique string that appears as a data attribute `data-testid` in the rendered code, serving as a hook for automated tests
   * In case of `testId` passed through EllipsisItem, the element will be identified like this: 'testId && `${testId}--breadcrumb-ellipsis'.
   * This can be used to click the elements when they are collapsed.
   */
  testId?: string;
  /**
   * A function to be called when a truncated breadcrumb item's tooltip is shown.
   */
  onTooltipShown?: () => void;
};
const Breadcrumbs = Origin;
export const BreadcrumbsItem = OriginBreadcrumbsItem;
export const __noop = OriginNoop;

export default Breadcrumbs;
