import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';
import iUser from '../../types/system/iUser';
import UserService from '../../services/system/UserService';
import UserHelper from '../../helpers/UserHelper';
import { iOption } from '../frameWork/Select';

export type iUserSelector = iPreloadAsyncSelectProps;

const UserSelector = (props: iUserSelector) => {
  return (
    <PreloadedAsyncSelector<iUser>
      placeholder={'Select a user ...'}
      {...props}
      formatOptionLabel={(data: iOption) => {
        if (props.formatOptionLabel) {
          return props.formatOptionLabel(data);
        }
        return UserHelper.getFullName(data.data);
      }}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return UserService.getAll({
          ...params,
          sort: 'firstName:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(searchTxtStr === ''
              ? {}
              : {
                  [OP_OR]: [
                    { firstName: { [OP_LIKE]: `%${searchTxtStr}%` } },
                    { lastName: { [OP_LIKE]: `%${searchTxtStr}%` } },
                  ],
                }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        UserService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default UserSelector;
