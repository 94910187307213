import { ButtonProps } from '../frameWork/Button';
import React from 'react';
import { getFooterWithBtns } from '../common/PopupModal';
import AuthService from '../../services/auth/AuthService';
import Icons from '../frameWork/Icons';
import PopupBtn from '../common/PopupBtn';
import Window from '../frameWork/Window';

type iLogoutBtn = Omit<ButtonProps, 'children'> & {
  children?: React.ReactNode;
  onSuccess?: (res: { success: boolean }) => void;
  renderBtn?: (onClick: () => void) => React.ReactNode;
};
const LogoutBtn = ({ onSuccess, ...props }: iLogoutBtn) => {
  const handleSuccess = () => {
    if (!onSuccess) {
      Window.reload();
      return;
    }
    onSuccess({ success: true });
  };

  const doLogout = () => {
    AuthService.logout();
    handleSuccess();
  };

  return (
    <PopupBtn
      {...props}
      titleId={'logout'}
      modalProps={() => ({
        shouldScrollInViewport: true,
        title: 'Logging out',
        body: 'Are you sure you want to logout?',
        footer: getFooterWithBtns({
          actionBtnProps: {
            appearance: 'primary',
            onClick: doLogout,
            iconBefore: Icons.SignOutIcon,
            btnText: 'Logout',
          },
        }),
      })}
    />
  );
};

export default LogoutBtn;
