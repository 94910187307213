import iAttribute, {
  AttributeForSections,
} from '../../../types/attribute/iAttribute';
import Flex from '../../frameWork/Flex';
import Toggle from '../../frameWork/Toggle';
import { iConfigParams } from '../../../services/AppService';
import styled from 'styled-components';
import Tokens from '../../frameWork/Tokens';
import AttributeSettingsHelper, {
  AttrSettingsFieldNames,
} from './AttributeSettingsHelper';

const Wrapper = styled.div`
  .attributes-for {
    tbody tr:hover {
      background-color: ${Tokens('color.background.accent.gray.subtlest')};
    }
    td.section-col {
      width: 120px;
    }
    .toggle-wrapper {
      margin-block-start: 0px !important;
      .form-field-wrapper {
        margin-block-start: 0px !important;
        margin: 0px;
        min-height: auto;
      }
    }
  }
`;
type iAttributeForSectionEditPanel = {
  attribute?: iAttribute;
  isDisabled?: boolean;
  testId?: string;
  className?: string;
  onUpdate: (newSettings: iConfigParams) => void;
};

const AttributeForSectionEditPanel = ({
  attribute,
  testId,
  className,
  onUpdate,
}: iAttributeForSectionEditPanel) => {
  const componentName = 'attribute-for-section-edit';
  const testIdStr = `${testId || ''}-${componentName}`;
  const settings = attribute?.settings || {};
  const forSectionsObj =
    AttributeSettingsHelper.getForActionObj(attribute) || {};
  const sections = Object.values(AttributeForSections);

  const handleChanged = (newData: iConfigParams) => {
    onUpdate({
      ...settings,
      [AttrSettingsFieldNames.forSectionsMap]: newData,
    });
  };

  return (
    <Wrapper
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      <table className={'attributes-for'}>
        <thead>
          <tr>
            <th></th>
            <th>
              <Flex className={'gap-025 align-items-center'}>
                <span>Readable</span>
                <Toggle
                  isChecked={
                    Object.values(forSectionsObj).filter(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (item: any) => item.isReadable === true,
                    ).length === sections.length
                  }
                  onChange={(event) => {
                    if (event.target.checked === true) {
                      handleChanged(
                        sections.reduce((map, attributeForSection) => {
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          const sectionObj: any =
                            attributeForSection in forSectionsObj
                              ? forSectionsObj[attributeForSection]
                              : {};
                          return {
                            ...map,
                            [attributeForSection]: {
                              isReadable: true,
                              ...(sectionObj.isEditable === true
                                ? { isEditable: true }
                                : {}),
                              ...(sectionObj.isRequired === true
                                ? { isRequired: true }
                                : {}),
                            },
                          };
                        }, {}),
                      );
                      return;
                    }
                    handleChanged({});
                  }}
                />
              </Flex>
            </th>
            <th>
              <Flex className={'gap-025 align-items-center'}>
                <span>Editable</span>
                <Toggle
                  isChecked={
                    Object.values(forSectionsObj).filter(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (item: any) => item.isEditable === true,
                    ).length === sections.length
                  }
                  onChange={(event) => {
                    handleChanged(
                      sections.reduce((map, attributeForSection) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const sectionObj: any =
                          attributeForSection in forSectionsObj
                            ? forSectionsObj[attributeForSection]
                            : {};
                        return {
                          ...map,
                          [attributeForSection]: {
                            isReadable: true,
                            ...(event.target.checked === true
                              ? {
                                  isEditable: true,
                                  ...(sectionObj.isRequired === true
                                    ? { isRequired: true }
                                    : {}),
                                }
                              : {}),
                          },
                        };
                      }, {}),
                    );
                  }}
                />
              </Flex>
            </th>
            <th>
              <Flex className={'gap-025 align-items-center'}>
                <span>Is Required</span>
                <Toggle
                  isChecked={
                    Object.values(forSectionsObj).filter(
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (item: any) => item.isRequired === true,
                    ).length === sections.length
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      handleChanged(
                        sections.reduce((map, attributeForSection) => {
                          return {
                            ...map,
                            [attributeForSection]: {
                              isReadable: true,
                              isEditable: true,
                              ...(event.target.checked
                                ? { isRequired: true }
                                : {}),
                            },
                          };
                        }, {}),
                      );
                      return;
                    }
                    handleChanged({});
                  }}
                />
              </Flex>
            </th>
          </tr>
        </thead>
        <tbody>
          {sections.map((attributeForSection) => {
            const sectionObj =
              attributeForSection in forSectionsObj
                ? forSectionsObj[attributeForSection]
                : {};
            return (
              <tr key={attributeForSection}>
                <td className={'section-col'}>{attributeForSection}</td>
                <td>
                  <Toggle
                    isChecked={
                      attributeForSection in forSectionsObj
                        ? forSectionsObj[attributeForSection].isReadable ===
                          true
                        : false
                    }
                    onChange={(event) => {
                      if (event.target.checked) {
                        handleChanged({
                          ...forSectionsObj,
                          [attributeForSection]: {
                            ...sectionObj,
                            isReadable: true,
                          },
                        });
                        return;
                      }
                      delete forSectionsObj[attributeForSection];
                      handleChanged(forSectionsObj);
                    }}
                  />
                </td>
                <td>
                  <Toggle
                    isChecked={
                      attributeForSection in forSectionsObj
                        ? forSectionsObj[attributeForSection].isEditable ===
                          true
                        : false
                    }
                    onChange={(event) => {
                      handleChanged({
                        ...forSectionsObj,
                        [attributeForSection]: {
                          ...sectionObj,
                          isEditable: event.target.checked,
                          ...(event.target.checked ? { isReadable: true } : {}),
                        },
                      });
                    }}
                  />
                </td>
                <td>
                  <Toggle
                    isChecked={
                      attributeForSection in forSectionsObj
                        ? forSectionsObj[attributeForSection].isRequired ===
                          true
                        : false
                    }
                    onChange={(event) => {
                      handleChanged({
                        ...forSectionsObj,
                        [attributeForSection]: {
                          ...sectionObj,
                          isRequired: event.target.checked,
                          ...(event.target.checked
                            ? { isReadable: true, isEditable: true }
                            : {}),
                        },
                      });
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default AttributeForSectionEditPanel;
