import ProtectedPage from '../../layouts/ProtectedPage';
import ContactCompanyList from '../../components/contact/ContactCompanyList';
import { useParams } from 'react-router-dom';
import ContactCompanyService from '../../services/contact/ContactCompanyService';

const ContactCompaniesPage = () => {
  const { type } = useParams();

  const contactCompanyType = `${type || ''}`.trim();
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `${ContactCompanyService.getHumanReadableType(contactCompanyType)} List`,
      }}
    >
      {(headerProps) => (
        <ContactCompanyList
          allowDelete
          headerProps={headerProps}
          contactCompanyType={contactCompanyType}
        />
      )}
    </ProtectedPage>
  );
};

export default ContactCompaniesPage;
