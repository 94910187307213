import styled from 'styled-components';
import React, { useCallback, useState } from 'react';
import Tokens from '../frameWork/Tokens';
import MathHelper from '../../helpers/MathHelper';
import Spinner from '../frameWork/Spinner';
import Toaster, { TOAST_TYPE_ERROR } from '../common/Toaster';
import UtilsService from '../../services/UtilsService';

const Wrapper = styled.div`
  position: relative;
  .file-uploader {
    border: 0.15rem dashed ${Tokens('color.border.accent.gray')};
    border-radius: ${Tokens('border.radius.100')};
    padding: ${Tokens('space.300')};
    height: 100%;

    //transition: border 0.2s ease-in-out;
    &.is-dragging {
      border: 0.18rem dashed ${Tokens('color.border.bold')};
      background-color: ${Tokens('color.background.accent.gray.subtlest')};
    }
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export type iFileUploader = {
  onFilesSelected: (files: File[]) => void;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  testId?: string;
};
const FileUploader = ({
  onFilesSelected,
  className,
  isDisabled = false,
  isLoading = false,
  testId,
}: iFileUploader) => {
  const testIdStr = `file-uploader-${testId || ''}`;
  const [htmlId] = useState(
    `fileUploader-${Math.ceil(MathHelper.mul(Math.random(), 10000000))}`,
  );
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setIsDragging(true);
    },
    [],
  );

  const handleDragLeave = useCallback(() => {
    setIsDragging(false);
  }, []);

  const getMaxFileSize = () => {
    return Number(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE || 0);
  };

  const handleFilesSelected = (files: File[]) => {
    const filteredFiles = files.filter((file) => {
      const maxFileSie = getMaxFileSize();
      if (maxFileSie <= 0) {
        return true;
      }
      if (file.size <= maxFileSie) {
        return true;
      }
      Toaster.showToast(
        <>
          <b>{file.name}</b> is greater than the max size:{' '}
          <b>{UtilsService.formatBytesToHuman(maxFileSie)}</b>
        </>,
        TOAST_TYPE_ERROR,
      );
      return false;
    });
    if (filteredFiles.length > 0) {
      onFilesSelected(filteredFiles);
    }
  };

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    handleFilesSelected(Array.from(event.dataTransfer.files));
  }, []);

  const handleFileSelect = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleFilesSelected(Array.from(event.target.files || []));
    },
    [],
  );

  const getMask = () => {
    if (isDisabled !== true && isLoading !== true) {
      return null;
    }
    return (
      <div className={`mask ${isDisabled === true ? 'is-disabled' : ''}`}>
        {isLoading === true ? (
          <Spinner testId={`${testIdStr}-spinner`} />
        ) : null}
      </div>
    );
  };

  return (
    <Wrapper
      data-testid={testIdStr}
      className={`file-uploader-wrapper ${className || ''} ${isDisabled ? 'is-disabled' : ''}`}
    >
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`file-uploader ${isDragging ? ' is-dragging' : ''}`}
      >
        <input
          type="file"
          multiple
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          id={htmlId}
        />
        <label htmlFor={htmlId} className={'cursor-pointer'}>
          <div className={'text-center'}>
            Drag & drop files here, or click to select files
            {getMaxFileSize() <= 0 ? null : (
              <div>
                <small>
                  Max file size:{' '}
                  {UtilsService.formatBytesToHuman(getMaxFileSize())}
                </small>
              </div>
            )}
          </div>
        </label>
      </div>
      {getMask()}
    </Wrapper>
  );
};

export default FileUploader;
