import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import iAttributeSet from '../../types/attribute/iAttributeSet';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import { getErrorProps, iErrorMap } from '../form/FormError';
import TextField from '../frameWork/TextField';
import AttributeSetService from '../../services/attribute/AttributeSetService';
import Toaster from '../common/Toaster';

export type iAttributeSetEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  attributeSet: iAttributeSet;
  isDisabled?: boolean;
  onSaved?: (saved: iAttributeSet) => void;
};
const AttributeSetEditPopupBtn = ({
  attributeSet,
  isDisabled = false,
  onSaved,
  ...props
}: iAttributeSetEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isSaving) {
      return;
    }
    setErrorMap({});
    setEditingData({});
    setModelShowing(false);
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    const checkingData = {
      ...attributeSet,
      ...editingData,
    };
    if (`${checkingData.name || ''}`.trim() === '') {
      errors.name = ['Name is required'];
    }
    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (!preSave()) {
      return;
    }
    setIsSaving(true);
    AttributeSetService.update(attributeSet.id, editingData)
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  const handleChanged = (fieldName: string, newValue: string | null) => {
    setEditingData({
      ...editingData,
      [fieldName]: newValue,
    });
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AttributeSetEditPopupBtn'}
      modalProps={(setModelShowing) => ({
        shouldScrollInViewport: true,
        title: (
          <>
            Editing Attribute Set <small>{attributeSet.name}</small>
          </>
        ),
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled:
              isDisabled || Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Save',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: (
          <>
            <TextField
              label={'Name'}
              placeholder={'Name'}
              isDisabled={isDisabled}
              isRequired
              value={editingData.name || attributeSet.name || ''}
              {...getErrorProps({
                error: errorMap,
                fieldName: 'name',
              })}
              onChange={(event) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                handleChanged('name', event.target.value || '');
              }}
            />

            <TextField
              label={'Description'}
              placeholder={'Description'}
              isDisabled={isDisabled}
              value={editingData.description || attributeSet.description || ''}
              {...getErrorProps({
                error: errorMap,
                fieldName: 'description',
              })}
              onChange={(event) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                handleChanged('description', event.target.value || '');
              }}
            />
          </>
        ),
      })}
    />
  );
};
export default AttributeSetEditPopupBtn;
