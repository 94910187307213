import SettingsPage from '../../layouts/SettingsPage';
import BuildSuspensionReasonList from '../../components/buildSuspension/BuildSuspensionReasonList';

const BuildSuspensionReasonsPage = () => {
  return (
    <SettingsPage title={'Build Suspension Reasons'}>
      {(headerProps) => (
        <BuildSuspensionReasonList allowDelete headerProps={headerProps} />
      )}
    </SettingsPage>
  );
};

export default BuildSuspensionReasonsPage;
