import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import AttributeService from '../../services/attribute/AttributeService';
import iAttributeSet from '../../types/attribute/iAttributeSet';
import iAttribute, { AttributeTypes } from '../../types/attribute/iAttribute';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import SectionMessage from '../frameWork/SectionMessage';
import Toaster from '../common/Toaster';
import { SimpleTag } from '../frameWork/Tag';
import Flex from '../frameWork/Flex';
import AttributeEditPopupBtn from './AttributeEditPopupBtn';
import MathHelper from '../../helpers/MathHelper';
import AttributeSettingsHelper from './components/AttributeSettingsHelper';
import AttributeSetEditPopupBtn from './AttributeSetEditPopupBtn';

export type iAttributeSetDetailsPanel = iComponentWithPageHeader & {
  attributeSet: iAttributeSet;
  allowCreate?: boolean;
  allowDelete?: boolean;
  allowEdit?: boolean;
  onAttributeSetUpdated?: (attributeSet: iAttributeSet) => void;
};
const AttributeSetDetailsPanel = ({
  attributeSet,
  headerProps,
  allowCreate,
  allowDelete,
  allowEdit,
  onAttributeSetUpdated,
}: iAttributeSetDetailsPanel) => {
  const testIdStr = 'AttributeSet-attribute-list';
  const { state, renderDataTable, renderDeleteBtn, onRefresh, onSetIsLoading } =
    useListCrudHook<iAttribute>({
      sort: `sort:ASC`,
      perPage: 999999,
      getFn: (params) =>
        AttributeService.getAll({
          where: JSON.stringify({
            isActive: true,
            attributeSetCode: attributeSet.code,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = (attribute?: iAttribute) => {
    if (!allowCreate && !allowEdit) {
      return null;
    }
    const attributes = state.data.data || [];
    const lastAttributeSort =
      attributes.length > 0 ? attributes[attributes.length - 1].sort : 0;
    return (
      <AttributeEditPopupBtn
        attributeSet={attributeSet}
        attribute={attribute}
        onSaved={() => onRefresh()}
        newSort={MathHelper.add(Number(lastAttributeSort), 1)}
        renderBtn={(onClick) => {
          if (`${attribute?.id || ''}`.trim() === '') {
            return getCreateIconBtn({ onClick });
          }
          return (
            <a onClick={onClick} className={'cursor-pointer'}>
              {attribute?.name}
            </a>
          );
        }}
      />
    );
  };

  const getColumns = (): iTableColumn<iAttribute>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      cell: ({ data }: iCellParams<iAttribute>) => {
        return allowEdit !== true ? data.name : getEditBtn(data);
      },
    },
    {
      key: 'type',
      header: 'Type',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAttribute>) => {
        return data.type;
      },
    },
    {
      key: 'options',
      header: 'Options',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAttribute>) => {
        if (data.type !== AttributeTypes.DROPDOWN) {
          return null;
        }
        const options = data.settings?.options || [];
        return (
          <Flex className={'gap-050 align-items-start flex-wrap'}>
            {options.map((option: string) => (
              <SimpleTag text={option} key={option} />
            ))}
          </Flex>
        );
      },
    },
    {
      key: 'hasEditLogic',
      header: 'Has Edit Logic',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAttribute>) => {
        const logics = AttributeSettingsHelper.getEditLogic(data);
        return DynamicTableHelper.getCheckedIcon(logics.length > 0);
      },
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iAttribute>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iAttribute>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => AttributeService.deactivate(data.id),
                    getDisplayName: (attribute) => attribute.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  const getRankingMessage = () => {
    if (allowEdit !== true) {
      return null;
    }
    return (
      <SectionMessage title={'You can reorder the attributes below'}>
        You can reorder the attributes below by drag and drop each row.
      </SectionMessage>
    );
  };

  const submitReorder = (sourceIndex: number, targetIndex: number) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    onSetIsLoading(true);
    const attributes = state.data.data || [];
    // Remove the item from the source position
    const [movedItem] = attributes.splice(sourceIndex, 1);
    // Insert it at the destination position
    attributes.splice(targetIndex, 0, movedItem);
    Promise.all(
      attributes.map((attribute, index) => {
        return AttributeService.update(attribute.id, { sort: index });
      }),
    )
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        onRefresh();
      });
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children:
          allowCreate === true ? (
            <PageTitleWithCreateBtn
              createBtn={getEditBtn()}
              title={
                <AttributeSetEditPopupBtn
                  isDisabled={allowEdit !== true}
                  attributeSet={attributeSet}
                  onSaved={onAttributeSetUpdated}
                  renderBtn={(onClick) => {
                    return (
                      <div onClick={onClick} className={'cursor-pointer'}>
                        {headerProps?.children}
                      </div>
                    );
                  }}
                />
              }
            />
          ) : (
            headerProps?.children
          ),
      }}
    >
      {getRankingMessage()}
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.ATTRIBUTE_LIST,
        tblProps: {
          testId: testIdStr,
          isRankable: allowEdit === true,
          onRankEnd: (params) =>
            submitReorder(params.sourceIndex, params.destination?.index || 0),
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default AttributeSetDetailsPanel;
