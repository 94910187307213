import TextField from '../frameWork/TextField';
import Logo from '../common/Logo';
import Icons from '../frameWork/Icons';
import Button from '../frameWork/Button';
import React from 'react';
import AuthService from '../../services/auth/AuthService';
import FormError, { iErrorMap } from '../form/FormError';
import Toaster from '../common/Toaster';
import iUser from '../../types/system/iUser';
import UtilsService from '../../services/UtilsService';

type iLoginPanel = {
  className?: string;
  onSuccess?: (res: iUser) => void;
};

const LoginPanel = ({ onSuccess, className }: iLoginPanel) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState({
    username: '',
    password: '',
  });
  const [error, setError] = React.useState<iErrorMap>({});

  const preCheck = () => {
    const errors = {};

    if (`${data.username || ''}`.trim() === '') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      errors.username = 'Username is required';
    }
    if (`${data.password || ''}`.trim() === '') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      errors.password = 'Password is required';
    }
    setError(errors);
    return Object.keys(errors).length <= 0;
  };

  const doLogin = () => {
    if (!preCheck()) {
      return;
    }

    setIsLoading(true);
    AuthService.login(data.username, data.password)
      .then((res) => {
        onSuccess && onSuccess(res.user);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChanged = (
    fieldName: string,
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    setData({
      ...data,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      [fieldName]: event.target.value || '',
    });
  };

  const onKeyPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
    UtilsService.handleEnterKeyPressed(event, () => {
      doLogin();
    });
  };

  return (
    <div
      data-testid={'login-panel'}
      className={`login-panel ${className || ''}`}
    >
      <div>
        <Logo />
      </div>
      <div>
        <div>Username</div>
        <TextField
          elemBeforeInput={<Icons.PersonIcon label={'username'} />}
          testId={'username'}
          value={data.username}
          placeholder={'Username'}
          onChange={(event) => handleChanged('username', event)}
          onKeyDown={(event) => onKeyPressed(event)}
          required
        />
        <FormError error={error} fieldName={'username'} />
      </div>
      <div>
        <div>Password</div>
        <TextField
          elemBeforeInput={<Icons.LockFilledIcon label={'Password'} />}
          value={data.password}
          testId={'password'}
          placeholder={'password'}
          onChange={(event) => handleChanged('password', event)}
          onKeyDown={(event) => onKeyPressed(event)}
          type={'password'}
          required
        />
        <FormError error={error} fieldName={'password'} />
      </div>
      <div>
        <Button
          testId={'login-button'}
          appearance={'primary'}
          isLoading={isLoading}
          shouldFitContainer
          iconBefore={Icons.SendIcon}
          onClick={() => doLogin()}
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default LoginPanel;
