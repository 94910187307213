import ProtectedPage from '../../layouts/ProtectedPage';
import ProductList from '../../components/product/ProductList';

const ProductsPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `Products`,
      }}
    >
      {(headerProps) => (
        <ProductList headerProps={headerProps} allowDelete allowCreate />
      )}
    </ProtectedPage>
  );
};

export default ProductsPage;
