export const validatePassword = (password: string | null | undefined) => {
  if (`${password || ''}`.trim() === '') {
    return false;
  }
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{8,}$/;
  return regex.test(`${password || ''}`.trim());
};

const PasswordRules = () => {
  return (
    <div>
      Password needs 8 chars of at least:
      <ul>
        <li>one lowercase</li>
        <li>one uppercase</li>
        <li>one number</li>
        <li>one special character</li>
      </ul>
    </div>
  );
};

export default PasswordRules;
