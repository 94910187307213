import React, { useEffect } from 'react';
import Icons from '../frameWork/Icons';
import PopupBtn, { iPopupBtn } from './PopupBtn';
import TextField from '../frameWork/TextField';
import Lozenge from '../frameWork/Lozenge';
import Toaster from './Toaster';
import { getFooterWithBtns, iGetDefaultBtn } from './PopupModal';

export type iDeleteConfirmPopup = iPopupBtn & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteFnc: () => Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeleted?: (resp: any) => void;
  confirmStr?: string;
  message?: React.ReactNode;
  title?: string;
  actionBtnProps?: Omit<iGetDefaultBtn, 'onClick'>;
  cancelBtnProps?: Omit<iGetDefaultBtn, 'onClick'>;
};

const DeleteConfirmPopupBtn = ({
  confirmStr,
  message,
  title,
  deleteFnc,
  onDeleted,
  testId,
  actionBtnProps,
  cancelBtnProps,
  ...props
}: iDeleteConfirmPopup) => {
  const [confirmString, setConfirmString] = React.useState('');
  const [inputString, setInputString] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);

  useEffect(() => {
    const confirmStrTrimmed = `${confirmStr || ''}`.trim();
    setConfirmString(
      confirmStrTrimmed === ''
        ? `${Math.ceil(Math.random() * 100000)}`
        : confirmStrTrimmed,
    );
  }, [confirmStr]);

  const getMessage = () => {
    if (message) {
      return message;
    }

    return <div>You are about to delete a provided item.</div>;
  };

  const deleteBtnEnabled = () => {
    const inputStr = `${inputString || ''}`.trim();
    if (inputStr === '') {
      return false;
    }

    return (
      inputStr.toLowerCase() === `${confirmString || ''}`.trim().toLowerCase()
    );
  };

  const doDelete = () => {
    setIsDeleting(true);
    deleteFnc()
      .then((res) => {
        setIsDeleting(false);
        onDeleted && onDeleted(res);
      })
      .catch((err) => {
        setIsDeleting(false);
        Toaster.showApiError(err);
      });
  };

  return (
    <PopupBtn
      {...props}
      titleId={'DeleteConfirmPopupBtn'}
      modalProps={(setModelShowing) => ({
        title: title || 'Are you sure?',
        shouldScrollInViewport: true,
        body: (
          <>
            <div>
              {getMessage()}
              <p>Are you sure to continue?</p>
            </div>

            <TextField
              value={inputString}
              placeholder={confirmString}
              testId={`delete-confirm-str-input-${testId || ''}`}
              helperMsg={
                <>
                  Please type in{' '}
                  <Lozenge isBold testId={`delete-confirm-str-${testId || ''}`}>
                    {confirmString}
                  </Lozenge>{' '}
                  the above textbox to confirm.
                </>
              }
              onChange={(event) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                setInputString(event.target.value);
              }}
            />
          </>
        ),
        footer: getFooterWithBtns({
          actionBtnProps: {
            testId: `delete-confirm-popup-deleteBtn-${testId || ''}`,
            isDisabled: !deleteBtnEnabled(),
            appearance: 'danger',
            isLoading: isDeleting,
            iconBefore: Icons.TrashIcon,
            btnText: 'Delete',
            ...actionBtnProps,
            onClick: doDelete,
          },
          cancelBtnProps: {
            testId: `delete-confirm-popup-cancelBtn-${testId || ''}`,
            isLoading: isDeleting,
            ...cancelBtnProps,
            onClick: () => {
              setInputString('');
              setModelShowing(false);
            },
          },
        }),
      })}
    />
  );
};

export default DeleteConfirmPopupBtn;
