import ProtectedPage from '../../layouts/ProtectedPage';
import BuildStyleList from '../../components/buildStyle/BuildStyleList';

const BuildStylesPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `Styles`,
      }}
    >
      {(headerProps) => (
        <BuildStyleList allowDelete headerProps={headerProps} />
      )}
    </ProtectedPage>
  );
};

export default BuildStylesPage;
