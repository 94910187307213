import ServiceHelper from '../helper/ServiceHelper';
import iBuild from '../../types/build/iBuild';

const endPoint = '/build';
const BuildService = {
  getAll: ServiceHelper.getPaginatedAll<iBuild>(endPoint),
  get: ServiceHelper.getById<iBuild>(endPoint),
  create: ServiceHelper.create<iBuild>(endPoint),
  update: ServiceHelper.updateById<iBuild>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuild>(endPoint),
};

export default BuildService;
