import HouseAreaList from '../../components/houseArea/HouseAreaList';
import ProtectedPage from '../../layouts/ProtectedPage';

const HouseAreasPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `House Areas`,
      }}
    >
      {(headerProps) => <HouseAreaList allowDelete headerProps={headerProps} />}
    </ProtectedPage>
  );
};

export default HouseAreasPage;
