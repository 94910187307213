import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import BuildStylePriceService from '../../services/build/BuildStylePriceService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import React from 'react';
import iBuildStylePrice from '../../types/build/iBuildStylePrice';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import EntityNames from '../../helpers/EntityNames';
import BuildStylePriceEditPopupBtn from './BuildStylePriceEditPopupBtn';
import UtilsService from '../../services/UtilsService';
import Lozenge from '../frameWork/Lozenge';
import moment from 'moment';

type iBuildStyleList = iComponentWithPageHeader & {
  allowDelete?: boolean;
  allowCreate?: boolean;
  styleId?: string;
  onSaved?: (saved: iBuildStylePrice, isCreated: boolean) => void;
};
const BuildStylePriceList = ({
  allowDelete,
  allowCreate,
  styleId,
  onSaved,
  headerProps,
}: iBuildStyleList) => {
  const testIdStr = 'BuildStylePrice-list';
  const { renderDataTable, renderDeleteBtn, onRefresh, onRefreshWhenCreated } =
    useListCrudHook<iBuildStylePrice>({
      sort: `startDate:DESC`,
      getFn: (params) =>
        BuildStylePriceService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(`${styleId || ''}`.trim() === ''
              ? {}
              : { styleId: `${styleId || ''}`.trim() }),
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy,Style',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = (price?: iBuildStylePrice) => {
    return (
      <BuildStylePriceEditPopupBtn
        styleId={styleId}
        stylePrice={price}
        onSaved={(saved, isCreated) => {
          if (isCreated) {
            onRefreshWhenCreated();
          } else {
            onRefresh();
          }
          onSaved && onSaved(saved, isCreated);
        }}
        renderBtn={(onClick) => {
          if (`${price?.id || ''}`.trim() === '') {
            return getCreateIconBtn({
              onClick,
              className: 'size-sm',
              label: `Create a new price`,
              isTooltipDisabled: false,
            });
          }
          return (
            <a onClick={onClick} className={'cursor-pointer'}>
              {DynamicTableHelper.displayDateCell(price?.startDate || null)}
            </a>
          );
        }}
      />
    );
  };

  const getColumns = (): iTableColumn<iBuildStylePrice>[] => [
    ...(`${styleId || ''}`.trim() === ''
      ? [
          {
            key: 'style',
            header: 'Style',
            isDefault: `${styleId || ''}`.trim() === '',
            cell: ({ data }: iCellParams<iBuildStylePrice>) => {
              return data.Style?.name;
            },
          },
        ]
      : []),
    {
      key: 'startDate',
      header: 'Start Date',
      isDefault: true,
      cell: ({ data }: iCellParams<iBuildStylePrice>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'endDate',
      header: 'End Date',
      isDefault: true,
      cell: ({ data }: iCellParams<iBuildStylePrice>) => {
        return DynamicTableHelper.displayDateCell(data.endDate);
      },
    },
    {
      key: 'price',
      header: 'Price',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStylePrice>) => {
        return UtilsService.formatIntoCurrency(data.price);
      },
    },
    {
      key: 'status',
      header: 'Status',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStylePrice>) => {
        const startDateStr = `${data.startDate || ''}`.trim();
        const endDateStr = `${data.endDate || ''}`.trim();
        let isCurrent = false;
        let isPast = false;
        let isFuture = false;
        if (startDateStr === '' && endDateStr === '') {
          isCurrent = true;
        } else if (startDateStr !== '' && endDateStr === '') {
          isFuture = moment(startDateStr).isAfter(moment());
          isCurrent = moment(startDateStr).isSameOrBefore(moment());
        } else if (startDateStr === '' && endDateStr !== '') {
          isPast = moment(endDateStr).isBefore(moment());
          isCurrent = moment(endDateStr).isSameOrAfter(moment());
        } else {
          isFuture = moment(startDateStr).isAfter(moment());
          isPast = moment(endDateStr).isBefore(moment());
          isCurrent =
            moment(startDateStr).isSameOrBefore(moment()) &&
            moment(endDateStr).isSameOrAfter(moment());
        }
        return (
          <Lozenge
            appearance={isCurrent ? 'success' : isFuture ? 'default' : 'moved'}
          >
            {isCurrent ? 'Current' : isFuture ? 'Future' : isPast ? 'Past' : ''}
          </Lozenge>
        );
      },
    },
    {
      key: 'comments',
      header: 'Comments',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildStylePrice>) => {
        return `${data.comments || ''}`;
      },
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iBuildStylePrice>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iBuildStylePrice>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      BuildStylePriceService.deactivate(data.id),
                    getDisplayName: (style) =>
                      `${EntityNames.BuildStylePrice} (Price=${style.price || ''})`,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children:
          allowCreate === true ? (
            <PageTitleWithCreateBtn
              createBtn={getEditBtn()}
              title={headerProps?.children}
            />
          ) : (
            headerProps?.children
          ),
      }}
    >
      {renderDataTable({
        selectiveColumnKey: SelectiveColKeys.BUILD_STYLE_PRICE_LIST,
        columns: getColumns(),
        showPageSizer: true,
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default BuildStylePriceList;
