import ServiceHelper from '../helper/ServiceHelper';
import iHouseArea from '../../types/build/iHouseArea';

const endPoint = '/HouseArea';

const HouseAreaService = {
  getAll: ServiceHelper.getPaginatedAll<iHouseArea>(endPoint),
  get: ServiceHelper.getById<iHouseArea>(endPoint),
  create: ServiceHelper.create<iHouseArea>(endPoint),
  update: ServiceHelper.updateById<iHouseArea>(endPoint),
  deactivate: ServiceHelper.deactivateById<iHouseArea>(endPoint),
};

export default HouseAreaService;
