import GasTypeList from '../../components/gasType/GasTypeList';
import ProtectedPage from '../../layouts/ProtectedPage';

const GasTypesPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `Gas Types`,
      }}
    >
      {(headerProps) => <GasTypeList allowDelete headerProps={headerProps} />}
    </ProtectedPage>
  );
};

export default GasTypesPage;
