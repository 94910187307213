import { default as Origin, TextAreaProps } from '@atlaskit/textarea';
import FormField, { iFormField } from './FormField';
import React from 'react';

export type iTextArea = Omit<
  iFormField<
    Omit<TextAreaProps, 'onPointerEnterCapture' | 'onPointerLeaveCapture'> &
      Partial<
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        Pick<TextAreaProps, 'onPointerEnterCapture' | 'onPointerLeaveCapture'>
      >
  >,
  'render'
>;
const TextArea = (props: iTextArea) => {
  return (
    <FormField<TextAreaProps>
      {...props}
      render={({ testId, ...fProps }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return <Origin {...fProps} testId={`${testId || ''}-textArea`} />;
      }}
    />
  );
};

export default TextArea;
