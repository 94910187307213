import ProtectedPage from '../../layouts/ProtectedPage';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import iBuild from '../../types/build/iBuild';
import BuildService from '../../services/build/BuildService';
import BuildDetailsPanel from '../../components/build/BuildDetailsPanel';
import { URL_BUILDS } from '../../helpers/UrlMap';
import { InlineFlex } from '../../components/frameWork/Flex';
import AddressHelper from '../../helpers/AddressHelper';

const BuildDetailsPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const [updatingBuild, setUpdatingBuild] = useState<iBuild | null>(null);
  return (
    <ProtectedPage>
      <ComponentWithPreloadData<iBuild>
        emptyStateProps={{
          description: `Couldn't find requested Build Job with id: ${idStr}.`,
        }}
        updatedModel={updatingBuild}
        getDataFn={() =>
          BuildService.get(idStr, {
            include:
              'CreatedBy,UpdatedBy,Style,Address,Client,Supervisor1,Supervisor2,BaseOffStyle,PlanRevision,SalesPerson,SalesManager',
          })
        }
      >
        {({ data: build, getDataFn }) => (
          <BuildDetailsPanel
            build={build}
            onSaved={() => getDataFn().then((resp) => setUpdatingBuild(resp))}
            headerProps={{
              children: AddressHelper.getFullAddress(build.Address),
              breadcrumbItems: [
                {
                  text: `Build Jobs`,
                  href: URL_BUILDS,
                },
                {
                  text: AddressHelper.getFullAddress(build.Address),
                  component: () => (
                    <InlineFlex className={'align-items-center'}>
                      {AddressHelper.getFullAddress(build.Address)}
                    </InlineFlex>
                  ),
                },
              ],
            }}
          />
        )}
      </ComponentWithPreloadData>
    </ProtectedPage>
  );
};

export default BuildDetailsPage;
