import ServiceHelper from '../helper/ServiceHelper';
import iBuildNote from '../../types/buildNote/iBuildNote';

const endPoint = '/buildNote';
const BuildNoteService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildNote>(endPoint),
  get: ServiceHelper.getById<iBuildNote>(endPoint),
  create: ServiceHelper.create<iBuildNote>(endPoint),
  update: ServiceHelper.updateById<iBuildNote>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildNote>(endPoint),
};

export default BuildNoteService;
