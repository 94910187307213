import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import TextField from '../frameWork/TextField';
import Toaster from '../common/Toaster';
import iBuildStyle from '../../types/build/iBuildStyle';
import BuildStyleService from '../../services/build/BuildStyleService';

export type iBuildStyleCreatePopupBtn = Omit<
  iPopupBtn,
  'titleId' | 'modalProps'
> & {
  onCreated: (created: iBuildStyle) => void;
};
const BuildStyleCreatePopupBtn = ({
  onCreated,
  ...props
}: iBuildStyleCreatePopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});

  const handleClose = (setShowingModal: iSetShowingModalFn) => {
    setEditingData({});
    setShowingModal(false);
  };

  const doSave = (setShowingModal: iSetShowingModalFn) => {
    setIsSaving(true);
    BuildStyleService.create(editingData)
      .then((resp) => {
        handleClose(setShowingModal);
        onCreated(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <PopupBtn
      titleId={'buildStyle-create-popup'}
      testId={'buildStyle-create-popup-btn'}
      shouldFitContainer
      {...props}
      modalProps={(setShowingModal) => ({
        shouldScrollInViewport: true,
        title: `Creating a new Style`,
        onClose: () => handleClose(setShowingModal),
        body: (
          <>
            <TextField
              isRequired
              testId={'name'}
              label={`Style name`}
              placeholder={`The name of the style...`}
              isDisabled={isSaving}
              value={editingData.name || ''}
              onChange={(event) =>
                setEditingData({
                  ...editingData,
                  name:
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    `${event.target.value || ''}`,
                })
              }
            />
          </>
        ),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setShowingModal),
          },
          actionBtnProps: {
            isDisabled: Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Create',
            testId: `${props.testId || ''}-createBtn`,
            onClick: () => doSave(setShowingModal),
          },
        }),
      })}
    />
  );
};

export default BuildStyleCreatePopupBtn;
