import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import iUser from '../../types/system/iUser';
import React, { useState } from 'react';
import UserHelper from '../../helpers/UserHelper';
import UserEditPanel from './UserEditPanel';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import UserService from '../../services/system/UserService';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';
import { iConfigParams } from '../../services/AppService';
import { iErrorMap } from '../form/FormError';

type iUserEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  user?: iUser;
  onSaved?: (saved: iUser) => void;
};

const UserEditPopupBtn = ({ user, onSaved, ...props }: iUserEditPopupBtn) => {
  const [editingData, setEditingData] = useState<iConfigParams | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const preSave = () => {
    const errors: iErrorMap = {};
    const data: iConfigParams = {
      ...(user || {}),
      ...(editingData || {}),
    };
    ['firstName', 'lastName', 'email'].forEach((key: string) => {
      if (`${data[key] || ''}`.trim() === '') {
        errors[key] = [`${key.toUpperCase()} is required`];
      }
    });

    if (`${user?.id || ''}`.trim() === '') {
      if (`${editingData?.username || ''}`.trim() === '') {
        errors.username = ['Username is required'];
      }
      if (`${editingData?.password || ''}`.trim() === '') {
        errors.password = ['Password is required'];
      }
    }
    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setShowingModal: iSetShowingModalFn) => {
    if (
      !editingData ||
      Object.keys(editingData || {}).length <= 0 ||
      preSave() !== true
    ) {
      return;
    }

    setIsSaving(true);
    const userId = `${user?.id || ''}`.trim();

    const saveFnc = () =>
      userId === ''
        ? UserService.create(editingData)
        : UserService.update(userId, editingData);

    saveFnc()
      .then((resp) => {
        setIsSaving(false);
        setShowingModal(false);
        setEditingData({});
        Toaster.showToast(
          userId === '' ? (
            <>
              <b>
                <u>{UserHelper.getFullName(resp)}</u>
              </b>{' '}
              Created
            </>
          ) : (
            'Updated'
          ),
          TOAST_TYPE_SUCCESS,
        );
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  return (
    <PopupBtn
      {...props}
      titleId={`user-edit-popup-${user?.id || 'new-user'}`}
      isDisabled={isSaving}
      modalProps={(setShowingModal) => ({
        showCloseBtn: isSaving !== true,
        shouldScrollInViewport: true,
        width: '760px',
        title: user ? `Editing: ${UserHelper.getFullName(user)}` : 'Create',
        body: (
          <div className={'margin-auto'}>
            <UserEditPanel
              isDisabled={isSaving}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              user={user ? { ...user, ...editingData } : editingData}
              className={'editor'}
              useAsForm={{
                onFieldChange: (fieldName, value) => {
                  setEditingData({
                    ...(editingData || {}),
                    [fieldName]: value,
                  });
                },
                errorMap: errorMap,
              }}
            />
          </div>
        ),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            onClick: () => {
              setErrorMap({});
              setEditingData(null);
              setShowingModal(false);
            },
          },
          actionBtnProps: {
            isDisabled: Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Save',
            onClick: () => doSave(setShowingModal),
          },
        }),
      })}
    />
  );
};

export default UserEditPopupBtn;
