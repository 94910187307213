import ServiceHelper from '../helper/ServiceHelper';
import iProduct from '../../types/product/iProduct';

const endPoint = '/product';
const ProductService = {
  getAll: ServiceHelper.getPaginatedAll<iProduct>(endPoint),
  get: ServiceHelper.getById<iProduct>(endPoint),
  create: ServiceHelper.create<iProduct>(endPoint),
  update: ServiceHelper.updateById<iProduct>(endPoint),
  deactivate: ServiceHelper.deactivateById<iProduct>(endPoint),
};

export default ProductService;
