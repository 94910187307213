import iBuild from '../../../../../types/build/iBuild';
import styled from 'styled-components';
import BuildStyleSelector from '../../../../buildStyle/BuildStyleSelector';
import { useEffect, useState } from 'react';
import BuildService from '../../../../../services/build/BuildService';
import Toaster from '../../../../common/Toaster';
import { iOption } from '../../../../frameWork/Select';
import Flex from '../../../../frameWork/Flex';
import iBuildStyle from '../../../../../types/build/iBuildStyle';
import BuildStyleService from '../../../../../services/build/BuildStyleService';
import EntityNames from '../../../../../helpers/EntityNames';
import BuildAreaList from '../../../../buildArea/BuildAreaList';
import SectionDiv from '../../../../frameWork/SectionDiv';

type iBuildDetailsContractHouse = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const Wrapper = styled.div``;

const BuildDetailsContractHouse = ({
  build,
  onBuildUpdated,
  isDisabled = false,
}: iBuildDetailsContractHouse) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [style, setStyle] = useState<iBuildStyle | null>(null);

  useEffect(() => {
    const styleId = `${build.styleId || ''}`.trim();
    if (styleId === '') {
      setStyle(null);
      return;
    }

    let isCanceled = false;
    setIsLoading(true);
    BuildStyleService.get(styleId)
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        setStyle(resp);
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [build.styleId]);

  const handleUpdate = (fieldName: string, newValue: string | null) => {
    setIsSaving(true);
    BuildService.update(build.id, {
      [fieldName]: newValue,
    })
      .then((resp) => {
        onBuildUpdated && onBuildUpdated(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const getBaseOffStyleSelector = () => {
    if (style?.isCustomised !== true) {
      return null;
    }
    return (
      <BuildStyleSelector
        noneCustomOnly
        label={'Base Off Style'}
        value={build.baseOffStyleId}
        isDisabled={isSaving || isLoading || isDisabled}
        onChange={(option: iOption) =>
          handleUpdate(
            'baseOffStyleId',
            option?.value ? `${option?.value}` : null,
          )
        }
      />
    );
  };

  return (
    <Wrapper>
      <Flex className={'align-items-start gap-1'}>
        <BuildStyleSelector
          label={'House Style'}
          value={build.styleId}
          isDisabled={isSaving || isLoading || isDisabled}
          onChange={(option: iOption) =>
            handleUpdate('styleId', option?.value ? `${option?.value}` : null)
          }
        />
        {getBaseOffStyleSelector()}
      </Flex>
      <SectionDiv>
        <BuildAreaList
          appearance={'tabs'}
          allowCreate={!isDisabled}
          allowDelete={!isDisabled}
          allowEdit={!isDisabled}
          headerProps={{ children: <h4>Areas</h4> }}
          entityName={EntityNames.Build}
          entityId={build.id}
        />
      </SectionDiv>
    </Wrapper>
  );
};

export default BuildDetailsContractHouse;
