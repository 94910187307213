import ServiceHelper from '../helper/ServiceHelper';
import iBank from '../../types/system/iBank';

const endPoint = '/bank';

const BankService = {
  getAll: ServiceHelper.getPaginatedAll<iBank>(endPoint),
  get: ServiceHelper.getById<iBank>(endPoint),
  create: ServiceHelper.create<iBank>(endPoint),
  update: ServiceHelper.updateById<iBank>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBank>(endPoint),
};

export default BankService;
