import {
  DatePicker as OriginalDatePicker,
  TimePicker as OriginalTimePicker,
  DateTimePicker as OriginalDateTimePicker,
  DatePickerProps,
  TimePickerProps,
  DateTimePickerProps,
} from '@atlaskit/datetime-picker';
import FormField, { iFormField } from './FormField';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/makeReduxStore';

export const DEFAULT_DATE_PLACEHOLDER = 'DD/MM/YYYY';
export const DEFAULT_TIME_PLACEHOLDER = 'hh:mm';

export type iTimePicker = TimePickerProps;
export const TimePicker = (props: Omit<iFormField<iTimePicker>, 'render'>) => {
  const { locale } = useSelector((s: RootState) => s.app);
  return (
    <FormField<iTimePicker>
      {...props}
      render={(fProps) => {
        return (
          <OriginalTimePicker
            placeholder={DEFAULT_TIME_PLACEHOLDER}
            locale={locale}
            {...fProps}
          />
        );
      }}
    />
  );
};

export type iDatePicker = DatePickerProps;
export const DatePicker = (props: Omit<iFormField<iDatePicker>, 'render'>) => {
  const { locale } = useSelector((s: RootState) => s.app);
  return (
    <FormField<iDatePicker>
      {...props}
      render={(fProps) => {
        return (
          <OriginalDatePicker
            placeholder={DEFAULT_DATE_PLACEHOLDER}
            locale={locale}
            {...fProps}
          />
        );
      }}
    />
  );
};

export type iDateTimePicker = DateTimePickerProps;
const DateTimePicker = (props: Omit<iFormField<iDateTimePicker>, 'render'>) => {
  const { locale } = useSelector((s: RootState) => s.app);
  return (
    <FormField<iDateTimePicker>
      {...props}
      render={(fProps) => {
        return (
          <OriginalDateTimePicker
            datePickerProps={{
              placeholder: DEFAULT_DATE_PLACEHOLDER,
              locale: locale,
              ...fProps.datePickerProps,
            }}
            timePickerProps={{
              placeholder: DEFAULT_TIME_PLACEHOLDER,
              locale: locale,
              ...fProps.timePickerProps,
            }}
            {...fProps}
          />
        );
      }}
    />
  );
};

export const formatDate = (date: Date | string | null, format?: string) => {
  const dateStr = `${date || ''}`.trim();
  if (dateStr === '') {
    return undefined;
  }
  const formatStr = `${format || ''}`.trim();
  if (formatStr === '') {
    return moment(dateStr).toISOString();
  }
  return moment(dateStr).format(format);
};

export default DateTimePicker;
