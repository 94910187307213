import ServiceHelper from '../helper/ServiceHelper';
import iBuildSuspensionType from '../../types/buildSuspension/iBuildSuspensionType';

const endPoint = '/buildSuspensionType';
const BuildSuspensionTypeService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildSuspensionType>(endPoint),
  get: ServiceHelper.getById<iBuildSuspensionType>(endPoint),
  create: ServiceHelper.create<iBuildSuspensionType>(endPoint),
  update: ServiceHelper.updateById<iBuildSuspensionType>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildSuspensionType>(endPoint),
};

export default BuildSuspensionTypeService;
