import ServiceHelper from '../helper/ServiceHelper';
import iHouseStorey from '../../types/build/iHouseStorey';

const endPoint = '/houseStorey';
const HouseStoreyService = {
  getAll: ServiceHelper.getPaginatedAll<iHouseStorey>(endPoint),
  get: ServiceHelper.getById<iHouseStorey>(endPoint),
  create: ServiceHelper.create<iHouseStorey>(endPoint),
  update: ServiceHelper.updateById<iHouseStorey>(endPoint),
  deactivate: ServiceHelper.deactivateById<iHouseStorey>(endPoint),
};

export default HouseStoreyService;
