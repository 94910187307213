import Button, { ButtonProps } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import { ReactNode } from 'react';
import Toaster, { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from './Toaster';

type iCopyToClipboardBtn = Omit<ButtonProps, 'children'> & {
  valueToCopy: string;
  children?: ReactNode;
};

const CopyToClipboardBtn = ({
  valueToCopy,
  children,
  ...props
}: iCopyToClipboardBtn) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(valueToCopy);
      Toaster.showToast('Copied', TOAST_TYPE_SUCCESS);
    } catch (err) {
      Toaster.showToast('Failed to copy to clipboard.', TOAST_TYPE_ERROR);
    }
  };
  return (
    <Button
      iconBefore={Icons.CopyIcon}
      {...props}
      onClick={() => copyToClipboard()}
    >
      {children || 'Copy'}
    </Button>
  );
};

export default CopyToClipboardBtn;
