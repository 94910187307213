import Text from '../frameWork/Text';
import * as _ from 'lodash';

export type iErrorMap = { [key: string]: string[] | string };

type iFormError = {
  error?: iErrorMap | null;
  fieldName: string;
};

export const getRequiredFieldsErrorMap = (
  fields: { [key: string]: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entity: any,
): iErrorMap => {
  return Object.keys(fields).reduce((map: iErrorMap, fieldName) => {
    if (!(fieldName in entity) || `${entity[fieldName] || ''}`.trim() === '') {
      return {
        ...map,
        [fieldName]: [`${_.capitalize(fields[fieldName])} is required.`],
      };
    }
    return map;
  }, {});
};

export const hasError = ({ error, fieldName }: iFormError) => {
  const errMap = error || {};
  if (!(fieldName in errMap)) {
    return false;
  }
  const errors = errMap[fieldName];
  if (Array.isArray(errors)) {
    return errors.join(' ').trim() !== '';
  }
  return `${errors || ''}`.trim() !== '';
};

export const getErrorMsgStr = ({ error, fieldName }: iFormError) => {
  if (!hasError({ error, fieldName })) {
    return undefined;
  }
  const errMap = error || {};
  const errors = errMap[fieldName];
  if (Array.isArray(errors)) {
    return errors.join('');
  }
  return `${errors}`.trim();
};

export const getErrorProps = ({ error, fieldName }: iFormError) => {
  return {
    errorMsg: getErrorMsgStr({ error, fieldName }),
    isInvalid: hasError({ error, fieldName }),
  };
};

const FormError = ({ error, fieldName }: iFormError) => {
  const errorStr = getErrorMsgStr({ error, fieldName }) || '';
  if (`${errorStr}`.trim() === '') {
    return null;
  }
  return (
    <Text color={'color.text.danger'} size={'small'}>
      {errorStr}
    </Text>
  );
};

export default FormError;
