import { ReactNode, useEffect, useState } from 'react';
import Toaster from '../common/Toaster';
import iContactCompany from '../../types/contact/iContactCompany';
import ContactCompanyService from '../../services/contact/ContactCompanyService';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { URL_CONTACT_COMPANY_DETAILS } from '../../helpers/UrlMap';
import Icons from '../frameWork/Icons';
import Flex from '../frameWork/Flex';
import StringHelper from '../../helpers/StringHelper';

const Wrapper = styled.div``;

export type iContactCompanyDiv = {
  id: string;
  testId?: string;
  className?: string;
  render?: (contactCompany: iContactCompany) => ReactNode;
};
const ContactCompanyDiv = ({
  testId,
  id,
  className,
  render,
}: iContactCompanyDiv) => {
  const componentName = 'contactCompany-div';
  const testIdStr = `${testId || ''}-${componentName}`;
  const [contactCompany, setContactCompany] = useState<iContactCompany | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (`${id || ''}`.trim() === '' || !StringHelper.isUUID(id)) {
      setContactCompany(null);
      return;
    }
    let isCanceled = false;
    setIsLoading(true);
    ContactCompanyService.get(id)
      .then((resp) => {
        if (isCanceled) {
          return;
        }
        setContactCompany(resp);
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [id]);

  const getContent = () => {
    if (!StringHelper.isUUID(id)) {
      return id;
    }

    if (!contactCompany || isLoading) {
      return null;
    }

    if (render) {
      return render(contactCompany);
    }

    return (
      <Flex className={'gap-025 align-items-center'}>
        <Link
          to={URL_CONTACT_COMPANY_DETAILS.replace(':id', contactCompany.id)}
          title={`View Supplier Details.`}
          data-testid={`${testIdStr}-link`}
        >
          <Icons.LinkIcon label={''} size={'small'} />
        </Link>
        <div>{contactCompany.name}</div>
      </Flex>
    );
  };

  return (
    <Wrapper
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      {getContent()}
    </Wrapper>
  );
};

export default ContactCompanyDiv;
