import iBuild from '../../../../types/build/iBuild';
import DetailsPanelWithSubMenuPanel from '../../../layout/DetailsPanelWithSubMenuPanel';
import BuildDetailsPermitHelper from './BuildDetailsPermitHelper';

type iBuildDetailsPermitPanel = {
  build: iBuild;
  testId?: string;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};
const BuildDetailsPermitPanel = ({
  build,
  onBuildUpdated,
  testId,
  isDisabled = false,
}: iBuildDetailsPermitPanel) => {
  const testIdStr = `${testId || ''}-buildDetails-permit`;

  return (
    <DetailsPanelWithSubMenuPanel
      testId={testIdStr}
      menuItemsMap={BuildDetailsPermitHelper.getBuildDetailsPermitMenuItemsMap({
        onBuildUpdated,
        build,
        isDisabled,
      })}
    />
  );
};

export default BuildDetailsPermitPanel;
