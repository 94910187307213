import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsVariationsPanel = {
  build: iBuild;
};
const BuildDetailsVariationsPanel = ({
  build,
}: iBuildDetailsVariationsPanel) => {
  return <Wrapper>Variations: {build.id}</Wrapper>;
};

export default BuildDetailsVariationsPanel;
