import iAsset from '../../types/asset/iAsset';
import styled from 'styled-components';
import Image, { IMAGE_PLACE_HOLDER_PATH } from '../frameWork/Image';
import { useEffect, useState } from 'react';
import Flex from '../frameWork/Flex';
import { IconButton } from '../frameWork/Button';
import Icons from '../frameWork/Icons';
import AssetService from '../../services/asset/AssetService';
import Toaster from '../common/Toaster';

export type iAssetViewer = {
  className?: string;
  asset: iAsset;
  showViewBtn?: boolean;
  onTempUrlGenerated?: (tempUrl: string) => void;
};
const Wrapper = styled.div`
  position: relative;

  .preview-btn-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.85);
  }
`;
const AssetViewer = ({
  asset,
  className,
  showViewBtn,
  onTempUrlGenerated,
}: iAssetViewer) => {
  const testIdStr = `asset-viewer-${asset.id}`;
  const [publicUrl, setPublicUrl] = useState<string | null>(null);
  const [isGetting, setIsGetting] = useState(false);

  useEffect(() => {
    if (asset.isPublic === true) {
      setPublicUrl(asset.url || '');
      return;
    }
    setPublicUrl(null);
  }, [asset.url, asset.isPublic, asset.id]);

  const getImageUrl = () => {
    const publicUrlStr = `${publicUrl || ''}`.trim();
    if (publicUrlStr === '') {
      return IMAGE_PLACE_HOLDER_PATH;
    }
    return publicUrlStr;
  };

  const genTempUrl = () => {
    setIsGetting(true);
    AssetService.getPublicUrl(asset.id)
      .then((resp) => {
        setIsGetting(false);
        const url = `${resp.url || ''}`.trim();
        if (url === '') {
          return;
        }
        setPublicUrl(url);
        onTempUrlGenerated && onTempUrlGenerated(url);
      })
      .catch((err) => {
        setIsGetting(false);
        Toaster.showApiError(err);
      });
  };

  const getViewBtn = () => {
    if (showViewBtn !== true) {
      return null;
    }
    const publicUrlStr = `${publicUrl || ''}`.trim();
    if (publicUrlStr !== '') {
      return null;
    }
    return (
      <Flex
        className={
          'preview-btn-wrapper align-items-center justify-content-center'
        }
      >
        <IconButton
          testId={`${testIdStr}-view-btn`}
          icon={Icons.VidPlayIcon}
          label={'View'}
          onClick={() => genTempUrl()}
          isLoading={isGetting}
        />
      </Flex>
    );
  };

  return (
    <Wrapper
      className={`asset-viewer ${className || ''}`}
      data-testid={testIdStr}
    >
      <Image src={getImageUrl()} testId={`${testIdStr}-image`} />
      {getViewBtn()}
    </Wrapper>
  );
};

export default AssetViewer;
