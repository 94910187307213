import iAttachment from '../../types/asset/iAttachment';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import AttachmentService from '../../services/attachment/AttachmentService';
import styled from 'styled-components';
import Spinner from '../frameWork/Spinner';
import Flex from '../frameWork/Flex';
import AttachmentUploadPopupBtn from './AttachmentUploadPopupBtn';
import Icons from '../frameWork/Icons';
import Tokens from '../frameWork/Tokens';
import React, { useEffect, useState } from 'react';
import AssetViewer from './AssetViewer';
import Image, { IMAGE_PLACE_HOLDER_PATH } from '../frameWork/Image';
import MathHelper from '../../helpers/MathHelper';
import MakeAssetPublicPopupBtn from './MakeAssetPublicPopupBtn';
import GetAssetPublicUrlPopupBtn from './GetAssetPublicUrlPopupBtn';
import EntityNames from '../../helpers/EntityNames';
import { AssetTypes } from '../../types/asset/iAsset';

const thumbnailWidth = 120;
const Wrapper = styled.div`
  background-color: ${Tokens('color.background.neutral')};
  position: relative;
  padding: ${Tokens('space.050')};
  .preview-div {
    min-height: 23rem;
    height: 23rem;
    padding: ${Tokens('space.100')};
    padding-bottom: 110px;
    position: relative;
    .preview-btns {
      background: rgba(255, 255, 255, 0.8);
      padding: ${Tokens('space.050')};
      position: absolute;
      top: ${Tokens('space.100')};
      right: ${Tokens('space.100')};
      .delete-btn-wrapper {
        button {
          color: ${Tokens('color.text.accent.red', '#AE2424')};
        }
      }
    }
    .preview-img-wrapper {
      height: 100%;
      width: 100%;
      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }

  .thumbnails-row {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: ${Tokens('space.050')};
    padding-left: ${Tokens('space.100')};
    padding-right: ${Tokens('space.100')};
    .thumbnails-content-row {
      .thumbnail-div {
        cursor: pointer;
        display: flex;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: ${thumbnailWidth}px;
        border: 2px #fff solid;
        &:hover {
          border-color: ${Tokens(
            'color.background.accent.gray.subtlest.hovered',
          )};
        }
        &.selected {
          border-color: ${Tokens(
            'color.background.accent.gray.subtlest.pressed',
          )};
        }
        > div {
          height: 100%;
          width: 100%;
        }
        img {
          object-fit: contain;
          height: 100%;
          width: 100%;
        }

        &.new:hover {
          box-shadow: 2px 2px 2px #eee;
          background-color: #cecece;
        }
      }
    }
  }
`;

export type iAttachmentPreviewPanel = {
  entityName: EntityNames;
  entityId: string;
  types?: AssetTypes[];
  className?: string;
  allowCreate?: boolean;
  allowDelete?: boolean;
  allowSettingExistingAssetPublic?: boolean;
  setNewAssetToPublic?: boolean;
};
const AttachmentPreviewPanel = ({
  entityName,
  entityId,
  types = [],
  className,
  allowCreate,
  allowDelete,
  allowSettingExistingAssetPublic,
  setNewAssetToPublic,
}: iAttachmentPreviewPanel) => {
  const testIdStr = `attachment-preview-${entityName}-${entityId}`;
  const [viewingAttachment, setViewingAttachment] =
    useState<iAttachment | null>(null);
  const [localAttachmentMap, setLocalAttachmentMap] = useState<{
    [key: string]: iAttachment;
  }>({});

  const {
    state,
    viewingState,
    onRefreshWhenCreated,
    renderDeleteBtn,
    onSetPage,
  } = useListCrudHook<iAttachment>({
    sort: `createdAt:DESC`,
    appendDataWhenLoadMore: true,
    getFn: (params) =>
      AttachmentService.getAll({
        where: JSON.stringify({
          isActive: true,
          entityName,
          entityId,
          ...(types?.length > 0 ? { type: types } : {}),
          ...(params?.filter || {}),
        }),
        include: 'Asset',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  useEffect(() => {
    setLocalAttachmentMap({});
  }, []);

  const getAssetDiv = (
    attachment: iAttachment | null,
    className?: string,
    showPreviewBtn = false,
    onAttachmentUpdated?: (attachment: iAttachment) => void,
  ) => {
    if (!attachment || !attachment.Asset) {
      return (
        <div className={className}>
          <Image src={IMAGE_PLACE_HOLDER_PATH} />
        </div>
      );
    }
    return (
      <AssetViewer
        asset={attachment.Asset}
        className={className}
        showViewBtn={showPreviewBtn}
        onTempUrlGenerated={(tempUrl: string) => {
          if (!onAttachmentUpdated) {
            return;
          }
          onAttachmentUpdated({
            ...attachment,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            Asset: {
              ...(attachment?.Asset || {}),
              isPublic: true,
              url: tempUrl,
            },
          });
        }}
      />
    );
  };

  const getCreateBtn = () => {
    if (allowCreate !== true) {
      return null;
    }
    return (
      <AttachmentUploadPopupBtn
        entityId={entityId}
        entityName={entityName}
        assetTypes={types}
        isAssetPublic={setNewAssetToPublic}
        onUploaded={() => onRefreshWhenCreated()}
        renderBtn={(onClick) => {
          return (
            <div
              className={'thumbnail-div new'}
              onClick={onClick}
              data-testid={`${testIdStr}-create-btn`}
            >
              <Icons.AddIcon label={'new'} />
            </div>
          );
        }}
      />
    );
  };

  const getDeleteBtn = (attachment: iAttachment) => {
    if (allowDelete !== true) {
      return null;
    }
    return (
      <div className={'delete-btn-wrapper'}>
        {renderDeleteBtn({
          deletingModel: attachment,
          deleteFnc: () => AttachmentService.deactivate(attachment.id),
          onDeleted: () => {
            setViewingAttachment(null);
          },
        })}
      </div>
    );
  };

  const getMakePublicBtn = (attachment: iAttachment) => {
    if (allowSettingExistingAssetPublic !== true || !attachment.Asset) {
      return null;
    }
    const id = attachment.id;
    const originalAttachments = (state.data.data || []).filter(
      (att) => att.id === id,
    );
    const asset =
      id in localAttachmentMap && originalAttachments.length > 0
        ? originalAttachments[0].Asset
        : attachment.Asset;
    if (!asset || asset.isPublic == true) {
      return null;
    }
    return (
      <MakeAssetPublicPopupBtn
        asset={asset}
        onUpdated={(saved) => {
          if (viewingAttachment && attachment.id === viewingAttachment.id) {
            setViewingAttachment({
              ...viewingAttachment,
              Asset: saved,
            });
          }
          onRefreshWhenCreated();
        }}
      />
    );
  };

  const getUrlBtn = (attachment: iAttachment) => {
    if (!attachment.Asset) {
      return null;
    }
    return <GetAssetPublicUrlPopupBtn asset={attachment.Asset} />;
  };

  const getThumbnailAttachments = () => {
    return (state.data.data || []).map((attachment) => {
      const key = attachment.id;
      if (key in localAttachmentMap) {
        return localAttachmentMap[key];
      }
      return attachment;
    });
  };

  const getViewingAttachment = () => {
    if (viewingAttachment !== null) {
      return viewingAttachment;
    }
    const attachments = getThumbnailAttachments();
    if (attachments.length > 0) {
      return attachments[0];
    }
    return null;
  };

  const getMoreBtn = () => {
    if (state.data.currentPage >= (state.data.pages || 0)) {
      return null;
    }
    return (
      <div
        className={'thumbnail-div load-more-btn'}
        data-testid={`${testIdStr}-load-more-btn`}
        onClick={() => onSetPage(MathHelper.add(state.data.currentPage, 1))}
      >
        <Flex
          className={'flex-column align-items-center justify-content-center'}
        >
          <Icons.ArrowRightIcon label={'load more'} />
          <div>Load More</div>
        </Flex>
      </div>
    );
  };

  const getContent = () => {
    if (state.isLoading === true && (viewingState.currentPage || 0) <= 1) {
      return <Spinner size={'large'} label={'loading'} />;
    }
    const attachments = getThumbnailAttachments();
    const viewAtt = getViewingAttachment();
    return (
      <>
        <Flex
          className={'preview-div align-items-center justify-content-center'}
          data-testid={`${testIdStr}-view-${viewAtt?.id}`}
        >
          {getAssetDiv(viewAtt, 'preview-img-wrapper', true, (attachment) => {
            setViewingAttachment(attachment);
            setLocalAttachmentMap({
              ...localAttachmentMap,
              [attachment.id]: attachment,
            });
          })}
          <Flex className={'preview-btns'}>
            {viewAtt && (
              <>
                {getUrlBtn(viewAtt)}
                {getMakePublicBtn(viewAtt)}
                {getDeleteBtn(viewAtt)}
              </>
            )}
          </Flex>
        </Flex>
        <div
          className={'thumbnails-row'}
          data-testid={`${testIdStr}-thumbnails`}
        >
          <Flex
            className={'gap-1 thumbnails-content-row'}
            style={{
              width: `${MathHelper.mul(MathHelper.add(attachments.length, 1), MathHelper.add(thumbnailWidth, 15))}px`,
            }}
          >
            {getCreateBtn()}
            {attachments.map((attachment, index) => {
              return (
                <div
                  className={`thumbnail-div ${(!viewAtt && index === 0) || attachment.id === viewAtt?.id ? 'selected' : ''}`}
                  key={attachment.id}
                  data-testid={`${testIdStr}-thumbnail-${attachment.id}`}
                  onClick={() => setViewingAttachment(attachment)}
                >
                  {getAssetDiv(attachment)}
                </div>
              );
            })}
            {getMoreBtn()}
          </Flex>
        </div>
      </>
    );
  };

  return (
    <Wrapper
      data-testid={testIdStr}
      className={`attachment-preview-wrapper ${className || ''}`}
    >
      {getContent()}
    </Wrapper>
  );
};

export default AttachmentPreviewPanel;
