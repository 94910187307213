import { iOption } from '../frameWork/Select';
import { OP_LIKE } from '../../services/ServiceHelper';
import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import iBuildSuspensionType from '../../types/buildSuspension/iBuildSuspensionType';
import BuildSuspensionTypeService from '../../services/buildSuspension/BuildSuspensionTypeService';

export type iBuildSuspensionTypeSelector = iPreloadAsyncSelectProps;
const BuildSuspensionTypeSelector = (props: iBuildSuspensionTypeSelector) => {
  return (
    <PreloadedAsyncSelector<iBuildSuspensionType>
      placeholder={'Select a type ...'}
      {...props}
      formatOptionLabel={(data: iOption) => {
        if (props.formatOptionLabel) {
          return props.formatOptionLabel(data);
        }
        const description = `${data.data.description || ''}`.trim();
        return (
          <>
            {data.data.name}
            {description === '' ? null : <small>: {description}</small>}
          </>
        );
      }}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return BuildSuspensionTypeService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(searchTxtStr === ''
              ? {}
              : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        BuildSuspensionTypeService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default BuildSuspensionTypeSelector;
