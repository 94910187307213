import iUser from '../../types/system/iUser';
import UserAvatar from './UserAvatar';
import { IconButton } from '../frameWork/Button';
import LogoutBtn from './LogoutBtn';
import { useState } from 'react';
import Popup from '../frameWork/Popup';
import { Section, LinkItem, ButtonItem } from '../frameWork/Menu';
import Icons from '../frameWork/Icons';
import { useNavigate } from 'react-router-dom';
import { URL_LOGIN, URL_MY_PROFILE } from '../../helpers/UrlMap';
import UserHelper from '../../helpers/UserHelper';

type iUserAvatarPopup = {
  user?: iUser;
};
const UserAvatarPopup = ({ user }: iUserAvatarPopup) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const testId = 'user-avatar-popup';
  return (
    <Popup
      testId={testId}
      isOpen={isOpen}
      placement={'bottom-end'}
      onClose={() => setIsOpen(false)}
      content={() => (
        <>
          <Section>
            <LinkItem
              testId={`${testId}-profile-link`}
              href={URL_MY_PROFILE}
              iconBefore={<Icons.PersonIcon label={''} size={'small'} />}
            >
              My Profile
            </LinkItem>
          </Section>
          <Section testId={`${testId}-logout-btn-wrapper`}>
            <LogoutBtn
              testId={`${testId}-logout-btn`}
              renderBtn={(onClick) => (
                <ButtonItem
                  testId={`${testId}-logout-btn`}
                  onClick={onClick}
                  iconBefore={
                    <Icons.SignOutIcon label={'sign-out'} size={'small'} />
                  }
                >
                  Logout
                </ButtonItem>
              )}
              onSuccess={() => navigate(URL_LOGIN)}
              shouldFitContainer
              appearance="subtle"
            />
          </Section>
        </>
      )}
      trigger={(triggerProps) => (
        <IconButton
          {...triggerProps}
          testId={`${testId}-btn`}
          label={user ? UserHelper.getFullName(user) : ''}
          shape={'circle'}
          onClick={() => setIsOpen(!isOpen)}
          icon={() => <UserAvatar user={user} size={'small'} />}
        />
      )}
    />
  );
};

export default UserAvatarPopup;
