import SettingsPage from '../../layouts/SettingsPage';
import PlanRevisionList from '../../components/plan/PlanRevisionList';

const PlanRevisionsPage = () => {
  return (
    <SettingsPage title={'Plan Revisions'}>
      {(headerProps) => (
        <PlanRevisionList allowDelete headerProps={headerProps} />
      )}
    </SettingsPage>
  );
};

export default PlanRevisionsPage;
