import iUser from '../../types/system/iUser';
import ServiceHelper from '../helper/ServiceHelper';

const endPoint = '/user';

const UserService = {
  getAll: ServiceHelper.getPaginatedAll<iUser>(endPoint),
  get: ServiceHelper.getById<iUser>(endPoint),
  create: ServiceHelper.create<iUser>(endPoint),
  update: ServiceHelper.updateById<iUser>(endPoint),
  deactivate: ServiceHelper.deactivateById<iUser>(endPoint),
};

export default UserService;
