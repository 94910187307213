import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsPreHandOverPanel = {
  build: iBuild;
};
const BuildDetailsPreHandOverPanel = ({
  build,
}: iBuildDetailsPreHandOverPanel) => {
  return <Wrapper>Pre-Hand Over: {build.id}</Wrapper>;
};

export default BuildDetailsPreHandOverPanel;
