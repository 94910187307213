import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsClaimsPanel = {
  build: iBuild;
};
const BuildDetailsClaimsPanel = ({ build }: iBuildDetailsClaimsPanel) => {
  return <Wrapper>Claims: {build.id}</Wrapper>;
};

export default BuildDetailsClaimsPanel;
