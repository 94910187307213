import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsDocumentsPanel = {
  build: iBuild;
};
const BuildDetailsDocumentsPanel = ({ build }: iBuildDetailsDocumentsPanel) => {
  return <Wrapper>Documents: {build.id}</Wrapper>;
};

export default BuildDetailsDocumentsPanel;
