import ProtectedPage from '../../layouts/ProtectedPage';
import SuburbList from '../../components/suburb/SuburbList';

const SuburbsPage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `Suburbs`,
      }}
    >
      {(headerProps) => <SuburbList allowDelete headerProps={headerProps} />}
    </ProtectedPage>
  );
};

export default SuburbsPage;
