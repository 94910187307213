import iBuild from '../../../../../types/build/iBuild';
import { DatePicker } from '../../../../frameWork/DateTimePicker';
import styled from 'styled-components';
import InlineEdit, { ViewWrapper } from '../../../../frameWork/InlineEdit';
import { useState } from 'react';
import BuildService from '../../../../../services/build/BuildService';
import moment from 'moment';
import Toaster from '../../../../common/Toaster';
import Flex from '../../../../frameWork/Flex';
import Tokens from '../../../../frameWork/Tokens';
import UtilsService from '../../../../../services/UtilsService';
import FormField from '../../../../frameWork/FormField';
import { iConfigParams } from '../../../../../services/AppService';
import MathHelper from '../../../../../helpers/MathHelper';
import AttachmentsTable from '../../../../asset/AttachmentsTable';
import EntityNames from '../../../../../helpers/EntityNames';
import Heading from '../../../../frameWork/Heading';
import { SelectiveColKeys } from '../../../../../services/LocalStorageService';
import { AssetTypes } from '../../../../../types/asset/iAsset';

type iBuildDetailsContractOverview = {
  build: iBuild;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: start;
  form {
    margin-top: 0px;
  }
  .price-el-before {
    padding: 0 0 0 ${Tokens('space.050', '1rem')};
  }
`;

const BuildDetailsContractOverview = ({
  build,
  onBuildUpdated,
  isDisabled = false,
}: iBuildDetailsContractOverview) => {
  const [isSaving, setIsSaving] = useState(false);

  const handleUpdate = (newValue: iConfigParams) => {
    setIsSaving(true);
    BuildService.update(build.id, newValue)
      .then((resp) => {
        onBuildUpdated && onBuildUpdated(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <Wrapper>
      <Flex className={'flex-wrap align-items-flex-start gap-05 full-width'}>
        <DatePicker
          label={'Contract Date'}
          isDisabled={isSaving || isDisabled}
          appearance={'subtle'}
          value={`${build.contractDate || ''}`}
          onChange={(selected) =>
            handleUpdate({
              contractDate:
                selected === null ? null : moment(selected).toISOString(),
            })
          }
        />
        <InlineEdit
          label={'Contract Days'}
          isDisabled={isSaving || isDisabled}
          placeHolder={'Number of days in the contract'}
          value={`${build.contractDays || ''}`}
          onConfirm={(days) =>
            handleUpdate({
              contractDays: `${days || ''}`.trim() === '' ? null : days,
            })
          }
        />
      </Flex>

      <Flex className={'flex-wrap align-items-flex-start gap-05 full-width'}>
        {[
          {
            name: 'Base Price',
            value: `${build.basePrice || '0'}`.trim(),
            fieldName: 'basePrice',
          },
          {
            name: 'Variance Price',
            value: `${build.variancePrice || '0'}`.trim(),
            fieldName: 'variancePrice',
          },
          {
            name: 'Site Allowance Price',
            value: `${build.siteAllowancePrice || '0'}`.trim(),
            fieldName: 'siteAllowancePrice',
          },
        ].map((item) => {
          return (
            <InlineEdit
              key={item.fieldName}
              readViewFitContainerWidth
              label={item.name}
              isDisabled={isSaving || isDisabled}
              placeHolder={item.name}
              value={item.value}
              editViewProps={{
                elemBeforeInput: <div className={'price-el-before'}>$</div>,
              }}
              readView={() => {
                return (
                  <ViewWrapper>
                    {UtilsService.formatIntoCurrency(Number(item.value))}
                  </ViewWrapper>
                );
              }}
              onConfirm={(price) => {
                const newPrice = Number(`${price || 0}`.trim());
                handleUpdate({
                  [item.fieldName]: newPrice,
                  contractPrice: MathHelper.add(
                    newPrice,
                    ['basePrice', 'variancePrice', 'siteAllowancePrice']
                      .filter((fieldName) => fieldName !== item.fieldName)
                      .reduce((sum, fieldName) => {
                        return MathHelper.add(
                          sum,
                          fieldName in build
                            ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-expect-error
                              Number(build[fieldName] || 0)
                            : 0,
                        );
                      }, 0),
                  ),
                });
              }}
            />
          );
        })}
        <FormField
          label={'Contract Price'}
          render={() => {
            return (
              <ViewWrapper className={'none-pointer'}>
                {UtilsService.formatIntoCurrency(
                  Number(`${build.contractPrice || '0'}`.trim()),
                )}
              </ViewWrapper>
            );
          }}
        />
      </Flex>

      <AttachmentsTable
        entityName={EntityNames.Build}
        entityId={build.id}
        types={[AssetTypes.CONTRACT_FILES]}
        selectiveColumnKey={SelectiveColKeys.CONTRACT_FILE_LIST}
        headerProps={{
          children: <Heading size={'small'}>Contract Files</Heading>,
          className: 'no-bottom-margin',
        }}
      />
    </Wrapper>
  );
};

export default BuildDetailsContractOverview;
