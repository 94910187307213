import { iOption } from '../frameWork/Select';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';
import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import iBuildStyle from '../../types/build/iBuildStyle';
import BuildStyleService from '../../services/build/BuildStyleService';
import Badge from '../frameWork/Badge';

export type iBuildStyleSelector = iPreloadAsyncSelectProps & {
  noneCustomOnly?: boolean;
};
const BuildStyleSelector = ({
  noneCustomOnly = false,
  ...props
}: iBuildStyleSelector) => {
  return (
    <PreloadedAsyncSelector<iBuildStyle>
      placeholder={'Select a style ...'}
      {...props}
      formatOptionLabel={(data: iOption) => {
        if (props.formatOptionLabel) {
          return props.formatOptionLabel(data);
        }
        return (
          <>
            {data.data.name}{' '}
            {data.data.isCustomised !== true ? null : (
              <Badge appearance={'added'}>Custom</Badge>
            )}
          </>
        );
      }}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return BuildStyleService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(noneCustomOnly === true
              ? { [OP_OR]: [{ isCustomised: false }, { isCustomised: null }] }
              : {}),
            ...(searchTxtStr === ''
              ? {}
              : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        BuildStyleService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default BuildStyleSelector;
