import ProtectedPage from '../../layouts/ProtectedPage';
import StateList from '../../components/state/StateList';

const StatePage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `States`,
      }}
    >
      {(headerProps) => <StateList allowDelete headerProps={headerProps} />}
    </ProtectedPage>
  );
};

export default StatePage;
