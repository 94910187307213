import AppService, { iConfigParams } from '../AppService';
import { AxiosRequestConfig } from 'axios';
import ServiceHelper from '../helper/ServiceHelper';
import iCompany from '../../types/system/iCompany';

const endPoint = `/company`;

const getCompanyInfo = async (
  params?: iConfigParams,
  config: AxiosRequestConfig = {},
): Promise<{ name: string; logoUrl: string; id: string }> => {
  return AppService.get(`${endPoint}/info`, params, config).then(
    ({ data }) => data,
  );
};

const CompanyService = {
  getCompanyInfo,
  get: ServiceHelper.getById<iCompany>(endPoint),
  update: ServiceHelper.updateById<iCompany>(endPoint),
};

export default CompanyService;
