import iAttribute from '../../../types/attribute/iAttribute';
import { iConfigParams } from '../../../services/AppService';
import styled from 'styled-components';
import { iCellParams } from '../../../helpers/DynamicTableHelper';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../../common/PageTitleWithCreateBtn';
import AttributeDisplayLogicEditPopupBtn from './AttributeDisplayLogicEditPopupBtn';
import Tokens from '../../frameWork/Tokens';
import AttributeSettingsHelper, {
  AttrSettingsFieldNames,
  iEditLogic,
} from './AttributeSettingsHelper';
import useListCrudHook from '../../hooks/useListCrudHook/useListCrudHook';
import MathHelper from '../../../helpers/MathHelper';
import * as _ from 'lodash';
import Icons from '../../frameWork/Icons';
import { IconButton } from '../../frameWork/Button';
import React from 'react';
import SectionMessage from '../../frameWork/SectionMessage';

const Wrapper = styled.div`
  .conditions-table {
    tbody {
      .form-field-wrapper {
        > div {
          background-color: transparent;
          border: none;
        }
        margin-top: 0px;
        min-height: auto;
        [class$='-control'] {
          min-height: auto;
          div {
            font-weight: bold;
            color: ${Tokens('color.text.selected')};
            padding: 0px;
            min-height: auto;
          }
        }
        input {
          padding: 0px;
          width: auto;
          font-weight: bold;
          color: ${Tokens('color.text.selected')} !important;
          -webkit-text-fill-color: ${Tokens('color.text.selected')} !important;
        }
      }
      [class$='-indicatorContainer'] {
        display: none;
      }
    }
  }
`;
type iEditLogicWithId = Omit<iEditLogic, 'id'> & { id: string };
type iAttributeForSectionEditPanel = {
  attribute?: iAttribute;
  isDisabled?: boolean;
  testId?: string;
  className?: string;
  onUpdate: (newSettings: iConfigParams) => void;
};
const AttributeDisplayLogic = ({
  attribute,
  testId,
  className,
  onUpdate,
  isDisabled = false,
}: iAttributeForSectionEditPanel) => {
  const componentName = 'attribute-edit-logic';
  const testIdStr = `${testId || ''}-${componentName}`;
  const { state, renderDataTable, renderDeleteBtn, onRefresh } =
    useListCrudHook<iEditLogicWithId>({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      getFn: async () => {
        const data = AttributeSettingsHelper.getEditLogic(attribute);
        return {
          data,
          from: 1,
          to: MathHelper.sub(data.length, 1),
          total: data.length,
          pages: 1,
          currentPage: 1,
          perPage: data.length,
        };
      },
    });

  const handleSaved = (newData: iEditLogicWithId) => {
    const currentLogics = state.data.data || [];
    let updatedLogics = currentLogics;
    const index = _.findIndex(currentLogics, {
      id: `${newData.id || ''}`.trim(),
    });
    if (index < 0) {
      updatedLogics = [newData, ...updatedLogics];
    } else {
      updatedLogics[index] = newData;
    }
    onUpdate({
      ...(attribute?.settings || {}),
      [AttrSettingsFieldNames.editLogic]: updatedLogics,
    });
  };

  const submitReorder = (sourceIndex: number, targetIndex: number) => {
    if (sourceIndex === targetIndex) {
      return;
    }
    const conditions = state.data.data || [];
    // Remove the item from the source position
    const [movedItem] = conditions.splice(sourceIndex, 1);
    // Insert it at the destination position
    conditions.splice(targetIndex, 0, movedItem);
    onUpdate({
      ...(attribute?.settings || {}),
      [AttrSettingsFieldNames.editLogic]: conditions,
    });
  };

  const getContent = () => {
    const columns = [
      {
        key: 'when',
        header: 'When (condition met)',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        cell: ({ data }: iCellParams<iEditLogic>) => {
          return AttributeSettingsHelper.translateWhenLogicToHumanReadable(
            data.when || [],
          );
        },
      },
      {
        key: 'then',
        header: 'Then (actions)',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        cell: ({ data }: iCellParams<iEditLogic>) => {
          return AttributeSettingsHelper.translateThenLogicToHumanReadable(
            attribute,
            data.then,
          );
        },
      },
      ...(isDisabled
        ? []
        : [
            {
              key: 'btns',
              header: '',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              cell: ({ data }: iCellParams<iEditLogic>) => {
                return (
                  <div className={'text-right'}>
                    <AttributeDisplayLogicEditPopupBtn
                      className={'update-panel'}
                      attribute={attribute}
                      editLogic={data}
                      onSave={(logic) => {
                        handleSaved(logic as iEditLogicWithId);
                        onRefresh();
                      }}
                      renderBtn={(onClick) => {
                        return (
                          <IconButton
                            isSelected
                            testId={`update-icon-btn-${data.id}`}
                            label={'update'}
                            icon={Icons.EditFilledIcon}
                            onClick={onClick}
                          />
                        );
                      }}
                    />
                    {renderDeleteBtn({
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      deletingModel: data,
                      showDeletedMsg: false,
                      getDisplayName: () => `Attribute Edit Logic`,
                      deleteFnc: async () => {
                        onUpdate({
                          ...(attribute?.settings || {}),
                          [AttrSettingsFieldNames.editLogic]: (
                            state.data.data || []
                          ).filter((cond) => cond.id !== data.id),
                        });
                      },
                    })}
                  </div>
                );
              },
            },
          ]),
    ];
    return renderDataTable({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      columns,
      tblProps: {
        className: 'conditions-table',
        isRankable: isDisabled !== true,
        onRankEnd: (params) =>
          submitReorder(params.sourceIndex, params.destination?.index || 0),
        topRow: (
          <>
            <PageTitleWithCreateBtn
              createBtn={
                <AttributeDisplayLogicEditPopupBtn
                  className={'create-panel'}
                  attribute={attribute}
                  onSave={(logic) => {
                    handleSaved(logic as iEditLogicWithId);
                    onRefresh();
                  }}
                  renderBtn={(onClick) => {
                    return getCreateIconBtn({
                      className: 'size-sm',
                      onClick: onClick,
                    });
                  }}
                />
              }
              title={<h4>List of editing logics:</h4>}
            />
          </>
        ),
      },
    });
  };

  const getRankingMessage = () => {
    return (
      <SectionMessage title={'Conditions Match from top down'}>
        You can reorder the conditions below by drag and drop each row.
      </SectionMessage>
    );
  };

  return (
    <Wrapper
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      {getRankingMessage()}
      {getContent()}
    </Wrapper>
  );
};

export default AttributeDisplayLogic;
