import ProtectedPage from '../../layouts/ProtectedPage';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import iProduct from '../../types/product/iProduct';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import ProductService from '../../services/product/ProductService';
import ProductDetailsPanel from '../../components/product/ProductDetailsPanel';
import { URL_PRODUCTS } from '../../helpers/UrlMap';
import { InlineFlex } from '../../components/frameWork/Flex';

const ProductDetailsPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const [updatedProduct, setUpdatedProduct] = useState<iProduct | undefined>(
    undefined,
  );
  return (
    <ProtectedPage>
      <ComponentWithPreloadData<iProduct>
        emptyStateProps={{
          description: `Couldn't find requested Product with id: ${idStr}.`,
        }}
        updatedModel={updatedProduct}
        getDataFn={() =>
          ProductService.get(idStr, {
            include: 'CreatedBy,UpdatedBy,AttributeSet',
          })
        }
      >
        {({ data, getDataFn }) => (
          <ProductDetailsPanel
            product={data}
            onSaved={() => getDataFn().then((resp) => setUpdatedProduct(resp))}
            headerProps={{
              children: data.name,
              breadcrumbItems: [
                {
                  text: `Products`,
                  href: URL_PRODUCTS,
                },
                {
                  text: data.name,
                  component: () => (
                    <InlineFlex className={'align-items-center'}>
                      {data.name}
                    </InlineFlex>
                  ),
                },
              ],
            }}
          />
        )}
      </ComponentWithPreloadData>
    </ProtectedPage>
  );
};

export default ProductDetailsPage;
