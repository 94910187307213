import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AppHelper from '../../helpers/AppHelper';

export type AppState = {
  isProd?: boolean;
  locale: string;
  buildDetailsSelectedTab?: string;
  buildDetailsSubSelectedTab?: string;
};
/**
 * Initial State
 */
const initialState: AppState = {
  locale: AppHelper.getDefaultLocale(),
};

/**
 * Actions
 */
const actions = {
  setIsProd: (
    state: AppState,
    action: PayloadAction<Pick<AppState, 'isProd'>>,
  ) => ({
    ...initialState,
    ...state,
    isProd: action.payload.isProd,
  }),
  setLocale: (
    state: AppState,
    action: PayloadAction<Pick<AppState, 'locale'>>,
  ) => ({
    ...initialState,
    ...state,
    locale: action.payload.locale || initialState.locale,
  }),
  setBuildDetailsSelectedTab: (
    state: AppState,
    action: PayloadAction<Pick<AppState, 'buildDetailsSelectedTab'>>,
  ) => ({
    ...initialState,
    ...state,
    buildDetailsSelectedTab:
      action.payload.buildDetailsSelectedTab ||
      initialState.buildDetailsSelectedTab,
  }),
  removeBuildDetailsSelectedTab: (state: AppState) => ({
    ...initialState,
    ...state,
    buildDetailsSelectedTab: undefined,
  }),
  setBuildDetailsSubSelectedTab: (
    state: AppState,
    action: PayloadAction<Pick<AppState, 'buildDetailsSubSelectedTab'>>,
  ) => ({
    ...initialState,
    ...state,
    buildDetailsSubSelectedTab:
      action.payload.buildDetailsSubSelectedTab ||
      initialState.buildDetailsSubSelectedTab,
  }),
  removeBuildDetailsSubSelectedTab: (state: AppState) => ({
    ...initialState,
    ...state,
    buildDetailsSubSelectedTab: undefined,
  }),
};
/**
 * Slice
 */
const AppSlice = createSlice({
  name: 'App',
  initialState,
  reducers: actions,
});
/**
 * action
 */
export const {
  setIsProd,
  setLocale,
  setBuildDetailsSelectedTab,
  removeBuildDetailsSelectedTab,
  setBuildDetailsSubSelectedTab,
  removeBuildDetailsSubSelectedTab,
} = AppSlice.actions;
/**
 * reducer
 */
export default AppSlice.reducer;
