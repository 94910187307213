import { toast, ToastContainer } from 'react-toastify';
import React from 'react';

export const TOAST_TYPE_INFO = 'info';
export const TOAST_TYPE_SUCCESS = 'success';
export const TOAST_TYPE_WARNING = 'warn';
export const TOAST_TYPE_ERROR = 'error';
export const TOAST_TYPE_DEFAULT = 'default';

const showToast = (msg: React.ReactNode, type?: string, options = {}) => {
  const toastOptions = {
    theme: 'colored',
    type,
    ...options,
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return toast(msg, toastOptions);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const showApiError = (error: any) => {
  // console.error(error);
  return showToast(
    error.response?.data?.message || error.message || 'Error Occurred',
    TOAST_TYPE_ERROR,
  );
};

const getContainer = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

const Toaster = {
  showToast,
  showApiError,
  getContainer,
};

export default Toaster;
