import ProtectedRoute from './ProtectedRoute';
import React from 'react';
import {
  Content,
  Main,
  PageLayout,
  TopNavigation,
  LeftSidebar,
  RightSidebar,
  LeftSidebarProps,
  RightSidebarProps,
} from '../components/frameWork/PageLayout';
import TopNav from '../components/layout/TopNav';
import styled from 'styled-components';
import Tokens from '../components/frameWork/Tokens';
import PageHeader, { iPageHeader } from '../components/frameWork/PageHeader';

const PageWrapper = styled.div`
  height: 100%;
  [data-testid='content'] > [data-testid='main'] {
    padding-left: ${Tokens('space.200')};
    padding-right: ${Tokens('space.200')};
  }
`;

export type iProtectedPage = {
  children:
    | React.ReactNode
    | ((pageHeaderProps?: iPageHeader) => React.ReactNode);
  leftSideBar?: React.ReactNode;
  rightSideBar?: React.ReactNode;
  leftSideBarProps?: Omit<LeftSidebarProps, 'children'>;
  rightSideBarProps?: Omit<RightSidebarProps, 'children'>;
  pageHeaderProps?: iPageHeader;
};
const ProtectedPage = ({
  children,
  leftSideBar,
  leftSideBarProps,
  pageHeaderProps,
  rightSideBar,
  rightSideBarProps,
}: iProtectedPage) => {
  const getLeftSideBar = () => {
    if (!leftSideBar) {
      return null;
    }

    return (
      <LeftSidebar
        id={'page-left-menu'}
        {...leftSideBarProps}
        testId={'left-side-bar'}
      >
        {leftSideBar}
      </LeftSidebar>
    );
  };

  const getRightSideBar = () => {
    if (!rightSideBar) {
      return null;
    }

    return (
      <RightSidebar {...rightSideBarProps} testId={'right-side-bar'}>
        {rightSideBar}
      </RightSidebar>
    );
  };

  const getChildren = () => {
    if (typeof children === 'function') {
      return children(pageHeaderProps);
    }
    return (
      <>
        {pageHeaderProps && <PageHeader {...pageHeaderProps} />}
        {children}
      </>
    );
  };

  return (
    <ProtectedRoute>
      <PageWrapper data-testid="protected-page-wrapper">
        <PageLayout testId={'page-layout'}>
          <TopNavigation isFixed={false} testId={'top-nav'}>
            <TopNav />
          </TopNavigation>
          <Content testId="content">
            {getLeftSideBar()}
            <Main testId={'main'}>{getChildren()}</Main>
            {getRightSideBar()}
          </Content>
        </PageLayout>
      </PageWrapper>
    </ProtectedRoute>
  );
};

export default ProtectedPage;
