import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import iAttributeSet from '../../types/attribute/iAttributeSet';
import AttributeSetService from '../../services/attribute/AttributeSetService';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';
import { iOptionWithData } from '../frameWork/Select';
import Flex from '../frameWork/Flex';

export type iAttributeSetSelector = iPreloadAsyncSelectProps;
const AttributeSetSelector = (props: iAttributeSetSelector) => {
  return (
    <PreloadedAsyncSelector<iAttributeSet>
      placeholder={'Select a AttributeSet ...'}
      {...props}
      optionsUniqBy={(data: iAttributeSet) => data.id}
      formatOptionLabel={(data: iOptionWithData<iAttributeSet>) => (
        <Flex className={'gap-05 align-items-center'}>
          <div>{data.data.name}</div>
          <small>
            <i>{data.data.code || ' '}</i>
          </small>
        </Flex>
      )}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return AttributeSetService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(searchTxtStr === ''
              ? {}
              : {
                  [OP_OR]: [
                    { name: { [OP_LIKE]: `%${searchTxtStr}%` } },
                    { code: { [OP_LIKE]: `%${searchTxtStr}%` } },
                  ],
                }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        AttributeSetService.getAll({
          where: JSON.stringify({ code: values }),
          perPage: values.length,
        })
      }
      renderOption={(data) => {
        return {
          label: data.name,
          value: data.code,
          data,
        };
      }}
    />
  );
};

export default AttributeSetSelector;
