import iBuild from '../../../../../types/build/iBuild';
import styled from 'styled-components';

type iBuildDetailsContractPlumbing = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const Wrapper = styled.div``;
const BuildDetailsContractPlumbing = ({
  build,
}: iBuildDetailsContractPlumbing) => {
  return <Wrapper>Plumbing for {build.id} is coming soon.</Wrapper>;
};

export default BuildDetailsContractPlumbing;
