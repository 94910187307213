import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import iBuildStyle from '../../types/build/iBuildStyle';
import Tabs from '../frameWork/Tabs';
import InlineEdit from '../frameWork/InlineEdit';
import { useState } from 'react';
import BuildStyleService from '../../services/build/BuildStyleService';
import Toaster, { TOAST_TYPE_ERROR } from '../common/Toaster';
import InlineTextArea from '../frameWork/InlineTextArea';
import Flex from '../frameWork/Flex';
import { iStateBeforeReload } from '../common/ComponentWithPreloadData';
import StringHelper from '../../helpers/StringHelper';
import { getErrorMsgStr, hasError, iErrorMap } from '../form/FormError';
import AttachmentPreviewPanel from '../asset/AttachmentPreviewPanel';
import { AssetTypes } from '../../types/asset/iAsset';
import EntityNames from '../../helpers/EntityNames';
import LeftAndRightPanel from '../layout/LeftAndRightPanel';
import Toggle from '../frameWork/Toggle';
import BuildAreaList from '../buildArea/BuildAreaList';
import BuildList from '../build/BuildList';
import BuildStylePriceList from '../buildStylePrice/BuildStylePriceList';
import FormField from '../frameWork/FormField';
import UtilsService from '../../services/UtilsService';

export type iBuildStyleDetailsPanel = iComponentWithPageHeader & {
  buildStyle: iBuildStyle;
  isDisabled?: boolean;
  stateBeforeReload?: iStateBeforeReload;
  testId?: string;
  onSaved?: (saved: iBuildStyle) => void;
  className?: string;
};

const BuildStyleDetailsPanel = ({
  headerProps,
  buildStyle,
  onSaved,
  isDisabled,
  testId,
  stateBeforeReload,
  className,
}: iBuildStyleDetailsPanel) => {
  const testIdStr = `${testId || ''}-buildStyle-details`;
  const [isSaving, setIsSaving] = useState(false);
  const [errorMap, setErrorMap] = useState<iErrorMap>({});
  const [previousSate, setPreviousSate] = stateBeforeReload || [];
  const [selectedTabKey, setSelectedTabKey] = useState(
    previousSate?.selectedTabKey || 'photos',
  );
  const handleOnChange = (
    fieldName: string,
    newValue: string | null | boolean,
  ): void => {
    if (
      !(fieldName in buildStyle) ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      `${buildStyle[fieldName] || ''}`.trim() === `${newValue || ''}`.trim(0)
    ) {
      return;
    }

    setIsSaving(true);
    BuildStyleService.update(buildStyle.id, {
      [fieldName]: newValue,
    })
      .then((resp) => {
        onSaved && onSaved(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };
  return (
    <ComponentWithPageHeader headerProps={headerProps}>
      <LeftAndRightPanel
        testId={testIdStr}
        className={`build-style-details ${className || ''}`}
        rightPanel={
          <>
            <FormField
              render={() =>
                buildStyle.LatestPrice?.isActive === true ? (
                  <div
                    style={{ fontSize: '1.7rem', padding: '8px 0px 12px 8px' }}
                  >
                    {UtilsService.formatIntoCurrency(
                      buildStyle.LatestPrice?.price || null,
                    )}
                  </div>
                ) : null
              }
              label={'Latest Price'}
            />

            <InlineEdit
              label={'Name'}
              testId={`${testIdStr}-name`}
              placeHolder={'The name of the Style...'}
              readViewFitContainerWidth
              isDisabled={isSaving || isDisabled}
              editViewProps={{
                isInvalid: hasError({ error: errorMap, fieldName: 'name' }),
                errorMsg: getErrorMsgStr({
                  error: errorMap,
                  fieldName: 'name',
                }),
              }}
              isEditing={
                hasError({ error: errorMap, fieldName: 'name' })
                  ? true
                  : undefined
              }
              onCancel={() => setErrorMap({})}
              isRequired
              onConfirm={(newValue) => {
                const newVal = `${newValue || ''}`.trim();
                setErrorMap(newVal === '' ? { name: 'Name is required.' } : {});
                if (newVal === '') {
                  return;
                }
                handleOnChange('name', newValue);
              }}
              value={buildStyle.name}
            />

            <Toggle
              testId={`${testIdStr}-isCustomised`}
              label={'Custom Design?'}
              isChecked={buildStyle.isCustomised}
              onChange={() =>
                handleOnChange(
                  'isCustomised',
                  !(buildStyle.isCustomised === true),
                )
              }
            />

            <InlineTextArea
              label={'Description'}
              testId={`${testIdStr}-description`}
              readViewStyle={{
                maxHeight: '10rem',
              }}
              placeHolder={'Some description about this style...'}
              readViewFitContainerWidth
              isDisabled={isSaving || isDisabled}
              onConfirm={(newValue) => handleOnChange('description', newValue)}
              value={buildStyle.description || ''}
            />

            <Flex className={'flex-wrap'}>
              {[
                {
                  label: 'Bedrooms',
                  placeHolder: 'No. of bedrooms',
                  fieldName: 'noOfBeds',
                  width: '33%',
                  nullWhenBlank: true,
                  isValid: (newValue: string) => {
                    if (!StringHelper.isNumeric(`${newValue || ''}`)) {
                      return 'Numeric only';
                    }
                    return true;
                  },
                },
                {
                  label: 'Bathrooms',
                  placeHolder: 'No. of bathrooms',
                  fieldName: 'noOfBaths',
                  width: '33%',
                  nullWhenBlank: true,
                  isValid: (newValue: string) => {
                    if (!StringHelper.isNumeric(`${newValue || ''}`)) {
                      return 'Numeric only';
                    }
                    return true;
                  },
                },
                {
                  label: 'Car Spaces',
                  placeHolder: 'No. of Car Spaces',
                  fieldName: 'noOfCars',
                  width: '33%',
                  nullWhenBlank: true,
                  isValid: (newValue: string) => {
                    if (!StringHelper.isNumeric(`${newValue || ''}`)) {
                      return 'Numeric only';
                    }
                    return true;
                  },
                },
                {
                  label: (
                    <>
                      Floor Area
                      <small>
                        ( m<sup>2</sup> )
                      </small>
                    </>
                  ),
                  placeHolder: 'Floor area',
                  fieldName: 'floorArea',
                  width: '100%',
                  nullWhenBlank: true,
                  isValid: (newValue: string) => {
                    if (!StringHelper.isNumeric(`${newValue || ''}`)) {
                      return 'Numeric only';
                    }
                    return true;
                  },
                },
                {
                  label: (
                    <>
                      House Width
                      <small>(m)</small>
                    </>
                  ),
                  placeHolder: 'The width of the house',
                  fieldName: 'houseWidth',
                  width: '50%',
                  nullWhenBlank: true,
                  isValid: (newValue: string) => {
                    if (!StringHelper.isNumeric(`${newValue || ''}`)) {
                      return 'Numeric only';
                    }
                    return true;
                  },
                },
                {
                  label: (
                    <>
                      House Length
                      <small>(m)</small>
                    </>
                  ),
                  placeHolder: 'The length of the house',
                  fieldName: 'houseLength',
                  width: '50%',
                  nullWhenBlank: true,
                  isValid: (newValue: string) => {
                    if (!StringHelper.isNumeric(`${newValue || ''}`)) {
                      return 'Numeric only';
                    }
                    return true;
                  },
                },
                {
                  label: (
                    <>
                      Min Block Width
                      <small>(m)</small>
                    </>
                  ),
                  placeHolder: 'The minimum block width',
                  fieldName: 'minBlockWidth',
                  width: '50%',
                  nullWhenBlank: true,
                  isValid: (newValue: string) => {
                    if (!StringHelper.isNumeric(`${newValue || ''}`)) {
                      return 'Numeric only';
                    }
                    return true;
                  },
                },
                {
                  label: (
                    <>
                      Min Block Depth
                      <small>(m)</small>
                    </>
                  ),
                  placeHolder: 'The minimum block depth',
                  fieldName: 'minBlockDepth',
                  width: '50%',
                  nullWhenBlank: true,
                  isValid: (newValue: string) => {
                    if (!StringHelper.isNumeric(`${newValue || ''}`)) {
                      return 'Numeric only';
                    }
                    return true;
                  },
                },
              ].map((item) => {
                const gotError = hasError({
                  error: errorMap,
                  fieldName: item.fieldName,
                });
                return (
                  <div style={{ width: item.width }} key={item.fieldName}>
                    <InlineEdit
                      editViewProps={{
                        isInvalid: gotError,
                        errorMsg: getErrorMsgStr({
                          error: errorMap,
                          fieldName: item.fieldName,
                        }),
                      }}
                      label={item.label}
                      readViewFitContainerWidth
                      placeHolder={item.placeHolder}
                      testId={`${testIdStr}-${item.fieldName}`}
                      isDisabled={isSaving || isDisabled}
                      isEditing={gotError === true ? true : undefined}
                      onCancel={() => setErrorMap({})}
                      onConfirm={(newValue) => {
                        const newVal = `${newValue || ''}`.trim();
                        const isValid = item.isValid
                          ? item.isValid(newVal)
                          : true;
                        setErrorMap(
                          isValid !== true ? { [item.fieldName]: isValid } : {},
                        );
                        if (isValid !== true) {
                          Toaster.showToast(
                            <>
                              <u>{item.label}</u> is {isValid}
                            </>,
                            TOAST_TYPE_ERROR,
                          );
                          return;
                        }
                        handleOnChange(
                          item.fieldName,
                          newVal === '' && item.nullWhenBlank === true
                            ? null
                            : newVal,
                        );
                      }}
                      value={`${
                        item.fieldName in buildStyle
                          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-expect-error
                            buildStyle[item.fieldName] || ''
                          : ''
                      }`}
                    />
                  </div>
                );
              })}
            </Flex>
          </>
        }
      >
        <Tabs
          testId={`${testIdStr}-tabs`}
          appearance={'tabs'}
          activeKey={selectedTabKey}
          onSelect={(key) => {
            setSelectedTabKey(key);
            setPreviousSate && setPreviousSate({ selectedTabKey: key });
          }}
          className={'left-panel'}
          tabs={[
            {
              eventKey: 'photos',
              title: 'Photos',
              tabContent: (
                <AttachmentPreviewPanel
                  entityName={EntityNames.BuildStyle}
                  entityId={buildStyle.id}
                  types={[AssetTypes.HOUSE_STYLE_PHOTOS]}
                  setNewAssetToPublic={true}
                  allowSettingExistingAssetPublic={true}
                  allowCreate
                  allowDelete
                />
              ),
            },
            {
              eventKey: 'prices',
              title: 'Prices',
              tabContent: (
                <BuildStylePriceList
                  styleId={buildStyle.id}
                  allowDelete
                  allowCreate
                  onSaved={() => onSaved && onSaved(buildStyle)}
                  headerProps={{
                    children: <h5>Prices</h5>,
                  }}
                />
              ),
            },
            {
              eventKey: 'floorPlans',
              title: 'Floor Plans',
              tabContent: (
                <BuildAreaList
                  headerProps={{
                    children: <h5>Areas</h5>,
                  }}
                  entityName={EntityNames.BuildStyle}
                  entityId={buildStyle.id}
                  pageSize={9999999}
                  allowDelete
                  allowCreate
                  onSaved={() => onSaved && onSaved(buildStyle)}
                />
              ),
            },
          ]}
        />
      </LeftAndRightPanel>
      <Tabs
        tabs={[
          {
            eventKey: 'builds',
            title: 'Build Jobs',
            tabContent: (
              <BuildList
                styleId={buildStyle.id}
                allowDelete={false}
                allowCreate={false}
                showHeader={false}
              />
            ),
          },
          {
            eventKey: 'history',
            title: 'History',
            tabContent: <div>Change history about this style.</div>,
          },
        ]}
      />
    </ComponentWithPageHeader>
  );
};

export default BuildStyleDetailsPanel;
