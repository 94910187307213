import ServiceHelper from '../helper/ServiceHelper';
import iBuildArea from '../../types/build/iBuildArea';

const endPoint = '/buildArea';
const BuildAreaService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildArea>(endPoint),
  get: ServiceHelper.getById<iBuildArea>(endPoint),
  create: ServiceHelper.create<iBuildArea>(endPoint),
  update: ServiceHelper.updateById<iBuildArea>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildArea>(endPoint),
};

export default BuildAreaService;
