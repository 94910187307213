import React from 'react';
import styled from 'styled-components';
import Icons from '../frameWork/Icons';
import Tooltip from '../frameWork/Tooltip';
import Tokens from '../frameWork/Tokens';

const Wrapper = styled.div`
  display: flex;
  gap: ${Tokens('space.100')};
  .pagination-details {
    display: flex;
    gap: 3px;
    .count-from,
    .count-to,
    .count-total {
        font-weight: ${Tokens('font.weight.medium')};
    }
  }
}
`;

export type iPaginationDetail = {
  testId?: string;
  className?: string;
  from?: number;
  to?: number;
  total?: number;
  onRefreshResults?: () => void;
  hideWhenEmptyTotal?: boolean;
};
const PaginationDetails = ({
  from = 1,
  to = 10,
  total = 0,
  onRefreshResults,
  className,
  testId = 'p-details',
  hideWhenEmptyTotal = true,
}: iPaginationDetail) => {
  const getRefreshBtn = () => {
    if (!onRefreshResults) {
      return null;
    }

    return (
      <div
        className={'refresh-btn cursor-pointer'}
        onClick={onRefreshResults}
        data-testid={`refresh-data-btn-${testId}-wrapper`}
      >
        <Tooltip content="Refresh results" position={'right'}>
          <Icons.RefreshIcon
            label="refresh"
            size={'small'}
            testId={`refresh-data-btn-${testId}-icon`}
          />
        </Tooltip>
      </div>
    );
  };

  if (total <= 0 && hideWhenEmptyTotal === true) {
    return null;
  }

  return (
    <Wrapper
      className={`pagination-detail-wrapper ${className || ''} align-items-center`}
      data-testid={testId}
    >
      <div className={'pagination-details'} data-testid={`details-${testId}`}>
        <span className={'count-from'} data-testid={`from-${testId}`}>
          {from > total ? total : from}
        </span>
        <span className={'count-separator'} data-testid={`separator-${testId}`}>
          -
        </span>
        <span className={'count-to'} data-testid={`to-${testId}`}>
          {to > total ? total : to}
        </span>
        <span className={'count-of'} data-testid={`of-${testId}`}>
          of
        </span>
        <span className={'count-total'} data-testid={`total-${testId}`}>
          {total}
        </span>
      </div>
      {getRefreshBtn()}
    </Wrapper>
  );
};

export default PaginationDetails;
