import iBuild from '../../../../../types/build/iBuild';
import styled from 'styled-components';

type iBuildDetailsContractAppliances = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const Wrapper = styled.div``;
const BuildDetailsContractAppliances = ({
  build,
}: iBuildDetailsContractAppliances) => {
  return <Wrapper>Appliances for {build.id} is coming soon.</Wrapper>;
};

export default BuildDetailsContractAppliances;
