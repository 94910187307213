import iBuild from '../../../../../types/build/iBuild';
import styled from 'styled-components';
import SectionDiv from '../../../../frameWork/SectionDiv';

type iBuildDetailsContractPacks = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const Wrapper = styled.div``;

const BuildDetailsContractPacks = ({ build }: iBuildDetailsContractPacks) => {
  return (
    <Wrapper>
      <SectionDiv>Packs for {build.id} is coming soon.</SectionDiv>
    </Wrapper>
  );
};

export default BuildDetailsContractPacks;
