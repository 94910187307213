import Icons from '../../../frameWork/Icons';
import iBuild from '../../../../types/build/iBuild';
import BuildDetailsColoursOverview from './Components/BuildDetailsColoursOverview';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';
import BuildDetailsColoursInternalCabinets from './Components/BuildDetailsColoursInternalCabinets';

export enum BuildDetailsColoursKeys {
  OVERVIEW = 'Summary',
  CABINETS = 'Cabinets',
  NICHE = 'Niche',
  WALL_TILES = 'Wall Tiles',
  FLOORING = 'Flooring',
  EXTERNAL = 'External',
  NOTES = 'Notes',
  CHANGES = 'Changes',
  VARIATIONS = 'Variations',
}

type iGetBuildDetailsColoursMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const getMenuItemsMap = ({
  build,
  onBuildUpdated,
  isDisabled = false,
}: iGetBuildDetailsColoursMenuItemsMap): iMenuItemsMap => {
  return {
    [BuildDetailsColoursKeys.OVERVIEW]: {
      name: BuildDetailsColoursKeys.OVERVIEW,
      icon: <Icons.OverviewIcon label={BuildDetailsColoursKeys.OVERVIEW} />,
      component: (
        <BuildDetailsColoursOverview
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsColoursKeys.CABINETS]: {
      name: BuildDetailsColoursKeys.CABINETS,
      icon: <Icons.QueuesIcon label={BuildDetailsColoursKeys.CABINETS} />,
      component: (
        <BuildDetailsColoursInternalCabinets
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsColoursKeys.WALL_TILES]: {
      name: BuildDetailsColoursKeys.WALL_TILES,
      icon: (
        <Icons.AppSwitcherIcon label={BuildDetailsColoursKeys.WALL_TILES} />
      ),
      component: <div>{BuildDetailsColoursKeys.WALL_TILES}</div>,
    },
    [BuildDetailsColoursKeys.FLOORING]: {
      name: BuildDetailsColoursKeys.FLOORING,
      icon: (
        <Icons.BitbucketSourceIcon label={BuildDetailsColoursKeys.FLOORING} />
      ),
      component: <div>{BuildDetailsColoursKeys.FLOORING}</div>,
    },
    [BuildDetailsColoursKeys.NICHE]: {
      name: BuildDetailsColoursKeys.NICHE,
      icon: (
        <Icons.MediaServicesFilterIcon label={BuildDetailsColoursKeys.NICHE} />
      ),
      component: <div>{BuildDetailsColoursKeys.NICHE}</div>,
    },
    [BuildDetailsColoursKeys.EXTERNAL]: {
      name: BuildDetailsColoursKeys.EXTERNAL,
      icon: (
        <Icons.BitbucketReposIcon label={BuildDetailsColoursKeys.EXTERNAL} />
      ),
      component: <div>{BuildDetailsColoursKeys.EXTERNAL}</div>,
    },
    [BuildDetailsColoursKeys.NOTES]: {
      name: BuildDetailsColoursKeys.NOTES,
      icon: (
        <Icons.MediaServicesAnnotateIcon
          label={BuildDetailsColoursKeys.NOTES}
        />
      ),
      component: <div>{BuildDetailsColoursKeys.NOTES}</div>,
    },
    [BuildDetailsColoursKeys.CHANGES]: {
      name: BuildDetailsColoursKeys.CHANGES,
      icon: (
        <Icons.EditorNumberListIcon label={BuildDetailsColoursKeys.CHANGES} />
      ),
      component: <div>{BuildDetailsColoursKeys.CHANGES}</div>,
    },
    [BuildDetailsColoursKeys.VARIATIONS]: {
      name: BuildDetailsColoursKeys.VARIATIONS,
      icon: (
        <Icons.BitbucketPullrequestsIcon
          label={BuildDetailsColoursKeys.VARIATIONS}
        />
      ),
      component: <div>{BuildDetailsColoursKeys.VARIATIONS}</div>,
    },
  };
};

const BuildDetailsColoursHelper = {
  getMenuItemsMap,
};
export default BuildDetailsColoursHelper;
