import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns, iPopupModal } from '../common/PopupModal';
import FileUploader from './FileUploader';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import AttachmentService from '../../services/attachment/AttachmentService';
import Toaster from '../common/Toaster';
import {
  HEADER_NAME_ASSET_IS_PUBLIC,
  HEADER_NAME_ASSET_TYPE,
  iConfigParams,
} from '../../services/AppService';
import iAttachment from '../../types/asset/iAttachment';
import EntityNames from '../../helpers/EntityNames';
import { AssetTypes } from '../../types/asset/iAsset';
import Flex from '../frameWork/Flex';
import AssetTypeSelector from './AssetTypeSelector';
import { iOption } from '../frameWork/Select';

const Wrapper = styled.div`
  .uploader-wrapper {
    height: 14rem;
    .mask {
      height: 17.25rem;
    }
  }
  margin-bottom: 3rem;
`;
export type iAttachmentUploadPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  entityName: EntityNames;
  entityId: string;
  assetTypes?: AssetTypes[];
  isAssetPublic?: boolean;
  onUploaded: (attachments: iAttachment[]) => void;
  modalProps?: Omit<iPopupModal, 'titleId' | 'isOpen' | 'children'>;
};
const AttachmentUploadPopupBtn = ({
  onUploaded,
  modalProps,
  entityName,
  entityId,
  assetTypes = [],
  isAssetPublic,
  ...props
}: iAttachmentUploadPopupBtn) => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [selectedAssetType, setSelectedAssetType] =
    React.useState<AssetTypes | null>(null);

  useEffect(() => {
    const types = assetTypes || [];
    if (types.length > 0) {
      setSelectedAssetType(types[0]);
    }
  }, [assetTypes]);

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isUploading === true) {
      return;
    }
    setModelShowing(false);
  };

  const onFilesSelected = (
    files: File[],
    setModelShowing: iSetShowingModalFn,
  ) => {
    setIsUploading(true);
    Promise.all(
      files.map((file: File) => {
        const data = new FormData();
        data.append('entityName', entityName);
        data.append('entityId', entityId);

        const headers: iConfigParams = {};
        if (selectedAssetType && `${selectedAssetType || ''}`.trim() !== '') {
          headers[HEADER_NAME_ASSET_TYPE] = `${selectedAssetType || ''}`.trim();
        }
        if (isAssetPublic === true) {
          headers[HEADER_NAME_ASSET_IS_PUBLIC] = 'true';
        }
        data.append('file', file);
        return AttachmentService.create(data, { headers });
      }),
    )
      .then((resp) => {
        setIsUploading(false);
        setModelShowing(false);
        onUploaded(resp);
      })
      .catch((err) => {
        setIsUploading(false);
        Toaster.showApiError(err);
      });
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AttachmentUploadPopupBtn'}
      modalProps={(setModelShowing) => ({
        width: '1000px',
        title:
          isUploading === true ? (
            `Don't close this window until it's finished`
          ) : (
            <Flex className={'gap-1 align-items-base'}>
              <div>Uploading new assets</div>
              <AssetTypeSelector
                testId={'AssetTypeSelector'}
                value={selectedAssetType}
                isDisabled={(assetTypes || []).length === 1}
                onChange={(selected: iOption) => {
                  setSelectedAssetType(
                    selected?.value
                      ? (`${selected?.value}` as AssetTypes)
                      : null,
                  );
                }}
              />
            </Flex>
          ),
        shouldScrollInViewport: true,
        ...modalProps,
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            btnText: 'Done',
            isLoading: isUploading,
            appearance: 'primary',
            iconBefore: () => null,
            onClick: () => handleClose(setModelShowing),
          },
        }),
        body: (
          <Wrapper>
            {selectedAssetType && (
              <FileUploader
                isLoading={isUploading}
                className={'uploader-wrapper'}
                onFilesSelected={(files) =>
                  onFilesSelected(files, setModelShowing)
                }
              />
            )}
          </Wrapper>
        ),
      })}
    />
  );
};

export default AttachmentUploadPopupBtn;
