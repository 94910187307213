import {
  default as Origin,
  AvatarPropTypes,
  AvatarItem as OriginAvatarItem,
} from '@atlaskit/avatar';

export type iAvatar = AvatarPropTypes;
const Avatar = Origin;

export const AvatarItem = OriginAvatarItem;

export default Avatar;
