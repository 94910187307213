import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsVariancePanel = {
  build: iBuild;
};
const BuildDetailsVariancePanel = ({ build }: iBuildDetailsVariancePanel) => {
  return <Wrapper>Variance: {build.id}</Wrapper>;
};

export default BuildDetailsVariancePanel;
