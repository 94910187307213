import iBuild from '../../../../../types/build/iBuild';

type iBuildDetailsPlanOverview = {
  build: iBuild;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};

const BuildDetailsPlanOverview = ({ build }: iBuildDetailsPlanOverview) => {
  return <div>Overview: {build.id}</div>;
};

export default BuildDetailsPlanOverview;
