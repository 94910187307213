import ServiceHelper from '../helper/ServiceHelper';
import iContactCompany from '../../types/contact/iContactCompany';
import * as _ from 'lodash';

const endPoint = '/contactCompany';

const getHumanReadableType = (contactCompanyType: string) => {
  return contactCompanyType
    .split('_')
    .map((name) => _.capitalize(name))
    .join(' ');
};

const ContactCompanyService = {
  getAll: ServiceHelper.getPaginatedAll<iContactCompany>(endPoint),
  get: ServiceHelper.getById<iContactCompany>(endPoint),
  create: ServiceHelper.create<iContactCompany>(endPoint),
  update: ServiceHelper.updateById<iContactCompany>(endPoint),
  deactivate: ServiceHelper.deactivateById<iContactCompany>(endPoint),
  getHumanReadableType,
};

export default ContactCompanyService;
