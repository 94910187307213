import iBaseType from '../iBaseType';

export enum AttributeSetCodes {
  CavitySlider = 'cavity_slider',
  InternalDoor = 'internal_door',
  FrontDoor = 'front_door',
  BackDoor = 'back_door',
  GarageDoor = 'garage_door',
  Window = 'window',
  Cabinet = 'cabinet',
  Concrete = 'concrete',
  PowerPoint = 'power_point',
  ExteriorLight = 'exterior_light',
  Flooring = 'flooring',
  Pier = 'pier',
  WaterTank = 'water_tank',
  Fencing = 'fencing',
  Niche = 'niche',
  WallTiles = 'wall_tiles',
  Bathroom = 'bathroom',
}

type iAttributeSet = iBaseType & {
  name: string;
  code: string;
  description: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings: any | null;
};

export default iAttributeSet;
