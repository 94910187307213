import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import CityCouncilService from '../../services/misc/CityCouncilService';
import iCityCouncil from '../../types/misc/iCityCouncil';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import SearchTextField from '../frameWork/SearchTextField';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';

type iCityCouncilList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};

const CityCouncilList = ({ allowDelete, headerProps }: iCityCouncilList) => {
  const {
    renderDataTable,
    renderDeleteBtn,
    renderEntityEditPopBtn,
    onSetFilter,
  } = useListCrudHook<iCityCouncil>({
    sort: `name:ASC`,
    getFn: (params) =>
      CityCouncilService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getEditBtn = (cityCouncil?: iCityCouncil) => {
    return renderEntityEditPopBtn<iCityCouncil>({
      editingEntity: cityCouncil,
      entityName: 'City Council / Shire',
      createFn: (data) => CityCouncilService.create(data),
      updateFn: (id, data) => CityCouncilService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'CityCouncil-name',
        },
        {
          fieldName: 'description',
          label: 'Description',
          isDisabled,
          value: entity?.description || '',
          testId: 'CityCouncil-description',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iCityCouncil>[] => [
    {
      key: 'name',
      header: 'Name',
      cell: ({ data }: iCellParams<iCityCouncil>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'description',
      header: 'Description',
      cell: ({ data }: iCellParams<iCityCouncil>) =>
        `${data.description || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iCityCouncil>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iCityCouncil>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      CityCouncilService.deactivate(data.id),
                    getDisplayName: (CityCouncil) => CityCouncil.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
        actions: (
          <SearchTextField
            testId={'cityCouncil-list-search'}
            onSearch={(searchText: string) => {
              onSetFilter({
                [OP_OR]: [
                  { name: { [OP_LIKE]: `%${searchText}%` } },
                  { description: { [OP_LIKE]: `%${searchText}%` } },
                ],
              });
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: 'CityCouncil-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default CityCouncilList;
