import styled from 'styled-components';
import EntityNames from '../../../helpers/EntityNames';
import iAttribute from '../../../types/attribute/iAttribute';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import AttributeValueService from '../../../services/attribute/AttributeValueService';
import Spinner from '../../frameWork/Spinner';
import Toaster from '../../common/Toaster';
import AttributeSettingsHelper from './AttributeSettingsHelper';
import iAttributeValue from '../../../types/attribute/iAttributeValue';
import AttributeInputHelper from './AttributeInputHelper';
import { iAttributeItemWithValueMap } from '../../../types/attribute/iAttributeItem';

const Wrapper = styled.div``;

type iAttributeValueInputWrapperChildren = {
  isRequiredByAttrCondition: boolean;
  valueByAttrCondition: string | null;
  limitedOptionValues: string[] | null;
};
type iAttributeValueInputWrapper = {
  entityId: string;
  entityName: EntityNames;
  attribute: iAttribute;
  attributeItem?: iAttributeItemWithValueMap;
  children: (props: iAttributeValueInputWrapperChildren) => ReactNode;
};
const AttributeValueInputWrapper = ({
  children,
  entityId,
  entityName,
  attribute,
  attributeItem,
}: iAttributeValueInputWrapper) => {
  const [isLoading, setIsLoading] = useState(false);
  const editingLogics = AttributeSettingsHelper.getEditLogic(attribute);
  const [attValuesInDB, setAttValuesInDB] = useState<iAttributeValue[]>([]);

  const getData = useCallback(async () => {
    const relatedAttributes: iAttribute[] = editingLogics.reduce(
      (arr: iAttribute[], editingLogic) => {
        return [
          ...arr,
          ...((editingLogic.when || [])
            .map((when) => when.attribute)
            .filter(
              (whenAttribute) =>
                whenAttribute && `${whenAttribute?.id || ''}`.trim() !== '',
            ) as iAttribute[]),
        ];
      },
      [],
    );
    if (relatedAttributes.length <= 0) {
      return;
    }

    return AttributeValueService.getAllFromEntity(entityName, entityId, {
      where: JSON.stringify({
        isActive: true,
        attributeId: relatedAttributes.map(
          (relatedAttribute) => relatedAttribute.id,
        ),
      }),
      perPage: 99999999,
    });
  }, []);

  useEffect(() => {
    let isCanceled = false;
    setIsLoading(true);
    getData()
      .then((resp) => {
        if (isCanceled || !resp) {
          return;
        }
        setAttValuesInDB(resp.data || []);
      })
      .catch((err) => {
        if (isCanceled) {
          return;
        }
        Toaster.showApiError(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, [entityName, entityId, getData]);

  const getContent = () => {
    if (isLoading) {
      return <Spinner />;
    }
    return children({
      isRequiredByAttrCondition:
        AttributeInputHelper.checkAttributeIsRequiredByEditLogic(
          attribute,
          attValuesInDB,
          attributeItem,
        ),
      valueByAttrCondition: AttributeInputHelper.getAttrValueFromEditLogic(
        attribute,
        attValuesInDB,
        attributeItem,
      ),
      limitedOptionValues:
        AttributeInputHelper.getLimitedOptionValuesFromEditLogic(
          attribute,
          attValuesInDB,
          attributeItem,
        ),
    });
  };
  return <Wrapper>{getContent()}</Wrapper>;
};

export default AttributeValueInputWrapper;
