import {
  AtlassianNavigation,
  ProductHome as OriginProductHome,
  Profile as OriginProfile,
  PrimaryButton as OriginPrimaryButton,
  PrimaryDropdownButton as OriginPrimaryDropdownButton,
} from '@atlaskit/atlassian-navigation';

export const ProductHome = OriginProductHome;
export const PrimaryDropdownButton = OriginPrimaryDropdownButton;

export const PrimaryButton = OriginPrimaryButton;

export const Profile = OriginProfile;

const Navigation = AtlassianNavigation;

export default Navigation;
