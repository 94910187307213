import styled from 'styled-components';
import iAddress from '../../types/system/iAddress';
import InlineEdit from '../frameWork/InlineEdit';
import tokens from '../frameWork/Tokens';
import Flex from '../frameWork/Flex';
import { useState } from 'react';
import AddressService from '../../services/system/AddressService';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';
import { getErrorMsgStr, hasError } from '../form/FormError';
import TextField from '../frameWork/TextField';
import { iUseAsForm } from '../form/EntityEditPanel';

const Wrapper = styled.div`
  label {
    font-size: ${tokens('font.size.050')};
    font-weight: ${tokens('font.weight.regular')};
  }
  form {
    width: 100% !important;
  }
`;

type iAddressEditPanel = {
  testId?: string;
  className?: string;
  address?: iAddress | null;
  showName?: boolean;
  onSaved?: (saved: iAddress, isCreated: boolean) => void;
  isDisabled?: boolean;
  useAsForm?: iUseAsForm;
};
const AddressEditPanel = ({
  className,
  address,
  testId,
  onSaved,
  showName = false,
  isDisabled,
  useAsForm,
}: iAddressEditPanel) => {
  const testIdStr = `addr-edit-panel-${testId || ''}`;
  const [isSaving, setIsSaving] = useState(false);

  const handleUpdate = (fieldName: string, newValue?: string) => {
    if (useAsForm || newValue === undefined) {
      return;
    }
    const addressId = `${address?.id || ''}`.trim();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const addr: any = address || {};
    const newVal = fieldName in addr ? addr[fieldName] : null;
    if (newVal === newValue) {
      return;
    }

    setIsSaving(true);
    const saveFn = () =>
      addressId === ''
        ? AddressService.create({
            [fieldName]: newValue,
          })
        : AddressService.update(addressId, {
            [fieldName]: newValue,
          });

    saveFn()
      .then((resp) => {
        setIsSaving(false);
        Toaster.showToast(
          `Address ${addressId === '' ? 'Created' : 'Updated'}`,
          TOAST_TYPE_SUCCESS,
        );
        onSaved && onSaved(resp, addressId === '');
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showToast(err);
      });
  };

  type iFieldInput = {
    fieldName: string;
    label: string;
    value: string;
    isRequired: boolean;
    width: string;
    placeholder: string;
  };
  const getFieldInput = (item: iFieldInput) => {
    if (useAsForm) {
      return (
        <TextField
          placeholder={item.placeholder}
          testId={`${testIdStr}-${item.fieldName}`}
          isDisabled={isSaving || isDisabled}
          value={item.value}
          label={item.label}
          isRequired={item.isRequired}
          onChange={(event) => {
            useAsForm?.onFieldChange(
              item.fieldName,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              event.target.value || '',
            );
          }}
          isInvalid={hasError({
            fieldName: item.fieldName,
            error: useAsForm.errorMap,
          })}
          errorMsg={getErrorMsgStr({
            fieldName: item.fieldName,
            error: useAsForm.errorMap,
          })}
        />
      );
    }
    return (
      <InlineEdit
        placeHolder={item.placeholder}
        testId={`${testIdStr}-${item.fieldName}`}
        isDisabled={isSaving || isDisabled}
        value={item.value}
        label={item.label}
        onConfirm={(newValue) => handleUpdate(item.fieldName, newValue)}
        isRequired={item.isRequired}
        readViewFitContainerWidth
      />
    );
  };

  return (
    <Wrapper
      className={`address-edit-panel ${className || ''}`}
      data-testid={`${testIdStr}-wrapper`}
    >
      <Flex className="flex-wrap gap-05">
        {[
          ...(showName === true
            ? [
                {
                  fieldName: 'name',
                  label: 'Address Name',
                  value: address?.name || '',
                  isRequired: false,
                  width: '100%',
                  placeholder: 'A special name for this address (Optional).',
                },
              ]
            : []),
          {
            fieldName: 'street',
            label: 'Street',
            value: address?.street || '',
            isRequired: true,
            width: '100%',
            placeholder: 'Street name or lot number',
          },
          {
            fieldName: 'suburb',
            label: 'Suburb',
            value: address?.suburb || '',
            isRequired: true,
            width: '35%',
            placeholder: 'Suburb or City',
          },
          {
            fieldName: 'state',
            label: 'State',
            value: address?.state || '',
            isRequired: true,
            width: '30%',
            placeholder: 'State or Territory',
          },
          {
            fieldName: 'postcode',
            label: 'Postcode',
            value: address?.postcode || '',
            isRequired: true,
            width: '30%',
            placeholder: 'Postcode',
          },
        ].map((item) => {
          return (
            <div key={item.fieldName} style={{ width: item.width }}>
              {getFieldInput(item)}
            </div>
          );
        })}
      </Flex>
    </Wrapper>
  );
};

export default AddressEditPanel;
