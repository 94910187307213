import ServiceHelper from '../helper/ServiceHelper';
import iPlanRevision from '../../types/plan/iPlanRevision';

const endPoint = '/planRevision';
const PlanRevisionService = {
  getAll: ServiceHelper.getPaginatedAll<iPlanRevision>(endPoint),
  get: ServiceHelper.getById<iPlanRevision>(endPoint),
  create: ServiceHelper.create<iPlanRevision>(endPoint),
  update: ServiceHelper.updateById<iPlanRevision>(endPoint),
  deactivate: ServiceHelper.deactivateById<iPlanRevision>(endPoint),
};

export default PlanRevisionService;
