import ServiceHelper from '../helper/ServiceHelper';
import iCityCouncil from '../../types/misc/iCityCouncil';

const endPoint = '/cityCouncil';

const CityCouncilService = {
  getAll: ServiceHelper.getPaginatedAll<iCityCouncil>(endPoint),
  get: ServiceHelper.getById<iCityCouncil>(endPoint),
  create: ServiceHelper.create<iCityCouncil>(endPoint),
  update: ServiceHelper.updateById<iCityCouncil>(endPoint),
  deactivate: ServiceHelper.deactivateById<iCityCouncil>(endPoint),
};

export default CityCouncilService;
