import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import BuildService from '../../services/build/BuildService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import PageTitleWithCreateBtn, {
  getCreateIconBtn,
} from '../common/PageTitleWithCreateBtn';
import React from 'react';
import BuildCreatePopupBtn from './BuildCreatePopupBtn';
import iBuild from '../../types/build/iBuild';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import SearchTextField from '../frameWork/SearchTextField';
import {
  URL_BUILD_DETAILS,
  URL_BUILD_STYLE_DETAILS,
  URL_CONTACT_COMPANY_DETAILS,
} from '../../helpers/UrlMap';
import { Link, useNavigate } from 'react-router-dom';
import AddressHelper from '../../helpers/AddressHelper';
import { OP_OR } from '../../services/ServiceHelper';
import moment from 'moment';
import Icons from '../frameWork/Icons';
import UserHelper from '../../helpers/UserHelper';

type iBuildList = iComponentWithPageHeader & {
  allowDelete?: boolean;
  allowCreate?: boolean;
  clientId?: string;
  styleId?: string;
  cityCouncilId?: string;
  supervisorId?: string;
  showHeader?: boolean;
};
const BuildList = ({
  allowDelete,
  allowCreate,
  clientId,
  styleId,
  cityCouncilId,
  supervisorId,
  showHeader = true,
  headerProps,
}: iBuildList) => {
  const navigate = useNavigate();
  const testIdStr = 'Build-list';
  const { renderDataTable, renderDeleteBtn, onSetFilter } =
    useListCrudHook<iBuild>({
      sort: `createdAt:DESC`,
      getFn: (params) =>
        BuildService.getAll({
          where: JSON.stringify({
            isActive: true,
            clientId,
            ...(styleId && {
              [OP_OR]: [{ styleId }, { baseOffStyleId: styleId }],
            }),
            cityCouncilId,
            ...(supervisorId && {
              [OP_OR]: [
                { supervisor1Id: supervisorId },
                { supervisor2Id: supervisorId },
              ],
            }),
            ...(params?.filter || {}),
          }),
          include:
            'CreatedBy,UpdatedBy,Address,Client,CityCouncil,WaterAuthority,GasAuthority,ElectricityAuthority,GasType,Suburb,Style,Status,Storey,Supervisor1,Supervisor2,Estate,BaseOffStyle,PlanRevision',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });
  const getColumns = (): iTableColumn<iBuild>[] => [
    {
      key: 'Address',
      header: 'Address',
      isDefault: true,
      cell: ({ data }: iCellParams<iBuild>) => {
        return (
          <Link to={URL_BUILD_DETAILS.replace(':id', data.id)}>
            {AddressHelper.getFullAddress(data.Address)}
          </Link>
        );
      },
    },
    ...[
      { field: 'Status', label: 'Status' },
      {
        field: 'Client',
        label: 'Client',
        getLink: ({ data }: iCellParams<iBuild>) => {
          const id = `${data.Client?.id || ''}`.trim();
          if (!data.Client || styleId === '') {
            return null;
          }
          return (
            <Link to={URL_CONTACT_COMPANY_DETAILS.replace(':id', id)}>
              {data.Client?.name}
            </Link>
          );
        },
      },
      {
        field: 'PlanRevision',
        label: 'Plan Revision',
        isDefault: true,
        isSelectable: true,
        getLink: ({ data }: iCellParams<iBuild>) => {
          const id = `${data.PlanRevision?.id || ''}`.trim();
          if (!data.PlanRevision || id === '') {
            return null;
          }
          return data.PlanRevision?.name || '';
        },
      },
      {
        field: 'Style',
        label: 'Style',
        isDefault: true,
        isSelectable: true,
        getLink: ({ data }: iCellParams<iBuild>) => {
          const id = `${data.Style?.id || ''}`.trim();
          if (!data.Style || id === '') {
            return null;
          }
          return (
            <Link to={URL_BUILD_STYLE_DETAILS.replace(':id', id)}>
              {data.Style?.name}
            </Link>
          );
        },
      },
      {
        field: 'BaseOffStyle',
        label: 'Base Off Style',
        isDefault: true,
        isSelectable: true,
        getLink: ({ data }: iCellParams<iBuild>) => {
          const id = `${data.BaseOffStyle?.id || ''}`.trim();
          if (!data.BaseOffStyle || id === '') {
            return null;
          }
          return (
            <Link to={URL_BUILD_STYLE_DETAILS.replace(':id', id)}>
              {data.BaseOffStyle?.name}
            </Link>
          );
        },
      },
      { field: 'CityCouncil', label: 'Council / Shire' },
      { field: 'Suburb', label: 'Suburb' },
      { field: 'Estate', label: 'Estate' },
      { field: 'Storey', label: 'Storeys' },
      {
        field: 'Supervisor1',
        label: 'Supervisor 1',
        getLink: ({ data }: iCellParams<iBuild>) => {
          const id = `${data.Supervisor1?.id || ''}`.trim();
          if (!data.Supervisor1 || id === '') {
            return null;
          }
          return UserHelper.getFullName(data.Supervisor1);
        },
      },
      {
        field: 'Supervisor2',
        label: 'Supervisor 2',
        getLink: ({ data }: iCellParams<iBuild>) => {
          const id = `${data.Supervisor2?.id || ''}`.trim();
          if (!data.Supervisor2 || id === '') {
            return null;
          }
          return UserHelper.getFullName(data.Supervisor2);
        },
      },
      {
        field: 'WaterAuthority',
        label: 'Water Authority',
        getLink: ({ data }: iCellParams<iBuild>) => {
          const id = `${data.WaterAuthority?.id || ''}`.trim();
          if (!data.WaterAuthority || id === '') {
            return null;
          }
          return (
            <Link to={URL_CONTACT_COMPANY_DETAILS.replace(':id', id)}>
              {data.WaterAuthority?.name}
            </Link>
          );
        },
      },
      {
        field: 'GasAuthority',
        label: 'Gas Authority',
        getLink: ({ data }: iCellParams<iBuild>) => {
          const id = `${data.GasAuthority?.id || ''}`.trim();
          if (!data.GasAuthority || id === '') {
            return null;
          }
          return (
            <Link to={URL_CONTACT_COMPANY_DETAILS.replace(':id', id)}>
              {data.GasAuthority?.name}
            </Link>
          );
        },
      },
      { field: 'GasType', label: 'Gas Type' },
      {
        field: 'ElectricityAuthority',
        label: 'Electricity Authority',
        getLink: ({ data }: iCellParams<iBuild>) => {
          const id = `${data.ElectricityAuthority?.id || ''}`.trim();
          if (!data.ElectricityAuthority || id === '') {
            return null;
          }
          return (
            <Link to={URL_CONTACT_COMPANY_DETAILS.replace(':id', id)}>
              {data.ElectricityAuthority?.name}
            </Link>
          );
        },
      },
    ].map((item) => ({
      key: item.field,
      header: item.label,
      isDefault: true,
      isSelectable: true,
      cell: (colData: iCellParams<iBuild>) => {
        const { data } = colData;
        if (!(item.field in data)) {
          return null;
        }
        if (item.getLink) {
          return item.getLink(colData);
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const obj = data[item.field];
        return `${obj?.name || ''}`.trim();
      },
    })),
    ...[
      { field: 'contractDate', label: 'Contract Date' },
      { field: 'estStartDate', label: 'Est. Start Date' },
      { field: 'estFinishDate', label: 'Est. End Date' },
      { field: 'cFODate', label: 'CFO Date' },
      { field: 'actualStartDate', label: 'Actual Start Date' },
      {
        field: 'estFinishDateFromActualStartDate',
        label: 'Est End Date from Actual Start Date',
      },
      { field: 'settlementDate', label: 'Settlement Date' },
    ].map((item) => ({
      key: item.field,
      header: item.label,
      isSelectable: true,
      group: 'Dates',
      cell: ({ data }: iCellParams<iBuild>) => {
        if (!(item.field in data)) {
          return null;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const dateTime = `${data[item.field] || ''}`.trim();
        return dateTime === '' ? null : moment(dateTime).format('DD MMM YYYY');
      },
    })),
    ...[
      {
        field: 'isSettlementDateConfirmed',
        label: 'Settlement Date Confirmed?',
      },
      { field: 'isCornerBlock', label: 'Is Corner Block?' },
    ].map((item) => ({
      key: item.field,
      header: item.label,
      isSelectable: true,
      group: 'Flags',
      cell: ({ data }: iCellParams<iBuild>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if (!(item.field in data) || data[item.field] !== true) {
          return null;
        }
        return <Icons.CheckIcon label={'Yes'} />;
      },
    })),
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iBuild>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iBuild>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => BuildService.deactivate(data.id),
                    getDisplayName: (bank) =>
                      AddressHelper.getFullAddress(bank.Address),
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={
        showHeader !== true
          ? undefined
          : {
              ...headerProps,
              children:
                allowCreate === true ? (
                  <PageTitleWithCreateBtn
                    createBtn={
                      <BuildCreatePopupBtn
                        onCreated={(created) =>
                          navigate(URL_BUILD_DETAILS.replace(':id', created.id))
                        }
                        renderBtn={(onClick) =>
                          getCreateIconBtn({
                            testId: `${testIdStr}-create-btn`,
                            onClick,
                          })
                        }
                      />
                    }
                    title={headerProps?.children}
                  />
                ) : (
                  headerProps?.children
                ),
              actions: (
                <SearchTextField
                  testId={`${testIdStr}-search-field`}
                  placeholder={'Search address, client, suburb ...'}
                  onSearch={(searchText) => {
                    const searchTxt = `${searchText || ''}`.trim();
                    onSetFilter(searchTxt === '' ? {} : {});
                  }}
                />
              ),
            }
      }
    >
      {renderDataTable({
        selectiveColumnKey: SelectiveColKeys.BUILD_LIST,
        columns: getColumns(),
        showPageSizer: true,
        tblProps: {
          testId: testIdStr,
        },
        selectiveBtnProps: {
          modalProps: {
            width: '740px',
          },
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default BuildList;
