import ServiceHelper from '../helper/ServiceHelper';
import iState from '../../types/misc/iState';

const endPoint = '/state';

const StateService = {
  getAll: ServiceHelper.getPaginatedAll<iState>(endPoint),
  get: ServiceHelper.getById<iState>(endPoint),
  create: ServiceHelper.create<iState>(endPoint),
  update: ServiceHelper.updateById<iState>(endPoint),
  deactivate: ServiceHelper.deactivateById<iState>(endPoint),
};

export default StateService;
