import ServiceHelper from '../helper/ServiceHelper';
import iAttachment from '../../types/asset/iAttachment';

const endPoint = '/attachment';
const AttachmentService = {
  getAll: ServiceHelper.getPaginatedAll<iAttachment>(endPoint),
  deactivate: ServiceHelper.deactivateById<iAttachment>(endPoint),
  create: ServiceHelper.createByUpload<iAttachment>(endPoint),
};

export default AttachmentService;
