import EstateList from '../../components/estate/EstateList';
import ProtectedPage from '../../layouts/ProtectedPage';

const EstatePage = () => {
  return (
    <ProtectedPage
      pageHeaderProps={{
        children: `Estates`,
      }}
    >
      {(headerProps) => <EstateList allowDelete headerProps={headerProps} />}
    </ProtectedPage>
  );
};

export default EstatePage;
