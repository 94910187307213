import SettingsPage from '../../layouts/SettingsPage';
import UserList from '../../components/user/UserList';

const UsersPage = () => {
  return (
    <SettingsPage title={'Users'}>
      {(headerProps) => <UserList allowDelete headerProps={headerProps} />}
    </SettingsPage>
  );
};

export default UsersPage;
