import iAttribute, {
  AttributeForSections,
} from '../../../types/attribute/iAttribute';
import AttributeDiv from '../AttributeDiv';
import { InlineFlex } from '../../frameWork/Flex';
import AttributeValueInput from './AttributeValueInput';

export enum AttrSettingsFieldNames {
  forSectionsMap = 'forSectionsMap',
  editLogic = 'editLogic',
}

export enum WhenActions {
  AttrValueProvided = 'AttrValueProvided',
  AttrValueIs = 'AttrValueIs',
}

export enum ThenActions {
  AttrIsRequired = 'AttrIsRequired',
  AttrLimitValues = 'AttrLimitValues',
  AttrValueWillBe = 'AttrValueWillBe',
}

export type iEditLogicWhen = {
  type: WhenActions | null;
  attrValues?: string[];
  attribute?: iAttribute;
};
export type iEditLogicThen = {
  type: ThenActions | null;
  attrValues?: string[];
};
export type iEditLogic = {
  id?: string;
  when?: iEditLogicWhen[];
  then?: iEditLogicThen;
};

const getForActionObj = (attribute?: iAttribute) => {
  const settings = attribute?.settings || {};
  return AttrSettingsFieldNames.forSectionsMap in settings
    ? settings[AttrSettingsFieldNames.forSectionsMap]
    : null;
};

const getEditLogic = (attribute?: iAttribute): iEditLogic[] => {
  const settings = attribute?.settings || {};
  return AttrSettingsFieldNames.editLogic in settings
    ? settings[AttrSettingsFieldNames.editLogic]
    : [];
};

const isAttributeReadableForSection = (
  attribute: iAttribute,
  forAction: AttributeForSections,
): boolean => {
  const forSectionsObj = getForActionObj(attribute) || {};
  return (
    forAction in forSectionsObj && forSectionsObj[forAction].isReadable === true
  );
};

const isAttributeEditableForSection = (
  attribute: iAttribute,
  forAction: AttributeForSections,
): boolean => {
  const forSectionsObj = getForActionObj(attribute) || {};
  return (
    forAction in forSectionsObj && forSectionsObj[forAction].isEditable === true
  );
};

const isAttributeRequiredForSection = (
  attribute: iAttribute,
  forAction: AttributeForSections,
): boolean => {
  const forSectionsObj = getForActionObj(attribute) || {};
  return (
    forAction in forSectionsObj && forSectionsObj[forAction].isRequired === true
  );
};

const filterReadableAttrsForSection = (
  attributes: iAttribute[],
  forAction: AttributeForSections,
): iAttribute[] => {
  return attributes.filter((attribute) => {
    return isAttributeReadableForSection(attribute, forAction);
  });
};

const isAttributeEditable = (attribute?: iAttribute) => {
  const forSectionsObj = getForActionObj(attribute) || {};
  return (
    Object.values(forSectionsObj).filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (item: any) => item.isEditable === true,
    ).length > 0
  );
};

const whenActionOptions = {
  [WhenActions.AttrValueProvided]: {
    display: (
      <>
        <b>Another attribute has got value</b>
        <div>
          <small>When another attribute has got value.</small>
        </div>
      </>
    ),
    label: WhenActions.AttrValueProvided,
    value: WhenActions.AttrValueProvided,
  },
  [WhenActions.AttrValueIs]: {
    display: (
      <>
        <b>Another attribute has value and the value is</b>
        <div>
          <small>
            Another attribute has value and the value is the provided value:
          </small>
        </div>
      </>
    ),
    label: WhenActions.AttrValueIs,
    value: WhenActions.AttrValueIs,
  },
};

const thenActionOptions = {
  [ThenActions.AttrIsRequired]: {
    display: (
      <>
        <b>Attribute is required</b>
        <div>
          <small>Then the current attribute will be required.</small>
        </div>
      </>
    ),
    label: ThenActions.AttrIsRequired,
    value: ThenActions.AttrIsRequired,
  },
  [ThenActions.AttrLimitValues]: {
    display: (
      <>
        <b>Attribute values limited</b>
        <div>
          <small>Attribute values will be limited to provided:</small>
        </div>
      </>
    ),
    label: ThenActions.AttrLimitValues,
    value: ThenActions.AttrLimitValues,
  },
  [ThenActions.AttrValueWillBe]: {
    display: (
      <>
        <b>Attribute values will be, when creating</b>
        <div>
          <small>
            Attribute value will be the provided value, when creating:
          </small>
        </div>
      </>
    ),
    label: ThenActions.AttrValueWillBe,
    value: ThenActions.AttrValueWillBe,
  },
};

const translateWhenLogicToHumanReadable = (whens: iEditLogicWhen[]) => {
  if (whens.length <= 0) {
    return null;
  }

  return (
    <>
      {whens.map((when, i) => {
        const values = when.attrValues || [];
        return (
          <InlineFlex key={i} className={'gap-025 align-items-center'}>
            <b>
              <AttributeDiv id={when.attribute?.id || ''} />
            </b>
            {when.type === WhenActions.AttrValueProvided ? (
              <div>has value</div>
            ) : when.type === WhenActions.AttrValueIs ? (
              <div>has value and the value is</div>
            ) : null}
            {values.length <= 0
              ? null
              : values.map((value, valIndex) => {
                  if (!when.attribute) {
                    return null;
                  }
                  return (
                    <AttributeValueInput
                      key={valIndex}
                      attribute={when.attribute}
                      showLabel={false}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      attributeValue={{
                        value: `${value || ''}`,
                      }}
                      attributeSection={null}
                      isDisabled={true}
                      onChange={() => null}
                    />
                  );
                })}
          </InlineFlex>
        );
      })}
    </>
  );
};

const translateThenLogicToHumanReadable = (
  attribute?: iAttribute,
  then?: iEditLogicThen | null,
) => {
  if (!then) {
    return null;
  }
  const values = then.attrValues || [];
  return (
    <InlineFlex className={'gap-025 align-items-center'}>
      <b>Current Attribute</b>

      {then.type === ThenActions.AttrIsRequired ? (
        <div>is required when editing.</div>
      ) : then.type === ThenActions.AttrLimitValues ? (
        <div>will be limited to: </div>
      ) : then.type === ThenActions.AttrValueWillBe ? (
        <div>the value populated by default, when creating: </div>
      ) : null}

      {values.length <= 0
        ? null
        : values.map((value, valIndex) => {
            if (!attribute) {
              return null;
            }
            return (
              <AttributeValueInput
                key={valIndex}
                attribute={attribute}
                showLabel={false}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                attributeValue={{
                  value: `${value || ''}`,
                }}
                attributeSection={null}
                isDisabled={true}
                onChange={() => null}
              />
            );
          })}
    </InlineFlex>
  );
};

const AttributeSettingsHelper = {
  getForActionObj,
  getEditLogic,
  filterReadableAttrsForSection,
  isAttributeRequiredForSection,
  isAttributeEditableForSection,
  isAttributeReadableForSection,
  isAttributeEditable,
  thenActionOptions,
  whenActionOptions,
  translateWhenLogicToHumanReadable,
  translateThenLogicToHumanReadable,
};

export default AttributeSettingsHelper;
