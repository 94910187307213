import ServiceHelper from '../helper/ServiceHelper';
import iTaxRate from '../../types/tax/iTaxRate';

const endPoint = '/taxRate';

const TaxRateService = {
  getAll: ServiceHelper.getPaginatedAll<iTaxRate>(endPoint),
  get: ServiceHelper.getById<iTaxRate>(endPoint),
  create: ServiceHelper.create<iTaxRate>(endPoint),
  update: ServiceHelper.updateById<iTaxRate>(endPoint),
  deactivate: ServiceHelper.deactivateById<iTaxRate>(endPoint),
};

export default TaxRateService;
