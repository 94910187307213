import ServiceHelper from '../helper/ServiceHelper';
import iAsset from '../../types/asset/iAsset';
import AppService, { iConfigParams } from '../AppService';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

const endPoint = '/asset';

const getPublicUrl = (
  id: string,
  params?: iConfigParams,
  config: AxiosRequestConfig = {},
): Promise<{ url: string }> => {
  return AppService.get(`${endPoint}/publicUrl/${id}`, params, config).then(
    ({ data }: AxiosResponse) => data,
  );
};
const AssetService = {
  makeItPublic: ServiceHelper.updateById<iAsset>(`${endPoint}/makePublic`),
  getPublicUrl,
};

export default AssetService;
