import iBuild from '../../../../types/build/iBuild';
import DetailsPanelWithSubMenuPanel from '../../../layout/DetailsPanelWithSubMenuPanel';
import BuildDetailsPrelimHelper from './BuildDetailsPrelimHelper';

type iBuildDetailsPrelimPanel = {
  build: iBuild;
  testId?: string;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};
const BuildDetailsPrelimPanel = ({
  build,
  onBuildUpdated,
  testId,
  isDisabled = false,
}: iBuildDetailsPrelimPanel) => {
  const testIdStr = `${testId || ''}-buildDetails-prelim`;

  return (
    <DetailsPanelWithSubMenuPanel
      testId={testIdStr}
      menuItemsMap={BuildDetailsPrelimHelper.getBuildDetailsPrelimMenuItemsMap({
        onBuildUpdated,
        build,
        isDisabled,
      })}
    />
  );
};

export default BuildDetailsPrelimPanel;
