import iBuild from '../../../../types/build/iBuild';
import BuildDetailsContractHelper from './BuildDetailsContractHelper';
import DetailsPanelWithSubMenuPanel from '../../../layout/DetailsPanelWithSubMenuPanel';

type iBuildDetailsContractPanel = {
  build: iBuild;
  testId?: string;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};
const BuildDetailsContractPanel = ({
  build,
  onBuildUpdated,
  testId,
  isDisabled = false,
}: iBuildDetailsContractPanel) => {
  const testIdStr = `${testId || ''}-buildDetails-contract`;

  return (
    <DetailsPanelWithSubMenuPanel
      testId={testIdStr}
      menuItemsMap={BuildDetailsContractHelper.getBuildDetailsContractMenuItemsMap(
        {
          onBuildUpdated,
          build,
          isDisabled,
        },
      )}
    />
  );
};

export default BuildDetailsContractPanel;
