import iAsset from '../../types/asset/iAsset';
import styled from 'styled-components';
import Image, { IMAGE_PLACE_HOLDER_PATH } from '../frameWork/Image';

const Wrapper = styled.div``;
type iAssetThumbnail = {
  asset: iAsset | null;
  className?: string;
  testId?: string;
};
const AssetThumbnail = ({ asset, className, testId }: iAssetThumbnail) => {
  const componentName = 'asset-thumbnail';
  const testIdStr = `${testId || ''}-${componentName}`;
  const assetUrl = `${asset?.url || ''}`.trim();
  return (
    <Wrapper
      className={`${componentName} ${className || ''}`}
      data-testid={testIdStr}
    >
      <Image
        src={
          assetUrl !== '' && asset?.isPublic === true
            ? assetUrl
            : IMAGE_PLACE_HOLDER_PATH
        }
      />
    </Wrapper>
  );
};
export default AssetThumbnail;
