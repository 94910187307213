import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import SuburbService from '../../services/misc/SuburbService';
import iSuburb from '../../types/misc/iSuburb';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import SearchTextField from '../frameWork/SearchTextField';
import { OP_LIKE, OP_OR } from '../../services/ServiceHelper';
import { URL_CONTACT_COMPANY_DETAILS } from '../../helpers/UrlMap';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import { iOptionWithData } from '../frameWork/Select';
import iCityCouncil from '../../types/misc/iCityCouncil';
import CityCouncilSelector from '../cityCouncil/CityCouncilSelector';
import GasTypeSelector from '../gasType/GasTypeSelector';
import iGasType from '../../types/system/iGasType';
import StringHelper from '../../helpers/StringHelper';
import ContactCompanySelector from '../contact/ContactCompanySelector';
import iContactCompany, {
  ContactCompanyTypes,
} from '../../types/contact/iContactCompany';
import { Link } from 'react-router-dom';

type iSuburbList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};

const SuburbList = ({ allowDelete, headerProps }: iSuburbList) => {
  const {
    renderDataTable,
    renderDeleteBtn,
    renderEntityEditPopBtn,
    onSetFilter,
  } = useListCrudHook<iSuburb>({
    sort: `name:ASC`,
    getFn: (params) =>
      SuburbService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include:
          'CreatedBy,UpdatedBy,CityCouncil,WaterSupplier,GasSupplier,GasType',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getEditBtn = (Suburb?: iSuburb) => {
    return renderEntityEditPopBtn<iSuburb>({
      editingEntity: Suburb,
      entityName: 'Suburb',
      createFn: (data) => SuburbService.create(data),
      updateFn: (id, data) => SuburbService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`name-${entity?.id || ''}`}
        >
          {entity?.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'Suburb-name',
        },
        {
          fieldName: 'postcode',
          label: 'Postcode',
          isDisabled,
          isRequired: true,
          value: entity?.postcode || '',
          testId: 'Suburb-postcode',
          isValid: (value: string) => {
            const valStr = `${value || ''}`.trim();
            if (valStr === '') {
              return {
                isValid: false,
                errMessages: ['Postcode is required'],
              };
            }
            if (!StringHelper.isNumeric(valStr)) {
              return {
                isValid: false,
                errMessages: ['Postcode needs to be numeric'],
              };
            }
            return { isValid: true, errMessages: [] };
          },
        },
        {
          fieldName: 'cityCouncilId',
          label: 'City Council / Shire',
          isDisabled,
          isRequired: true,
          value: entity?.cityCouncilId || '',
          testId: 'Suburb-cityCouncilId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <CityCouncilSelector
                {...props}
                {...errorProps}
                onChange={(option: iOptionWithData<iCityCouncil> | null) => {
                  useAsForm.onFieldChange('cityCouncilId', option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'gasTypeId',
          label: 'Gas Type',
          isDisabled,
          value: entity?.gasTypeId || '',
          testId: 'Suburb-gasTypeId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <GasTypeSelector
                {...props}
                {...errorProps}
                onChange={(option: iOptionWithData<iGasType> | null) => {
                  useAsForm.onFieldChange('gasTypeId', option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'gasSupplierId',
          label: 'Gas Supplier',
          isDisabled,
          value: entity?.gasSupplierId || '',
          testId: 'Suburb-gasSupplierId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <ContactCompanySelector
                {...props}
                {...errorProps}
                contactCompanyType={ContactCompanyTypes.GAS_AUTHORITY}
                onChange={(option: iOptionWithData<iContactCompany> | null) => {
                  useAsForm.onFieldChange('gasSupplierId', option?.value || '');
                }}
              />
            );
          },
        },
        {
          fieldName: 'waterSupplierId',
          label: 'Water Supplier',
          isDisabled,
          value: entity?.waterSupplierId || '',
          testId: 'Suburb-waterSupplierId',
          renderComponent: (props, useAsForm, errorProps) => {
            return (
              <ContactCompanySelector
                {...props}
                {...errorProps}
                contactCompanyType={ContactCompanyTypes.WATER_AUTHORITY}
                onChange={(option: iOptionWithData<iContactCompany> | null) => {
                  useAsForm.onFieldChange(
                    'waterSupplierId',
                    option?.value || '',
                  );
                }}
              />
            );
          },
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iSuburb>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      cell: ({ data }: iCellParams<iSuburb>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'postcode',
      header: 'Postcode',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iSuburb>) => {
        return `${data?.postcode || ''}`;
      },
    },
    {
      key: 'cityCouncil',
      header: 'City Council',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iSuburb>) => {
        return `${data.CityCouncil?.name || ''}`;
      },
    },
    {
      key: 'gasType',
      header: 'Gas Type',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iSuburb>) => {
        return `${data.GasType?.name || ''}`;
      },
    },
    {
      key: 'gasSupplier',
      header: 'GasSupplier',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iSuburb>) => {
        const gasSupplierId = `${data.gasSupplierId || ''}`.trim();
        if (gasSupplierId === '') {
          return null;
        }
        return (
          <Link to={URL_CONTACT_COMPANY_DETAILS.replace(':id', gasSupplierId)}>
            {data.GasSupplier?.name || ''}
          </Link>
        );
      },
    },
    {
      key: 'waterSupplier',
      header: 'Water Supplier',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iSuburb>) => {
        const waterSupplierId = `${data.waterSupplierId || ''}`.trim();
        if (waterSupplierId === '') {
          return null;
        }
        return (
          <Link
            to={URL_CONTACT_COMPANY_DETAILS.replace(':id', waterSupplierId)}
          >
            {data.WaterSupplier?.name || ''}
          </Link>
        );
      },
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iSuburb>(true, true),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iSuburb>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => SuburbService.deactivate(data.id),
                    getDisplayName: (Suburb) => Suburb.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
        actions: (
          <SearchTextField
            testId={'suburb-list-search'}
            onSearch={(searchText: string) => {
              onSetFilter({
                [OP_OR]: [{ name: { [OP_LIKE]: `%${searchText}%` } }],
              });
            }}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        selectiveColumnKey: SelectiveColKeys.SUBURB_LIST,
        tblProps: {
          testId: 'Suburb-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default SuburbList;
