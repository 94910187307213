import ServiceHelper from '../helper/ServiceHelper';
import iAttribute from '../../types/attribute/iAttribute';

const endPoint = '/attribute';
const AttributeService = {
  getAll: ServiceHelper.getPaginatedAll<iAttribute>(endPoint),
  get: ServiceHelper.getById<iAttribute>(endPoint),
  create: ServiceHelper.create<iAttribute>(endPoint),
  update: ServiceHelper.updateById<iAttribute>(endPoint),
  deactivate: ServiceHelper.deactivateById<iAttribute>(endPoint),
};

export default AttributeService;
