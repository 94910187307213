import iAttributeSet, {
  AttributeSetCodes,
} from '../../types/attribute/iAttributeSet';
import styled from 'styled-components';
import React, { ReactNode, useState } from 'react';
import Spinner from '../frameWork/Spinner';
import iAttribute, {
  AttributeForSections,
} from '../../types/attribute/iAttribute';
import Tokens from '../frameWork/Tokens';
import { iAttributeItemWithValueMap } from '../../types/attribute/iAttributeItem';
import EntityNames from '../../helpers/EntityNames';
import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import AttributeInputHelper from './components/AttributeInputHelper';
import FormField from '../frameWork/FormField';
import { ViewWrapper } from '../frameWork/InlineEdit';
import AttributeItemEditPopupBtn from './components/AttributeItemEditPopupBtn';
import AttributeSettingsHelper from './components/AttributeSettingsHelper';
import Heading from '../frameWork/Heading';

const Wrapper = styled.div`
  min-height: ${Tokens('space.800', '4rem')};
  .content-row {
    display: flex;
    gap: ${Tokens('space.100', '0.5rem')};
    align-items: start;
    form:first-child {
      margin-block-start: ${Tokens('space.150', '0.75rem')};
    }
    .attr-editor {
      min-width: 10rem;
    }
  }
`;

type iAttributeValueEditSingleType = {
  title?: ReactNode;
  attributeSetCode: AttributeSetCodes;
  className?: string;
  testId?: string;
  entityId: string;
  entityName: EntityNames;
  isDisabled?: boolean;
  attributeSection: AttributeForSections;
};
const AttributeValueEditSingleItem = ({
  title,
  attributeSetCode,
  className = '',
  testId = '',
  entityId,
  entityName,
  isDisabled = false,
  attributeSection,
}: iAttributeValueEditSingleType) => {
  const testIdStr = `${testId}-attribute-value-edit-single-item`;
  const [attributes, setAttributes] = useState<iAttribute[]>([]);
  const [viewingAttrSet, setViewingAttrSet] = useState<iAttributeSet | null>(
    null,
  );
  const { state, onRefresh } = useListCrudHook<iAttributeItemWithValueMap>({
    getFn: async () => {
      const { attributes, itemsWithValueMap, attributeSet } =
        await AttributeInputHelper.getDataForInputPanel({
          entityId,
          entityName,
          attributeSetCode,
        });
      setAttributes(attributes);
      setViewingAttrSet(attributeSet);
      return itemsWithValueMap;
    },
  });

  const getTitleRow = () => {
    if (title) {
      return <div>{title}</div>;
    }
    return <Heading size={'small'}>{viewingAttrSet?.name}</Heading>;
  };

  const getContent = () => {
    // if there is an error of getting data, then don't display the input boxes.
    if (state.isLoading) {
      return <Spinner />;
    }
    const items = state.data.data || [];
    const item = items.length > 0 ? items[0] : null;
    console.log('item', item);
    return (
      <>
        {attributes
          .filter((attribute) =>
            AttributeSettingsHelper.isAttributeReadableForSection(
              attribute,
              attributeSection,
            ),
          )
          .map((attribute) => {
            return (
              <FormField
                key={attribute.id}
                label={attribute.name}
                render={() => {
                  const attrValueMap = item?.valuesMap || {};
                  const attValue =
                    attribute.id in attrValueMap
                      ? attrValueMap[attribute.id]
                      : undefined;
                  return (
                    <AttributeItemEditPopupBtn
                      needQty={false}
                      needArea={false}
                      attributeItem={item || undefined}
                      attributeSetCode={attributeSetCode}
                      entityId={entityId}
                      entityName={entityName}
                      onSaved={() => onRefresh()}
                      attributeSection={attributeSection}
                      renderBtn={(onClick) => {
                        return (
                          <ViewWrapper
                            onClick={() => {
                              if (isDisabled) {
                                return;
                              }
                              onClick();
                            }}
                            className={
                              attValue
                                ? ''
                                : isDisabled
                                  ? 'disabled-view'
                                  : 'default-view'
                            }
                          >
                            {attValue
                              ? AttributeInputHelper.displayValue(
                                  attribute,
                                  attValue,
                                )
                              : isDisabled
                                ? 'no value'
                                : 'Click here to ...'}
                          </ViewWrapper>
                        );
                      }}
                    />
                  );
                }}
              />
            );
          })}
      </>
    );
  };
  return (
    <Wrapper
      className={`attribute-value-single-set ${className}`}
      data-testid={testIdStr}
    >
      {getTitleRow()}
      <div className={'content-row'}>{getContent()}</div>
    </Wrapper>
  );
};

export default AttributeValueEditSingleItem;
