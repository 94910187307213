import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import TextField from '../frameWork/TextField';
import Toggle from '../frameWork/Toggle';
import iContactCompany from '../../types/contact/iContactCompany';
import ContactCompanyService from '../../services/contact/ContactCompanyService';
import Toaster from '../common/Toaster';

type iContactCompanyCreatePopupBtn = Omit<
  iPopupBtn,
  'titleId' | 'modalProps'
> & {
  contactCompanyType: string;
  onCreated: (created: iContactCompany) => void;
};
const ContactCompanyCreatePopupBtn = ({
  contactCompanyType,
  onCreated,
  ...props
}: iContactCompanyCreatePopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});

  const handleClose = (setShowingModal: iSetShowingModalFn) => {
    setEditingData({});
    setShowingModal(false);
  };

  const doSave = (setShowingModal: iSetShowingModalFn) => {
    setIsSaving(true);
    ContactCompanyService.create({
      ...editingData,
      isCompany: editingData.isCompany === true,
      type: contactCompanyType,
    })
      .then((resp) => {
        handleClose(setShowingModal);
        onCreated(resp);
      })
      .catch((err) => {
        Toaster.showApiError(err);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <PopupBtn
      titleId={'contact-company-create-popup'}
      testId={'contact-company-create-popup-btn'}
      shouldFitContainer
      {...props}
      modalProps={(setShowingModal) => ({
        shouldScrollInViewport: true,
        title: `Creating a new ${ContactCompanyService.getHumanReadableType(contactCompanyType)}`,
        onClose: () => handleClose(setShowingModal),
        body: (
          <>
            <TextField
              isRequired
              testId={'name'}
              label={`${ContactCompanyService.getHumanReadableType(contactCompanyType)}'s name`}
              placeholder={`A ${ContactCompanyService.getHumanReadableType(contactCompanyType)} could a company, a person or a group of people`}
              isDisabled={isSaving}
              value={editingData.name || ''}
              onChange={(event) =>
                setEditingData({
                  ...editingData,
                  name:
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    `${event.target.value || ''}`,
                })
              }
            />
            <Toggle
              testId={'isCompany'}
              label={`This ${ContactCompanyService.getHumanReadableType(contactCompanyType)} is a company?`}
              isChecked={editingData.isCompany === true}
              onChange={() =>
                setEditingData({
                  ...editingData,
                  isCompany: editingData.isCompany === true ? false : true,
                })
              }
            />
          </>
        ),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setShowingModal),
          },
          actionBtnProps: {
            isDisabled: Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText: 'Create',
            testId: `${props.testId || ''}-createBtn`,
            onClick: () => doSave(setShowingModal),
          },
        }),
      })}
    />
  );
};

export default ContactCompanyCreatePopupBtn;
