import Select, { iSelect } from '../frameWork/Select';
import { AssetTypes } from '../../types/asset/iAsset';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type iAssetTypeSelector = Omit<iSelect<any>, 'options'>;
const AssetTypeSelector = ({ value, ...props }: iAssetTypeSelector) => {
  const options = Object.values(AssetTypes);

  return (
    <Select
      {...props}
      options={options
        .sort((opt1, opt2) => (`${opt1}` > `${opt2}` ? -1 : 1))
        .map((option) => ({
          label: option,
          value: option,
        }))}
      value={value ? { label: value, value: value } : null}
    />
  );
};

export default AssetTypeSelector;
