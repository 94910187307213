import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsInvoicesPanel = {
  build: iBuild;
};
const BuildDetailsInvoicesPanel = ({ build }: iBuildDetailsInvoicesPanel) => {
  return <Wrapper>Invoices: {build.id}</Wrapper>;
};

export default BuildDetailsInvoicesPanel;
