import Spinner from '../components/frameWork/Spinner';
import styled from 'styled-components';

export const testId = 'page-init-wrapper';
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 1rem;
`;
const PageInit = () => {
  return (
    <Wrapper data-testid={testId}>
      <Spinner size={'large'} label={'loading'} />
    </Wrapper>
  );
};

export default PageInit;
