import iBuild from '../../../../types/build/iBuild';
import DetailsPanelWithSubMenuPanel from '../../../layout/DetailsPanelWithSubMenuPanel';
import BuildDetailsPlanHelper from './BuildDetailsPlanHelper';

type iBuildDetailsPlansPanel = {
  build: iBuild;
  testId?: string;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};
const BuildDetailsPlansPanel = ({
  build,
  onBuildUpdated,
  testId,
  isDisabled = false,
}: iBuildDetailsPlansPanel) => {
  const testIdStr = `${testId || ''}-buildDetails-plans`;
  return (
    <DetailsPanelWithSubMenuPanel
      testId={testIdStr}
      menuItemsMap={BuildDetailsPlanHelper.getMenuItemsMap({
        onBuildUpdated,
        build,
        isDisabled,
      })}
    />
  );
};

export default BuildDetailsPlansPanel;
