enum EntityNames {
  Address = 'Address',
  User = 'User',
  Build = 'Build',
  BuildStyle = 'BuildStyle',
  BuildStylePrice = 'BuildStylePrice',
  Product = 'Product',
}

export default EntityNames;
