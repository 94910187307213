import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import iProduct from '../../types/product/iProduct';
import LeftAndRightPanel from '../layout/LeftAndRightPanel';
import InlineEdit from '../frameWork/InlineEdit';
import { useState } from 'react';
import ProductService from '../../services/product/ProductService';
import { iConfigParams } from '../../services/AppService';
import Toaster from '../common/Toaster';
import StringHelper from '../../helpers/StringHelper';
import Toggle from '../frameWork/Toggle';
import styled from 'styled-components';
import Tabs from '../frameWork/Tabs';
import ProductDetailsPanelHelper from './ProductDetailsPanelHelper';

const Wrapper = styled.div`
  .flag-switch {
    label:first-child {
      font-weight: 500;
      font-size: 16px;
      margin-right: 2rem;
    }
  }
`;

export type iProductDetailsPanel = iComponentWithPageHeader & {
  product: iProduct;
  isDisabled?: boolean;
  testId?: string;
  onSaved?: (saved: iProduct) => void;
  className?: string;
};
const ProductDetailsPanel = ({
  headerProps,
  product,
  onSaved,
  testId,
  className,
  isDisabled,
}: iProductDetailsPanel) => {
  const testIdStr = `${testId || ''}-product-details`;
  const [isSaving, setIsSaving] = useState(false);
  const tabItemsMap = ProductDetailsPanelHelper.getTabItemsMap({
    product,
    onSaved,
    isDisabled,
  });
  const [selectedKey, setSelectedKey] = useState<string>(
    Object.keys(tabItemsMap)[0] || '',
  );

  const handleUpdate = (data: iConfigParams) => {
    setIsSaving(true);
    ProductService.update(product.id, data)
      .then((resp) => {
        setIsSaving(false);
        onSaved && onSaved(resp);
      })
      .catch((error) => {
        setIsSaving(false);
        Toaster.showApiError(error);
      });
  };

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: product.name,
      }}
    >
      <Wrapper>
        <LeftAndRightPanel
          className={`product-details ${className || ''}`}
          testId={testIdStr}
          rightPanel={
            <div className={'right-panel'}>
              {product.isForSell ? (
                <InlineEdit
                  isDisabled={isSaving || isDisabled}
                  value={`${product.unitPrice || ''}`}
                  label={'Unit Price (Ex.)'}
                  validate={(newValue) => {
                    const unitPrice = `${newValue || ''}`.trim();
                    return unitPrice !== '' &&
                      !StringHelper.isNumeric(unitPrice)
                      ? 'Unit Price needs to be a number'
                      : '';
                  }}
                  onConfirm={(newValue) =>
                    handleUpdate({
                      unitPrice: newValue,
                    })
                  }
                />
              ) : null}

              <Toggle
                className={'flag-switch'}
                label={'We sell this product'}
                helperMsg={'Change yours selling prices'}
                isDisabled={isSaving || isDisabled}
                isChecked={product.isForSell}
                onChange={() =>
                  handleUpdate({
                    isForSell: !product.isForSell,
                  })
                }
              />
              <Toggle
                className={'flag-switch'}
                label={'We purchase this product'}
                helperMsg={'Click here to change purchase details'}
                isDisabled={isSaving || isDisabled}
                isChecked={product.isForPurchase}
                onChange={() =>
                  handleUpdate({
                    isForPurchase: !product.isForPurchase,
                  })
                }
              />
              <Toggle
                className={'flag-switch'}
                label={'We use this product to build'}
                helperMsg={'Product can be used in building process'}
                isDisabled={isSaving || isDisabled}
                isChecked={product.isForBuild}
                onChange={() =>
                  handleUpdate({
                    isForBuild: !product.isForBuild,
                  })
                }
              />
            </div>
          }
        >
          <Tabs
            appearance={'tabs'}
            tabs={Object.values(tabItemsMap)}
            onSelect={setSelectedKey}
            activeKey={selectedKey}
          />
        </LeftAndRightPanel>
      </Wrapper>
    </ComponentWithPageHeader>
  );
};

export default ProductDetailsPanel;
