import ServiceHelper from '../helper/ServiceHelper';
import iBuildSuspensionReason from '../../types/buildSuspension/iBuildSuspensionReason';

const endPoint = '/buildSuspensionReason';
const BuildSuspensionReasonService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildSuspensionReason>(endPoint),
  get: ServiceHelper.getById<iBuildSuspensionReason>(endPoint),
  create: ServiceHelper.create<iBuildSuspensionReason>(endPoint),
  update: ServiceHelper.updateById<iBuildSuspensionReason>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildSuspensionReason>(endPoint),
};

export default BuildSuspensionReasonService;
