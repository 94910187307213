import Icons from '../../../frameWork/Icons';
import iBuild from '../../../../types/build/iBuild';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';

export enum BuildDetailsPermitKeys {
  PRE_PERMIT = 'Pre-Permit',
  PERMIT_INFO = 'Permit Info',
  MAP = 'Map',
  REPORT_AND_CONSENT = 'Report & Consent',
  DA = 'DA',
  DEVELOPER_GUIDELINES = 'Developer Guidelines',
  BSVIC = 'BSVIC',
  PRE_PERMIT_CHECKLIST = 'Pre-Permit Checklist',
  SITE_INSPECTIONS = 'Site Inspections',
}

type iGetBuildDetailsPermitMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const getBuildDetailsPermitMenuItemsMap = ({
  build,
}: iGetBuildDetailsPermitMenuItemsMap): iMenuItemsMap => {
  return Object.keys(BuildDetailsPermitKeys).reduce((map, key) => {
    const value =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      key in BuildDetailsPermitKeys ? BuildDetailsPermitKeys[key] : '';
    return {
      ...map,
      [key]: {
        name: value,
        icon: <Icons.OverviewIcon label={value} />,
        component: (
          <div>
            {value}: {build.id}
          </div>
        ),
      },
    };
  }, {});
};

const BuildDetailsPermitHelper = {
  getBuildDetailsPermitMenuItemsMap,
};
export default BuildDetailsPermitHelper;
