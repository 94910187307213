import SettingsPage from '../../layouts/SettingsPage';
import AttributeSetList from '../../components/attribute/AttributeSetList';

const AttributeSetsPage = () => {
  return (
    <SettingsPage title={'Attribute Sets'}>
      {(headerProps) => <AttributeSetList headerProps={headerProps} />}
    </SettingsPage>
  );
};

export default AttributeSetsPage;
