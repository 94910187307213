import * as SentryReact from '@sentry/react';

const init = () => {
  const dsn = `${process.env.REACT_APP_SENTRY_DSN || ''}`.trim();
  const apiEndPoint = `${process.env.REACT_APP_API_URL || ''}`.trim();
  if (dsn === '' || apiEndPoint === '') {
    return;
  }

  SentryReact.init({
    dsn,
    integrations: [
      SentryReact.browserTracingIntegration(),
      SentryReact.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', apiEndPoint],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

const Sentry = {
  init,
};

export default Sentry;
