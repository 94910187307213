import ServiceHelper from '../helper/ServiceHelper';
import iBuildSuspension from '../../types/buildSuspension/iBuildSuspension';

const endPoint = '/buildSuspension';
const BuildSuspensionService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildSuspension>(endPoint),
  get: ServiceHelper.getById<iBuildSuspension>(endPoint),
  create: ServiceHelper.create<iBuildSuspension>(endPoint),
  update: ServiceHelper.updateById<iBuildSuspension>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildSuspension>(endPoint),
};

export default BuildSuspensionService;
