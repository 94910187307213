import { iTab } from '../frameWork/Tabs';
import iProduct from '../../types/product/iProduct';
import ProductDetailsGeneralPanel from './components/ProductDetailsGeneralPanel';
import ProductDetailsAttributesPanel from './components/ProductDetailsAttributesPanel';

export enum ProductDetailsTabKeys {
  GENERAL = 'General',
  ATTRIBUTES = 'Attributes',
}

type iGetTabItemsMap = {
  product: iProduct;
  isDisabled?: boolean;
  onSaved?: (saved: iProduct) => void;
};
const getTabItemsMap = ({
  product,
  isDisabled,
  onSaved,
}: iGetTabItemsMap): { [key: string]: iTab } => {
  return {
    [ProductDetailsTabKeys.GENERAL]: {
      eventKey: ProductDetailsTabKeys.GENERAL,
      title: ProductDetailsTabKeys.GENERAL,
      tabContent: (
        <ProductDetailsGeneralPanel
          product={product}
          isDisabled={isDisabled}
          onSaved={onSaved}
        />
      ),
    },
    [ProductDetailsTabKeys.ATTRIBUTES]: {
      eventKey: ProductDetailsTabKeys.ATTRIBUTES,
      title: ProductDetailsTabKeys.ATTRIBUTES,
      tabContent: (
        <ProductDetailsAttributesPanel
          product={product}
          isDisabled={isDisabled}
          onSaved={onSaved}
        />
      ),
    },
  };
};

const ProductDetailsPanelHelper = {
  getTabItemsMap,
};

export default ProductDetailsPanelHelper;
