import SideNavigation, {
  NavigationHeader,
  NavigationContent,
  Section,
  LinkItem,
  HeadingItem,
} from '../frameWork/SideNavigation';
import { AvatarItem } from '../frameWork/Avatar';
import styled from 'styled-components';
import Tokens from '../frameWork/Tokens';
import { useLocation } from 'react-router-dom';
import {
  URL_SETTINGS,
  URL_SETTINGS_BANKS,
  URL_SETTINGS_COMPANY_PROFILE,
  URL_SETTINGS_TAX_RATES,
  URL_SETTINGS_USERS,
  URL_SETTINGS_FINANCE_TYPE,
  URL_SETTINGS_BUILD_NOTE_CATEGORY,
  URL_SETTINGS_BUILD_SUSPENSION_TYPE,
  URL_SETTINGS_BUILD_SUSPENSION_REASON,
  URL_SETTINGS_PLAN_REVISION,
  URL_SETTINGS_ATTRIBUTE_SET,
} from '../../helpers/UrlMap';
import Icons from '../frameWork/Icons';
import Logo from '../common/Logo';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/makeReduxStore';

const Wrapper = styled.div`
  height: 100%;
  background-color: ${Tokens('color.background.accent.gray.subtlest')};
  [data-testid='sidebar-avatar'] {
    img {
      background-color: ${Tokens('color.background.accent.gray.subtlest')};
      object-fit: contain;
    }
  }
`;
const testId = 'side-nav';
const SettingsSideBar = () => {
  const { pathname } = useLocation();
  const { user } = useSelector((s: RootState) => s.auth);

  return (
    <Wrapper className={'side-nav-wrapper'}>
      <SideNavigation label={'settings'} testId={testId}>
        <NavigationHeader>
          <AvatarItem
            avatar={<Logo width={70} height={70} />}
            primaryText={
              <h4 data-testid={`${testId}-companyName`}>
                {user?.Company?.name || 'Company Name'}
              </h4>
            }
            secondaryText={user?.Company?.abn || ''}
            testId={`${testId}-sidebar-logo`}
          />
        </NavigationHeader>
        <NavigationContent testId="navigation-content" className="nav-content">
          <Section isList>
            {[
              {
                key: 'company-profile',
                name: 'Company Profile',
                icon: (
                  <Icons.OfficeBuildingFilledIcon label={'company profile'} />
                ),
                url: URL_SETTINGS_COMPANY_PROFILE,
                isSelected:
                  pathname === URL_SETTINGS_COMPANY_PROFILE ||
                  pathname === URL_SETTINGS,
              },
              {
                key: 'users',
                name: 'Users',
                icon: <Icons.PeopleGroupIcon label={'Users'} />,
                url: URL_SETTINGS_USERS,
                isSelected: pathname === URL_SETTINGS_USERS,
              },
            ].map((item) => (
              <LinkItem
                key={item.name}
                testId={item.name}
                iconBefore={item.icon}
                isSelected={item.isSelected}
                href={item.url}
              >
                {item.name}
              </LinkItem>
            ))}
          </Section>

          <Section isList>
            <HeadingItem>Finance</HeadingItem>
            {[
              {
                key: 'banks',
                name: 'Banks',
                icon: <Icons.BillingIcon label={'Banks'} />,
                url: URL_SETTINGS_BANKS,
                isSelected: pathname === URL_SETTINGS_BANKS,
              },
              {
                key: 'taxRates',
                name: 'Tax Rates',
                icon: <Icons.BulletListIcon label={'Tax Rates'} />,
                url: URL_SETTINGS_TAX_RATES,
                isSelected: pathname === URL_SETTINGS_TAX_RATES,
              },
              {
                key: 'financeTypes',
                name: 'Finance Types',
                icon: <Icons.CreditcardIcon label={'Finance Types'} />,
                url: URL_SETTINGS_FINANCE_TYPE,
                isSelected: pathname === URL_SETTINGS_FINANCE_TYPE,
              },
            ].map((item) => (
              <LinkItem
                key={item.name}
                testId={item.name}
                iconBefore={item.icon}
                isSelected={item.isSelected}
                href={item.url}
              >
                {item.name}
              </LinkItem>
            ))}
          </Section>

          <Section isList>
            <HeadingItem>Build</HeadingItem>
            {[
              {
                key: 'planRevision',
                name: 'Plan Revisions',
                icon: <Icons.DocumentsIcon label={'Plan Revisions'} />,
                url: URL_SETTINGS_PLAN_REVISION,
                isSelected: pathname === URL_SETTINGS_PLAN_REVISION,
              },
              {
                key: 'buildNoteCategories',
                name: 'Note Categories',
                icon: <Icons.IssuesIcon label={'Note Categories'} />,
                url: URL_SETTINGS_BUILD_NOTE_CATEGORY,
                isSelected: pathname === URL_SETTINGS_BUILD_NOTE_CATEGORY,
              },
              {
                key: 'buildSuspensionTypes',
                name: 'Suspension Types',
                icon: (
                  <Icons.MediaServicesLineThicknessIcon
                    label={'Suspension Types'}
                  />
                ),
                url: URL_SETTINGS_BUILD_SUSPENSION_TYPE,
                isSelected: pathname === URL_SETTINGS_BUILD_SUSPENSION_TYPE,
              },
              {
                key: 'buildSuspensionReasons',
                name: 'Suspension Reasons',
                icon: (
                  <Icons.MediaServicesAnnotateIcon
                    label={'Suspension Reasons'}
                  />
                ),
                url: URL_SETTINGS_BUILD_SUSPENSION_REASON,
                isSelected: pathname === URL_SETTINGS_BUILD_SUSPENSION_REASON,
              },
              {
                key: 'attributeSets',
                name: 'Attribute Sets',
                icon: <Icons.BacklogIcon label={'Attribute Sets'} />,
                url: URL_SETTINGS_ATTRIBUTE_SET,
                isSelected: pathname === URL_SETTINGS_ATTRIBUTE_SET,
              },
            ].map((item) => (
              <LinkItem
                key={item.name}
                testId={item.name}
                iconBefore={item.icon}
                isSelected={item.isSelected}
                href={item.url}
              >
                {item.name}
              </LinkItem>
            ))}
          </Section>
        </NavigationContent>
      </SideNavigation>
    </Wrapper>
  );
};

export default SettingsSideBar;
