import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import AttributeSetService from '../../services/attribute/AttributeSetService';
import iAttributeSet from '../../types/attribute/iAttributeSet';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import React from 'react';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import { Link } from 'react-router-dom';
import { URL_SETTINGS_ATTRIBUTE_SET_DETAILS } from '../../helpers/UrlMap';

export type iAttributeSetList = iComponentWithPageHeader;
const AttributeSetList = ({ headerProps }: iAttributeSetList) => {
  const testIdStr = `AttributeSet-list`;

  const { renderDataTable } = useListCrudHook<iAttributeSet>({
    sort: `name:ASC`,
    getFn: (params) =>
      AttributeSetService.getAll({
        where: JSON.stringify({
          isActive: true,
          ...(params?.filter || {}),
        }),
        include: 'CreatedBy,UpdatedBy',
        currentPage: params?.currentPage || 1,
        perPage: params?.perPage || 10,
        ...(params?.sort ? { sort: params.sort } : {}),
      }),
  });

  const getColumns = (): iTableColumn<iAttributeSet>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      isSelectable: false,
      isSortable: true,
      cell: ({ data }: iCellParams<iAttributeSet>) => (
        <Link to={URL_SETTINGS_ATTRIBUTE_SET_DETAILS.replace(':id', data.id)}>
          {data.name}
        </Link>
      ),
    },
    {
      key: 'code',
      header: 'Code',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iAttributeSet>) => data.code,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iAttributeSet>(),
  ];

  return (
    <ComponentWithPageHeader headerProps={headerProps}>
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: testIdStr,
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default AttributeSetList;
