import PreloadedAsyncSelector, {
  iPreloadAsyncSelectProps,
} from '../frameWork/PreloadedAsyncSelector';
import iCityCouncil from '../../types/misc/iCityCouncil';
import CityCouncilService from '../../services/misc/CityCouncilService';
import { OP_LIKE } from '../../services/ServiceHelper';
import { iOptionWithData } from '../frameWork/Select';

export type iCityCouncilSelector = iPreloadAsyncSelectProps;
const CityCouncilSelector = (props: iCityCouncilSelector) => {
  return (
    <PreloadedAsyncSelector<iCityCouncil>
      placeholder={'Select a city council / shire ...'}
      {...props}
      optionsUniqBy={(data: iCityCouncil) => data.id}
      formatOptionLabel={(data: iOptionWithData<iCityCouncil>) => (
        <>
          <div>{data.data.name}</div>
          <small>
            <i>{data.data.description || ' '}</i>
          </small>
        </>
      )}
      getFn={(data) => {
        const { searchText, ...params } = data || {
          currentPage: 1,
          perPage: 10,
        };
        const searchTxtStr = `${searchText || ''}`.trim();
        return CityCouncilService.getAll({
          ...params,
          sort: 'name:ASC',
          where: JSON.stringify({
            isActive: true,
            ...(searchTxtStr === ''
              ? {}
              : { name: { [OP_LIKE]: `%${searchTxtStr}%` } }),
          }),
        });
      }}
      getValuesFn={(values: string[]) =>
        CityCouncilService.getAll({
          where: JSON.stringify({ id: values }),
          perPage: values.length,
        })
      }
    />
  );
};

export default CityCouncilSelector;
