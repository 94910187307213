import iBuild from '../../../../../types/build/iBuild';

type iBuildDetailsPrelimFloorPlan = {
  build: iBuild;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};

const BuildDetailsPrelimFloorPlan = ({
  build,
}: iBuildDetailsPrelimFloorPlan) => {
  return <div>Floor Plan: {build.id}</div>;
};

export default BuildDetailsPrelimFloorPlan;
