import iBuild from '../../../../../types/build/iBuild';

type iBuildDetailsPlanSoilTest = {
  build: iBuild;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};

const BuildDetailsPlanSoilTest = ({ build }: iBuildDetailsPlanSoilTest) => {
  return <div>Soil Test: {build.id}</div>;
};

export default BuildDetailsPlanSoilTest;
