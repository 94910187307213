import ServiceHelper from '../helper/ServiceHelper';
import iBuildNoteCategory from '../../types/buildNote/iBuildNoteCategory';

const endPoint = '/buildNoteCategory';
const BuildNoteCategoryService = {
  getAll: ServiceHelper.getPaginatedAll<iBuildNoteCategory>(endPoint),
  get: ServiceHelper.getById<iBuildNoteCategory>(endPoint),
  create: ServiceHelper.create<iBuildNoteCategory>(endPoint),
  update: ServiceHelper.updateById<iBuildNoteCategory>(endPoint),
  deactivate: ServiceHelper.deactivateById<iBuildNoteCategory>(endPoint),
};

export default BuildNoteCategoryService;
