import PopupBtn, { iPopupBtn, iSetShowingModalFn } from '../common/PopupBtn';
import iAttribute, { AttributeTypes } from '../../types/attribute/iAttribute';
import { useState } from 'react';
import { iConfigParams } from '../../services/AppService';
import { iErrorMap } from '../form/FormError';
import { getFooterWithBtns } from '../common/PopupModal';
import Icons from '../frameWork/Icons';
import AttributeService from '../../services/attribute/AttributeService';
import Toaster from '../common/Toaster';
import iAttributeSet from '../../types/attribute/iAttributeSet';
import * as _ from 'lodash';
import AttributeEditPanel from './AttributeEditPanel';

export type iAttributeEditPopupBtn = Omit<iPopupBtn, 'titleId'> & {
  attributeSet: iAttributeSet;
  newSort?: number;
  isDisabled?: boolean;
  attribute?: iAttribute;
  onSaved?: (saved: iAttribute, isCreated: boolean) => void;
};
const AttributeEditPopupBtn = ({
  attribute,
  attributeSet,
  onSaved,
  newSort = 0,
  isDisabled = false,
  ...props
}: iAttributeEditPopupBtn) => {
  const [isSaving, setIsSaving] = useState(false);
  const [editingData, setEditingData] = useState<iConfigParams>({});
  const [errorMap, setErrorMap] = useState<iErrorMap>({});

  const handleClose = (setModelShowing: iSetShowingModalFn) => {
    if (isSaving) {
      return;
    }
    setErrorMap({});
    setEditingData({});
    setModelShowing(false);
  };

  const getChangedOptions = () => {
    const changedSettings = editingData.settings || attribute?.settings || {};
    const options = 'options' in changedSettings ? changedSettings.options : [];
    return _.uniq(
      options
        .map((option: string) => `${option || ''}`.trim())
        .filter((option: string) => option !== ''),
    );
  };

  const preSave = () => {
    const errors: iErrorMap = {};
    const data = { ...(attribute || {}), ...editingData };
    if (`${data.name || ''}`.trim() === '') {
      errors.name = ['Name is required'];
    }
    if (`${data.type || ''}`.trim() === '') {
      errors.type = ['Type is required'];
    }
    if (
      data.type === AttributeTypes.DROPDOWN &&
      getChangedOptions().length <= 0
    ) {
      errors.options = ['Options are required'];
    }

    setErrorMap(errors);
    return Object.keys(errors).length <= 0;
  };

  const doSave = (setModelShowing: iSetShowingModalFn) => {
    if (!preSave()) {
      return;
    }
    const attributeId = `${attribute?.id || ''}`.trim();
    const submittingData = {
      ...editingData,
      ...(attributeId === '' ? { sort: newSort } : {}),
      attributeSetCode: attributeSet.code,
      settings: {
        ...(attribute?.settings || {}),
        ...(editingData?.settings || {}),
        options: getChangedOptions(),
      },
    };
    setIsSaving(true);
    const getFnc = () =>
      attributeId === ''
        ? AttributeService.create(submittingData)
        : AttributeService.update(attributeId, submittingData);

    getFnc()
      .then((resp) => {
        setIsSaving(false);
        handleClose(setModelShowing);
        onSaved && onSaved(resp, attributeId === '');
      })
      .catch((err) => {
        setIsSaving(false);
        Toaster.showApiError(err);
      });
  };

  return (
    <PopupBtn
      {...props}
      titleId={'AttributeEditPopupBtn'}
      modalProps={(setModelShowing) => ({
        shouldScrollInViewport: true,
        width: '70rem',
        title:
          `${attribute?.id || ''}`.trim() === '' ? (
            <>
              Creating a new attribute under{' '}
              <small>{attributeSet.name || ''}</small>
            </>
          ) : (
            <>
              Editing attribute{' '}
              <small>
                {attributeSet.name} / {attribute?.name || ''}
              </small>
            </>
          ),
        onClose: () => handleClose(setModelShowing),
        footer: getFooterWithBtns({
          cancelBtnProps: {
            isLoading: isSaving,
            testId: `${props.testId || ''}-cancelBtn`,
            onClick: () => handleClose(setModelShowing),
          },
          actionBtnProps: {
            isDisabled:
              isDisabled || Object.keys(editingData || {}).length <= 0,
            isLoading: isSaving,
            iconBefore: Icons.SendIcon,
            btnText:
              `${attribute?.id || ''}`.trim() === '' ? 'Create' : 'Update',
            testId: `${props.testId || ''}-saveBtn`,
            onClick: () => doSave(setModelShowing),
          },
        }),
        body: (
          <AttributeEditPanel
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            attribute={{
              ...(attribute || {}),
              ...editingData,
            }}
            isDisabled={isSaving || isDisabled}
            useAsFrom={{
              errorMap,
              onFieldChange: (fieldName, newValue) => {
                setEditingData({
                  ...editingData,
                  [fieldName]: newValue,
                });
              },
            }}
          />
        ),
      })}
    />
  );
};

export default AttributeEditPopupBtn;
