import SettingsPage from '../../layouts/SettingsPage';
import BuildNoteCategoryList from '../../components/buildNote/BuildNoteCategoryList';

const BuildNoteCategoriesPage = () => {
  return (
    <SettingsPage title={'Build Note Categories'}>
      {(headerProps) => (
        <BuildNoteCategoryList allowDelete headerProps={headerProps} />
      )}
    </SettingsPage>
  );
};

export default BuildNoteCategoriesPage;
