import Icons from './Icons';
import TextField, { iTextField } from './TextField';
import React from 'react';
import UtilsService from '../../services/UtilsService';
import { IconButton } from './Button';

type iSearchTextField = Omit<iTextField, 'elemAfterInput'> & {
  onSearch: (value: string) => void;
};

const SearchTextField = ({ onSearch, testId, ...props }: iSearchTextField) => {
  const [value, setValue] = React.useState('');

  const handleSearch = () => {
    onSearch(value);
  };

  return (
    <TextField
      placeholder={'Search ...'}
      isCompact
      size={23}
      testId={testId}
      {...props}
      elemAfterInput={
        <IconButton
          icon={Icons.SearchIcon}
          testId={`${testId || ''}-btn`}
          label="search"
          onClick={() => handleSearch()}
        />
      }
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value || '');
      }}
      onKeyDown={(event) => {
        UtilsService.handleEnterKeyPressed(event, () => {
          handleSearch();
        });
      }}
    />
  );
};

export default SearchTextField;
