import iBuild from '../../../../../types/build/iBuild';

type iBuildDetailsPrelimDevelopers = {
  build: iBuild;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};

const BuildDetailsPrelimDevelopers = ({
  build,
}: iBuildDetailsPrelimDevelopers) => {
  return <div>Developers: {build.id}</div>;
};

export default BuildDetailsPrelimDevelopers;
