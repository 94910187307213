import iBaseType from '../iBaseType';

export enum LogEntityChangeTypes {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

type iLogEntityChange = iBaseType & {
  entityId: string;
  entityName: string;
  type: string;
  comments: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  oldValues: any | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newValues: any | null;
};

export default iLogEntityChange;
