import LoginPanel from '../../components/auth/LoginPanel';
import { useNavigate } from 'react-router-dom';
import { URL_BACKEND } from '../../helpers/UrlMap';
import styled from 'styled-components';
import Tokens from '../../components/frameWork/Tokens';

const Wrapper = styled.div`
  display: flex;
  background: transparent url('/images/login_bg.jpg') center center no-repeat;
  background-size: cover;
  height: 100%;

  .login-div {
    background-color: ${Tokens('color.background.accent.magenta.subtler')};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${Tokens('space.600')};
    gap: ${Tokens('space.200')};
  }
`;

const LoginPage = () => {
  const navigate = useNavigate();

  return (
    <Wrapper className={'justify-content-center align-items-end flex-column'}>
      <LoginPanel
        className={'login-div'}
        onSuccess={() => {
          navigate(URL_BACKEND);
        }}
      />
    </Wrapper>
  );
};

export default LoginPage;
