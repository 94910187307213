import Icons from '../../../frameWork/Icons';
import BuildDetailsContractOverview from './BuildDetailsContractComponents/BuildDetailsContractOverview';
import iBuild from '../../../../types/build/iBuild';
import BuildDetailsContractHouse from './BuildDetailsContractComponents/BuildDetailsContractHouse';
import BuildDetailsContractDoors from './BuildDetailsContractComponents/BuildDetailsContractDoors';
import BuildDetailsContractWindows from './BuildDetailsContractComponents/BuildDetailsContractWindows';
import BuildDetailsContractElectrical from './BuildDetailsContractComponents/BuildDetailsContractElectrical';
import BuildDetailsContractCabinets from './BuildDetailsContractComponents/BuildDetailsContractCabinets';
import BuildDetailsContractFlooring from './BuildDetailsContractComponents/BuildDetailsContractFlooring';
import BuildDetailsContractConcrete from './BuildDetailsContractComponents/BuildDetailsContractConcrete';
import BuildDetailsContractPacks from './BuildDetailsContractComponents/BuildDetailsContractPacks';
import BuildDetailsContractFencing from './BuildDetailsContractComponents/BuildDetailsContractFencing';
import BuildDetailsContractBathrooms from './BuildDetailsContractComponents/BuildDetailsContractBathrooms';
import BuildDetailsContractNiche from './BuildDetailsContractComponents/BuildDetailsContractNiche';
import BuildDetailsContractWallTiles from './BuildDetailsContractComponents/BuildDetailsContractWallTiles';
import BuildDetailsContractPiers from './BuildDetailsContractComponents/BuildDetailsContractPiers';
import BuildDetailsContractWaterTanks from './BuildDetailsContractComponents/BuildDetailsContractWaterTanks';
import BuildDetailsContractAppliances from './BuildDetailsContractComponents/BuildDetailsContractAppliances';
import BuildDetailsContractPlumbing from './BuildDetailsContractComponents/BuildDetailsContractPlumbing';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';

export enum BuildDetailsContractKeys {
  OVERVIEW = 'Overview',
  HOUSE = 'House',
  DOORS = 'Doors',
  WINDOWS = 'Windows',
  ELECTRICAL = 'Electrical',
  CABINETS = 'Cabinets',
  FLOORING = 'Flooring',
  CONCRETE = 'Concrete',
  PACKS = 'Packs',
  FENCING = 'Fencing',
  BATHROOMS = 'Bathrooms',
  NICHE = 'Niche',
  WALL_TILES = 'Wall Tiles',
  PIERS = 'Piers',
  APPLIANCES = 'Appliances',
  WATER_TANK = 'Water Tank',
  PLUMBING = 'Plumbing',
}

type iGetBuildDetailsContractMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const getBuildDetailsContractMenuItemsMap = ({
  build,
  onBuildUpdated,
  isDisabled = false,
}: iGetBuildDetailsContractMenuItemsMap): iMenuItemsMap => {
  return {
    [BuildDetailsContractKeys.OVERVIEW]: {
      name: BuildDetailsContractKeys.OVERVIEW,
      icon: <Icons.OverviewIcon label={BuildDetailsContractKeys.OVERVIEW} />,
      component: (
        <BuildDetailsContractOverview
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.HOUSE]: {
      name: BuildDetailsContractKeys.HOUSE,
      icon: <Icons.HomeIcon label={BuildDetailsContractKeys.HOUSE} />,
      component: (
        <BuildDetailsContractHouse
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.DOORS]: {
      name: BuildDetailsContractKeys.DOORS,
      icon: <Icons.DoorsIcon label={BuildDetailsContractKeys.DOORS} />,
      component: (
        <BuildDetailsContractDoors
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.WINDOWS]: {
      name: BuildDetailsContractKeys.WINDOWS,
      icon: (
        <Icons.EditorLayoutThreeWithSidebarsIcon
          label={BuildDetailsContractKeys.WINDOWS}
        />
      ),
      component: (
        <BuildDetailsContractWindows
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.ELECTRICAL]: {
      name: BuildDetailsContractKeys.ELECTRICAL,
      icon: (
        <Icons.EditorHintIcon label={BuildDetailsContractKeys.ELECTRICAL} />
      ),
      component: (
        <BuildDetailsContractElectrical
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.CABINETS]: {
      name: BuildDetailsContractKeys.CABINETS,
      icon: <Icons.QueuesIcon label={BuildDetailsContractKeys.CABINETS} />,
      component: (
        <BuildDetailsContractCabinets
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.FLOORING]: {
      name: BuildDetailsContractKeys.FLOORING,
      icon: (
        <Icons.BitbucketSourceIcon label={BuildDetailsContractKeys.FLOORING} />
      ),
      component: (
        <BuildDetailsContractFlooring
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.CONCRETE]: {
      name: BuildDetailsContractKeys.CONCRETE,
      icon: (
        <Icons.EditorUnderlineIcon label={BuildDetailsContractKeys.CONCRETE} />
      ),
      component: (
        <BuildDetailsContractConcrete
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.PACKS]: {
      name: BuildDetailsContractKeys.PACKS,
      icon: (
        <Icons.EditorMediaWideIcon label={BuildDetailsContractKeys.PACKS} />
      ),
      component: (
        <BuildDetailsContractPacks
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.FENCING]: {
      name: BuildDetailsContractKeys.FENCING,
      icon: <Icons.BoardIcon label={BuildDetailsContractKeys.FENCING} />,
      component: (
        <BuildDetailsContractFencing
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.BATHROOMS]: {
      name: BuildDetailsContractKeys.BATHROOMS,
      icon: <Icons.BathIcon label={BuildDetailsContractKeys.BATHROOMS} />,
      component: (
        <BuildDetailsContractBathrooms
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.NICHE]: {
      name: BuildDetailsContractKeys.NICHE,
      icon: (
        <Icons.MediaServicesFilterIcon label={BuildDetailsContractKeys.NICHE} />
      ),
      component: (
        <BuildDetailsContractNiche
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.WALL_TILES]: {
      name: BuildDetailsContractKeys.WALL_TILES,
      icon: (
        <Icons.AppSwitcherIcon label={BuildDetailsContractKeys.WALL_TILES} />
      ),
      component: (
        <BuildDetailsContractWallTiles
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.PIERS]: {
      name: BuildDetailsContractKeys.PIERS,
      icon: <Icons.PiersIcon label={BuildDetailsContractKeys.PIERS} />,
      component: (
        <BuildDetailsContractPiers
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.APPLIANCES]: {
      name: BuildDetailsContractKeys.APPLIANCES,
      icon: (
        <Icons.AppliancesIcon label={BuildDetailsContractKeys.APPLIANCES} />
      ),
      component: (
        <BuildDetailsContractAppliances
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.WATER_TANK]: {
      name: BuildDetailsContractKeys.WATER_TANK,
      icon: <Icons.WaterTankIcon label={BuildDetailsContractKeys.WATER_TANK} />,
      component: (
        <BuildDetailsContractWaterTanks
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsContractKeys.PLUMBING]: {
      name: BuildDetailsContractKeys.PLUMBING,
      icon: (
        <Icons.BitbucketCompareIcon label={BuildDetailsContractKeys.PLUMBING} />
      ),
      component: (
        <BuildDetailsContractPlumbing
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
  };
};

const BuildDetailsContractHelper = {
  getBuildDetailsContractMenuItemsMap,
};
export default BuildDetailsContractHelper;
