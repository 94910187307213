import styled from 'styled-components';
import Tokens from '../frameWork/Tokens';
import { ReactNode } from 'react';

export const RIGHT_PANEL_WIDTH = 310;
const Wrapper = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: ${Tokens('space.1000')};
  gap: ${Tokens('space.400')};
  .left-panel {
    flex: 1;
  }
  .right-panel {
    width: ${RIGHT_PANEL_WIDTH}px;
  }
`;
type iLeftAndRightPanel = {
  testId?: string;
  className?: string;
  children: ReactNode;
  rightPanel: ReactNode;
};
const LeftAndRightPanel = ({
  testId,
  className,
  children,
  rightPanel,
}: iLeftAndRightPanel) => {
  return (
    <Wrapper
      data-testid={testId}
      className={`left-and-right-panel ${className || ''}`}
    >
      <div className={'left-panel'}>{children}</div>
      {rightPanel && <div className={'right-panel'}>{rightPanel}</div>}
    </Wrapper>
  );
};

export default LeftAndRightPanel;
