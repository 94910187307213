import Icons from '../../../frameWork/Icons';
import iBuild from '../../../../types/build/iBuild';
import BuildDetailsPrelimOverview from './BuildDetailsPrelimComponents/BuildDetailsPrelimOverview';
import BuildDetailsPrelimDevelopers from './BuildDetailsPrelimComponents/BuildDetailsPrelimDevelopers';
import BuildDetailsPrelimFloorPlan from './BuildDetailsPrelimComponents/BuildDetailsPrelimFloorPlan';
import BuildDetailsPrelimBoundary from './BuildDetailsPrelimComponents/BuildDetailsPrelimBoundary';
import { iMenuItemsMap } from '../../../layout/DetailsPanelWithSubMenuPanel';

export enum BuildDetailsPrelimKeys {
  OVERVIEW = 'Overview',
  DEVELOPERS = 'Developers',
  FLOOR_PLAN = 'Floor Plan',
  BOUNDARY = 'Boundary',
}

type iGetBuildDetailsPrelimMenuItemsMap = {
  build: iBuild;
  onBuildUpdated?: (updated: iBuild) => void;
  isDisabled?: boolean;
};

const getBuildDetailsPrelimMenuItemsMap = ({
  build,
  onBuildUpdated,
  isDisabled = false,
}: iGetBuildDetailsPrelimMenuItemsMap): iMenuItemsMap => {
  return {
    [BuildDetailsPrelimKeys.OVERVIEW]: {
      name: BuildDetailsPrelimKeys.OVERVIEW,
      icon: <Icons.OverviewIcon label={BuildDetailsPrelimKeys.OVERVIEW} />,
      component: (
        <BuildDetailsPrelimOverview
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsPrelimKeys.DEVELOPERS]: {
      name: BuildDetailsPrelimKeys.DEVELOPERS,
      icon: (
        <Icons.EditorBackgroundColorIcon
          label={BuildDetailsPrelimKeys.DEVELOPERS}
        />
      ),
      component: (
        <BuildDetailsPrelimDevelopers
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsPrelimKeys.FLOOR_PLAN]: {
      name: BuildDetailsPrelimKeys.FLOOR_PLAN,
      icon: <Icons.FloorPlanIcon label={BuildDetailsPrelimKeys.FLOOR_PLAN} />,
      component: (
        <BuildDetailsPrelimFloorPlan
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
    [BuildDetailsPrelimKeys.BOUNDARY]: {
      name: BuildDetailsPrelimKeys.BOUNDARY,
      icon: <Icons.LandPieceIcon label={BuildDetailsPrelimKeys.BOUNDARY} />,
      component: (
        <BuildDetailsPrelimBoundary
          build={build}
          onBuildUpdated={onBuildUpdated}
          isDisabled={isDisabled}
        />
      ),
    },
  };
};

const BuildDetailsPrelimHelper = {
  getBuildDetailsPrelimMenuItemsMap,
};
export default BuildDetailsPrelimHelper;
