import ProtectedPage from '../../layouts/ProtectedPage';
import UserEditPanel from '../../components/user/UserEditPanel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/makeReduxStore';
import PageHeader from '../../components/frameWork/PageHeader';
import { URL_BACKEND, URL_MY_PROFILE } from '../../helpers/UrlMap';
import { userAuthenticated } from '../../redux/reduxers/auth.slice';
import AuthService from '../../services/auth/AuthService';

const ProfilePage = () => {
  const { user } = useSelector((s: RootState) => s.auth);
  const dispatch = useDispatch();

  const getUserFullName = () => {
    return `${user?.firstName} ${user?.lastName}`;
  };

  return (
    <ProtectedPage>
      <PageHeader
        breadcrumbItems={[
          {
            text: 'Home',
            href: URL_BACKEND,
          },
          {
            href: URL_MY_PROFILE,
            text: getUserFullName(),
          },
        ]}
      >
        {getUserFullName()}
      </PageHeader>
      <UserEditPanel
        user={user}
        className="justify-content-center align-items-start gap-1"
        saveFn={(data) => AuthService.updateMe(data)}
        onSuccess={(updated) => {
          dispatch(userAuthenticated({ user: updated }));
        }}
      />
    </ProtectedPage>
  );
};

export default ProfilePage;
