import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsTrackingPanel = {
  build: iBuild;
};
const BuildDetailsTrackingPanel = ({ build }: iBuildDetailsTrackingPanel) => {
  return <Wrapper>Tracking: {build.id}</Wrapper>;
};

export default BuildDetailsTrackingPanel;
