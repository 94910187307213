import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import BuildNoteService from '../../services/buildNote/BuildNoteService';
import iBuildNote from '../../types/buildNote/iBuildNote';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';
import React from 'react';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import BuildNoteCategorySelector from './BuildNoteCategorySelector';
import { iOption } from '../frameWork/Select';
import { SelectiveColKeys } from '../../services/LocalStorageService';
import TextArea from '../frameWork/TextArea';
import styled from 'styled-components';
import Toggle from '../frameWork/Toggle';

const Wrapper = styled.div`
  td.dynamic-tbl-cell-isAdminNote {
    width: 3.5rem;
  }
  td.dynamic-tbl-cell-category {
    width: 8rem;
  }
  td.dynamic-tbl-cell-created,
  td.dynamic-tbl-cell-updated {
    width: 10rem;
  }
`;

type iBuildNoteList = iComponentWithPageHeader & {
  buildId: string;
  allowDelete?: boolean;
};
const BuildNoteList = ({
  buildId,
  headerProps,
  allowDelete,
}: iBuildNoteList) => {
  const buildIdStr = `${buildId || ''}`.trim();
  const testIdStr = `buildNote-list`;

  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iBuildNote>({
      sort: `createdAt:DESC`,
      getFn: (params) =>
        BuildNoteService.getAll({
          where: JSON.stringify({
            isActive: true,
            buildId: buildIdStr,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy,Category',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getCategoryName = (note: iBuildNote) => {
    return `${note?.Category?.name || 'NO CATEGORY'}`.trim();
  };

  const getEditBtn = (note?: iBuildNote) => {
    return renderEntityEditPopBtn<iBuildNote>({
      editingEntity: note,
      entityName: 'Note',
      createFn: (data) => BuildNoteService.create({ ...data, buildId }),
      updateFn: (id, data) => BuildNoteService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`buildNote-${entity?.id || ''}`}
        >
          {getCategoryName(entity)}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'categoryId',
          label: 'Category',
          isDisabled,
          isRequired: true,
          value: entity?.categoryId || '',
          testId: 'buildNote-categoryId',
          renderComponent: (props, useAsForm, errorProps) => {
            const { fieldName, ...rest } = props;
            return (
              <BuildNoteCategorySelector
                {...rest}
                {...errorProps}
                onChange={(selected: iOption) =>
                  useAsForm.onFieldChange(fieldName, selected?.data.id)
                }
              />
            );
          },
        },
        {
          fieldName: 'isAdminNote',
          label: 'Is Admin Note?',
          isDisabled,
          value: '',
          testId: 'buildNote-isAdminNote',
          renderComponent: (props, useAsForm, errorProps) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { fieldName, value, ...rest } = props;
            return (
              <Toggle
                {...rest}
                {...errorProps}
                isChecked={entity?.isAdminNote === true}
                onChange={(event) =>
                  useAsForm.onFieldChange(fieldName, event.target.checked)
                }
              />
            );
          },
        },
        {
          fieldName: 'notes',
          label: 'Notes',
          isDisabled,
          value: `${entity?.notes || ''}`.trim(),
          testId: 'buildNote-notes',
          renderComponent: (props, useAsForm, errorProps) => {
            const { fieldName, value, ...rest } = props;
            return (
              <TextArea
                {...rest}
                value={`${value}`}
                isInvalid={errorProps.isInvalid}
                onChange={(event) =>
                  useAsForm.onFieldChange(
                    fieldName,
                    `${event.target.value || ''}`,
                  )
                }
              />
            );
          },
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iBuildNote>[] => [
    {
      key: 'category',
      header: 'Category',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildNote>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'isAdminNote',
      header: 'Admin Notes?',
      isDefault: true,
      isSelectable: true,
      cell: ({ data }: iCellParams<iBuildNote>) => {
        return DynamicTableHelper.getCheckedIcon(data.isAdminNote);
      },
    },
    {
      key: 'notes',
      header: 'Notes',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildNote>) => (
        <div>{data.notes || ''}</div>
      ),
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iBuildNote>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            isDefault: true,
            cell: ({ data }: iCellParams<iBuildNote>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () => BuildNoteService.deactivate(data.id),
                    getDisplayName: (note) => getCategoryName(note),
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <Wrapper>
      <ComponentWithPageHeader
        headerProps={{
          ...headerProps,
          children: (
            <PageTitleWithCreateBtn
              createBtn={getEditBtn()}
              title={headerProps?.children}
            />
          ),
        }}
      >
        {renderDataTable({
          columns: getColumns(),
          selectiveColumnKey: SelectiveColKeys.BUILD_NOTE_LIST,
          tblProps: {
            testId: testIdStr,
          },
        })}
      </ComponentWithPageHeader>
    </Wrapper>
  );
};

export default BuildNoteList;
