import iAsset from '../../types/asset/iAsset';
import DeleteConfirmPopupBtn, {
  iDeleteConfirmPopup,
} from '../common/DeleteConfirmPopupBtn';
import AssetService from '../../services/asset/AssetService';
import { IconButton, IconButtonProps } from '../frameWork/Button';
import Icons from '../frameWork/Icons';

export type iMakeAssetPublicPopupBtn = Omit<
  iDeleteConfirmPopup,
  'onDeleted' | 'deleteFnc' | 'titleId'
> & {
  asset: iAsset;
  onUpdated?: (saved: iAsset) => void;
  btnProps?: Omit<IconButtonProps, 'onClick'>;
};
const MakeAssetPublicPopupBtn = ({
  asset,
  onUpdated,
  renderBtn,
  btnProps,
  ...props
}: iMakeAssetPublicPopupBtn) => {
  const testIdStr = `makeAssetPublic-btn-${asset.id}`;
  return (
    <DeleteConfirmPopupBtn
      testId={testIdStr}
      titleId={testIdStr}
      title={'Making this Asset public?'}
      message={
        <>
          Once you{"'"}ve made this Asset public, then anyone on the internet
          can view it.
        </>
      }
      {...props}
      deleteFnc={() => AssetService.makeItPublic(asset.id)}
      onDeleted={onUpdated}
      actionBtnProps={{
        testId: `makeAssetPublic-confirm-btn-${asset.id}`,
        iconBefore: Icons.WatchFilledIcon,
        btnText: 'Make it public',
      }}
      renderBtn={(onClick) => {
        if (renderBtn) {
          return renderBtn(onClick);
        }
        return (
          <IconButton
            label="Make it public"
            testId={`${testIdStr}-btn`}
            icon={Icons.WatchFilledIcon}
            appearance={'subtle'}
            {...btnProps}
            onClick={onClick}
          />
        );
      }}
    />
  );
};

export default MakeAssetPublicPopupBtn;
