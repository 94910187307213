import ServiceHelper from '../helper/ServiceHelper';
import iSuburb from '../../types/misc/iSuburb';

const endPoint = '/suburb';

const SuburbService = {
  getAll: ServiceHelper.getPaginatedAll<iSuburb>(endPoint),
  get: ServiceHelper.getById<iSuburb>(endPoint),
  create: ServiceHelper.create<iSuburb>(endPoint),
  update: ServiceHelper.updateById<iSuburb>(endPoint),
  deactivate: ServiceHelper.deactivateById<iSuburb>(endPoint),
};

export default SuburbService;
