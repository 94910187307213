import styled from 'styled-components';
import iBuild from '../../../../types/build/iBuild';

const Wrapper = styled.div``;

type iBuildDetailsQuotesPanel = {
  build: iBuild;
};
const BuildDetailsQuotesPanel = ({ build }: iBuildDetailsQuotesPanel) => {
  return <Wrapper>Quotes: {build.id}</Wrapper>;
};

export default BuildDetailsQuotesPanel;
