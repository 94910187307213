import useListCrudHook from '../hooks/useListCrudHook/useListCrudHook';
import ComponentWithPageHeader, {
  iComponentWithPageHeader,
} from '../common/ComponentWithPageHeader';
import iBuildSuspensionType from '../../types/buildSuspension/iBuildSuspensionType';
import BuildSuspensionTypeService from '../../services/buildSuspension/BuildSuspensionTypeService';
import DynamicTableHelper, {
  iCellParams,
  iTableColumn,
} from '../../helpers/DynamicTableHelper';
import PageTitleWithCreateBtn from '../common/PageTitleWithCreateBtn';

type iBuildSuspensionTypeList = iComponentWithPageHeader & {
  allowDelete?: boolean;
};
const BuildSuspensionTypeList = ({
  headerProps,
  allowDelete,
}: iBuildSuspensionTypeList) => {
  const { renderDataTable, renderDeleteBtn, renderEntityEditPopBtn } =
    useListCrudHook<iBuildSuspensionType>({
      sort: `name:ASC`,
      getFn: (params) =>
        BuildSuspensionTypeService.getAll({
          where: JSON.stringify({
            isActive: true,
            ...(params?.filter || {}),
          }),
          include: 'CreatedBy,UpdatedBy',
          currentPage: params?.currentPage || 1,
          perPage: params?.perPage || 10,
          ...(params?.sort ? { sort: params.sort } : {}),
        }),
    });

  const getEditBtn = (note?: iBuildSuspensionType) => {
    return renderEntityEditPopBtn<iBuildSuspensionType>({
      editingEntity: note,
      entityName: 'Build Suspension Type',
      createFn: (data) => BuildSuspensionTypeService.create(data),
      updateFn: (id, data) => BuildSuspensionTypeService.update(id, data),
      renderEditBtn: ({ entity, onClick }) => (
        <a
          onClick={onClick}
          className={'cursor-pointer'}
          data-testid={`buildSuspensionType-${entity.id}`}
        >
          {entity.name}
        </a>
      ),
      getFormFields: ({ entity, isDisabled }) => [
        {
          fieldName: 'name',
          label: 'Name',
          isDisabled,
          isRequired: true,
          value: entity?.name || '',
          testId: 'BuildSuspensionType-name',
        },
        {
          fieldName: 'description',
          label: 'Description',
          isDisabled,
          value: entity?.description || '',
          testId: 'BuildSuspensionType-description',
        },
      ],
    });
  };

  const getColumns = (): iTableColumn<iBuildSuspensionType>[] => [
    {
      key: 'name',
      header: 'Name',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildSuspensionType>) => {
        return getEditBtn(data);
      },
    },
    {
      key: 'description',
      header: 'Description',
      isDefault: true,
      isSelectable: false,
      cell: ({ data }: iCellParams<iBuildSuspensionType>) =>
        `${data.description || ''}`,
    },
    ...DynamicTableHelper.getCreatedAndUpdatedColumns<iBuildSuspensionType>(),
    ...(allowDelete !== true
      ? []
      : [
          {
            key: 'btns',
            header: '',
            cell: ({ data }: iCellParams<iBuildSuspensionType>) => {
              return (
                <div className={'text-right'}>
                  {renderDeleteBtn({
                    deletingModel: data,
                    deleteFnc: async () =>
                      BuildSuspensionTypeService.deactivate(data.id),
                    getDisplayName: (noteCategory) => noteCategory.name,
                  })}
                </div>
              );
            },
          },
        ]),
  ];

  return (
    <ComponentWithPageHeader
      headerProps={{
        ...headerProps,
        children: (
          <PageTitleWithCreateBtn
            createBtn={getEditBtn()}
            title={headerProps?.children}
          />
        ),
      }}
    >
      {renderDataTable({
        columns: getColumns(),
        tblProps: {
          testId: 'BuildSuspensionType-list',
        },
      })}
    </ComponentWithPageHeader>
  );
};

export default BuildSuspensionTypeList;
