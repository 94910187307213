import AppService, { iConfigParams } from '../AppService';
import LocalStorageService from '../LocalStorageService';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import iUser from '../../types/system/iUser';

const endPoint = `/auth`;
const getLocalAuthToken = () => {
  const token = LocalStorageService.getToken();
  return typeof token !== 'string' ? '' : token;
};

const removeLocalAuthToken = () => {
  return LocalStorageService.removeToken();
};

const login = (
  username: string,
  password: string,
): Promise<{ user: iUser }> => {
  return AppService.post(endPoint, { username, password }).then(({ data }) => {
    LocalStorageService.setToken(data.token);
    return { user: data.user };
  });
};

const logout = (data: iConfigParams = {}, config: AxiosRequestConfig = {}) => {
  return AppService.delete(endPoint, data, config).then(({ data }) => {
    removeLocalAuthToken();
    return data;
  });
};

const getMe = (config: AxiosRequestConfig = {}): Promise<{ user: iUser }> => {
  return AppService.get(`${endPoint}/getMe`, {}, config).then(
    ({ data }) => data,
  );
};

const updateMe = (
  data: iConfigParams = {},
  config: AxiosRequestConfig = {},
): Promise<iUser> => {
  return AppService.put(`${endPoint}/profile`, data, config).then(
    ({ data }: AxiosResponse) => data.user,
  );
};

const AuthService = {
  getLocalAuthToken,
  removeLocalAuthToken,
  login,
  updateMe,
  logout,
  getMe,
};

export default AuthService;
