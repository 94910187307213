import iBuild from '../../../../../types/build/iBuild';

type iBuildDetailsColoursOverview = {
  build: iBuild;
  isDisabled?: boolean;
  onBuildUpdated?: (updated: iBuild) => void;
};

const BuildDetailsColoursOverview = ({
  build,
}: iBuildDetailsColoursOverview) => {
  return <div>Overview: {build.id}</div>;
};

export default BuildDetailsColoursOverview;
