import styled from 'styled-components';
import { ReactNode } from 'react';
import Tokens from './Tokens';

const Wrapper = styled.div`
  margin-block-start: ${Tokens('space.300', '24px')};
  .title-row {
    font: ${Tokens('font.heading.small')};
  }
  &.margin-top-small {
    margin-block-start: ${Tokens('space.100', '16px')} !important;
  }

  &.flex {
    display: flex;
  }
`;

export type iSectionDiv = {
  title?: ReactNode;
  children?: ReactNode;
  className?: string;
};

const SectionDiv = ({ title, children, className }: iSectionDiv) => {
  const getTitle = () => {
    if (!title) {
      return null;
    }
    return <div className={'title-row'}>{title}</div>;
  };
  return (
    <Wrapper className={className}>
      {getTitle()}
      {children}
    </Wrapper>
  );
};

export default SectionDiv;
