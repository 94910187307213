import SettingsPage from '../../layouts/SettingsPage';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import ComponentWithPreloadData from '../../components/common/ComponentWithPreloadData';
import AttributeSetService from '../../services/attribute/AttributeSetService';
import iAttributeSet from '../../types/attribute/iAttributeSet';
import { URL_SETTINGS_ATTRIBUTE_SET } from '../../helpers/UrlMap';
import AttributeSetDetailsPanel from '../../components/attribute/AttributeSetDetailsPanel';
import MathHelper from '../../helpers/MathHelper';

const AttributeSetDetailsPage = () => {
  const { id } = useParams();
  const idStr = `${id || ''}`.trim();
  const [forceReload, setForceReload] = useState(0);

  return (
    <ComponentWithPreloadData<iAttributeSet>
      forceReload={forceReload}
      emptyStateProps={{
        description: `Couldn't find requested Attribute Set with id: ${idStr}.`,
      }}
      getDataFn={() =>
        AttributeSetService.get(idStr, {
          include: 'CreatedBy,UpdatedBy',
        })
      }
    >
      {({ data: attributeSet }) => {
        return (
          <SettingsPage
            title={attributeSet?.name || ''}
            headerProps={{
              className: 'no-bottom-margin',
            }}
            showTitle={false}
            extraBreadcrumbs={[
              {
                text: 'Attribute Sets',
                href: URL_SETTINGS_ATTRIBUTE_SET,
              },
            ]}
          >
            <AttributeSetDetailsPanel
              headerProps={{
                children: attributeSet?.name || '',
                className: 'no-top-margin',
              }}
              attributeSet={attributeSet}
              onAttributeSetUpdated={() =>
                setForceReload(MathHelper.add(forceReload, 1))
              }
              allowCreate
              allowDelete
              allowEdit
            />
          </SettingsPage>
        );
      }}
    </ComponentWithPreloadData>
  );
};

export default AttributeSetDetailsPage;
