import Image, { IMAGE_PLACE_HOLDER_PATH } from '../frameWork/Image';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/makeReduxStore';
import { useEffect, useState } from 'react';
import CompanyService from '../../services/system/CompanyService';

type iLogo = {
  width?: number;
  height?: number;
  className?: string;
};

const Logo = ({ width, height, className }: iLogo) => {
  const { user } = useSelector((s: RootState) => s.auth);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  useEffect(() => {
    let isCanceled = false;
    const companyLogoUrl = `${user?.Company?.logoUrl || ''}`.trim();
    if (companyLogoUrl !== '') {
      setLogoUrl(companyLogoUrl);
      return;
    }

    CompanyService.getCompanyInfo()
      .then((companyInfo) => {
        if (isCanceled) {
          return;
        }
        setLogoUrl(companyInfo.logoUrl);
      })
      .catch(() => {
        if (isCanceled) {
          return;
        }
        setLogoUrl(IMAGE_PLACE_HOLDER_PATH);
      });

    return () => {
      isCanceled = true;
    };
  }, [user?.Company?.logoUrl]);

  const logoUrlString = `${logoUrl || ''}`.trim();
  return (
    <Image
      src={logoUrlString === '' ? IMAGE_PLACE_HOLDER_PATH : logoUrlString}
      alt="logo"
      className={`logo ${className || ''}`}
      width={width}
      height={height}
    />
  );
};

export default Logo;
