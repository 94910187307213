import iUser from '../../types/system/iUser';
import Flex from '../frameWork/Flex';
import UserAvatar from './UserAvatar';
import InlineEdit from '../frameWork/InlineEdit';
import styled from 'styled-components';
import Tokens from '../frameWork/Tokens';
import { useState } from 'react';
import Toaster, { TOAST_TYPE_SUCCESS } from '../common/Toaster';
import PasswordReset from './PasswordReset';
import { iConfigParams } from '../../services/AppService';
import TextField from '../frameWork/TextField';
import FormError, { iErrorMap } from '../form/FormError';
import SectionMessage from '../frameWork/SectionMessage';

type iUserEditPanel = {
  user?: iUser | null;
  className?: string;
  isDisabled?: boolean;
  onSuccess?: (updated: iUser) => void;
  saveFn?: (data: iConfigParams) => Promise<iUser>;
  useAsForm?: {
    onFieldChange: (fieldName: string, value: string) => void;
    errorMap: iErrorMap;
  };
};

const inputWidth = 220;
const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: ${Tokens('space.500')};
  .avatar-edit-wrapper {
    padding: ${Tokens('space.200')};
  }

  .input-panel {
    width: 500px;
    max-width: 100%;
    justify-content: center;
    gap: ${Tokens('space.200')};
    flex-flow: wrap;
    > div {
      width: ${inputWidth}px;
      max-width: 100%;

      &.full-width {
        width: calc(${inputWidth * 2}px + ${Tokens('space.200')}) !important;
      }
    }
  }
`;

export const testId = 'user-edit-panel';
const UserEditPanel = ({
  user = null,
  className,
  onSuccess,
  saveFn,
  useAsForm,
  isDisabled = false,
}: iUserEditPanel) => {
  const [isSaving, setIsSaving] = useState(false);

  const saveField = (fieldName: string, newValue: string) => {
    const id = `${user?.id || ''}`.trim();
    if (useAsForm || id === '' || !saveFn) {
      return;
    }

    setIsSaving(true);
    saveFn({ [fieldName]: newValue })
      .then((res) => {
        setIsSaving(false);
        Toaster.showToast('Updated.', TOAST_TYPE_SUCCESS);
        onSuccess && onSuccess(res);
      })
      .catch((e) => {
        setIsSaving(false);
        Toaster.showApiError(e);
      });
  };

  const getFields = () => {
    return (
      <>
        {[
          {
            fieldName: 'firstName',
            label: 'First name',
            isRequired: true,
            value: user?.firstName,
            isDisabled: isSaving || isDisabled,
          },
          {
            fieldName: 'lastName',
            label: 'Last name',
            isRequired: true,
            value: user?.lastName,
            isDisabled: isSaving || isDisabled,
          },
          {
            fieldName: 'email',
            label: 'Email',
            isRequired: true,
            value: user?.email,
            className: 'full-width',
            isDisabled: isSaving || isDisabled,
          },
          {
            fieldName: 'username',
            label: 'Username',
            isRequired: true,
            value: user?.username,
            className: 'full-width',
            isDisabled:
              isSaving ||
              (`${user?.id || ''}`.trim() !== '' &&
                `${user?.username || ''}`.trim() !== '') ||
              isDisabled,
          },
        ].map((item) => (
          <div key={item.fieldName} className={item.className}>
            {useAsForm ? (
              <TextField
                isInvalid={item.fieldName in useAsForm.errorMap}
                errorMsg={useAsForm.errorMap[item.fieldName]}
                label={item.label}
                isRequired={item.isRequired}
                isDisabled={item.isDisabled}
                testId={`${testId}-${item.fieldName}`}
                value={item.value}
                onChange={(event) => {
                  useAsForm?.onFieldChange &&
                    useAsForm?.onFieldChange(
                      item.fieldName,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      event.target.value,
                    );
                }}
              />
            ) : (
              <InlineEdit
                isRequired={item.isRequired}
                isDisabled={item.isDisabled}
                readViewFitContainerWidth
                testId={`${testId}-${item.fieldName}`}
                label={item.label}
                value={item.value}
                onConfirm={(value) => saveField(item.fieldName, value)}
              />
            )}
          </div>
        ))}

        <div className={'full-width'}>
          <hr />
          <PasswordReset
            onSubmit={(newPassword) => saveField('password', newPassword)}
            useAsForm={
              useAsForm && {
                onNewPasswordChanged: (newPassword) => {
                  useAsForm?.onFieldChange &&
                    useAsForm?.onFieldChange('password', newPassword);
                },
              }
            }
          />
          <FormError error={useAsForm?.errorMap || {}} fieldName={'password'} />
        </div>
      </>
    );
  };

  if (`${user?.id || ''}`.trim() === '' && !useAsForm) {
    return (
      <div className={className} data-testid={`${testId}-section-error`}>
        <SectionMessage
          appearance={'error'}
          title={'Invalid usage of UserEditPanel'}
        >
          AsAForm needs to be passed in when creating a user.
        </SectionMessage>
      </div>
    );
  }

  return (
    <Wrapper className={className} data-testid={testId}>
      <div className="avatar-edit-wrapper">
        <UserAvatar
          testId={`${testId}-avatar`}
          user={user}
          onClick={isDisabled ? undefined : () => {}}
          size={'xxlarge'}
        />
      </div>
      <Flex className={'align-items-start input-panel'}>{getFields()}</Flex>
    </Wrapper>
  );
};
export default UserEditPanel;
